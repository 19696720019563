<template>
  <div class="checkbox" @click="toggle">
    <div class="check" :class="{checked: this.checked}"></div>
    <div class="label">{{label}}</div>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',

  props: {
    label: String,
    value: [Boolean, Array],
    item: [Number, String]
  },

  methods: {
    toggle () {
      if (Array.isArray(this.val)) {
        if (this.val.includes(this.itemValue)) {
          this.val = this.val.filter(i => i !== this.itemValue)
        } else {
          this.val.push(this.itemValue)
          this.val.sort()
        }
      } else {
        this.val = !this.val
      }

      this.$emit('input', this.val)
    }
  },

  watch: {
    value () {
      if (Array.isArray(this.value)) {
        this.checked = this.value.includes(this.itemValue)
      } else {
        this.checked = this.value
      }

      this.val = this.value
    }
  },

  computed: {
    itemValue () {
      return this.item ?? this.label ?? null
    }
  },

  data () {
    return {
      val: this.value,
      checked: null
    }
  },

  mounted () {
    if (Array.isArray(this.value)) {
      this.checked = this.value.includes(this.itemValue)
    } else {
      this.checked = this.value
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/shared.less';

.checkbox {
  .locked-element-mixin;
  display: inline-block;
  cursor: pointer;
  transition: all 200ms;

  .check {
    display: inline-block;
    border-radius: 3px;
    // background: fade(contrast(@main-color), 10%);
    border: 2px solid fade(contrast(@main-color), 10%);
    width: 25px;
    height: 25px;
    vertical-align: middle;
    transition: all 200ms;

    &.checked {
      background: @accent-color;
      position: relative;

      &::before {
        content: '';
        font-family: 'Font Awesome 5 Pro';
        position: absolute;
        font-weight: 800;
        font-size: 15pt;
        color: @main-color;
        top: 0;
        left: 1px;
      }
    }
  }

  .label {
    display: inline-block;
    margin-left: 10px;
  }

  &:active {
    opacity: 0.7;

    .check {
      transform: scale(1.2);
    }
  }
}
</style>
