<template>
  <div class="input-list-wrapper" :class="{ disabled }">
    <label>{{label}}</label>
    <div class="input-list">
      <div
        @click.stop="$refs.search.focus()"
        :class="{ disabled }"
        class="input-list-holder"
        :tabindex="disabled ? -1 : 0"
        ref="holder"
      >
        <div v-for="item in selectedItems" :key="item.value" class="item" @click.stop="deleteItem(item)">{{item.label}}</div>

        <div
          class="search"
          ref="search"
          @keydown="searchPreventNewLine"
          @keyup="search"
          @keyup.down.enter="$refs.item[0].focus()"
          @click.stop
          contenteditable
        ></div>
      </div>

      <transition name="dropdown">
        <div v-if="showListFlag" class="dropdown-list" @click="$event.stopPropagation()">
          <ul class="dropdown-items" :style="{ maxHeight: `${computedOptions.listMaxHeight}px` }" ref="list">
            <div v-if="loading" class="loading">
              <i class="fal fa-spinner-third fa-spin"></i>
            </div>
            <div v-else-if="computedList.length === 0" class="no-items">{{computedOptions.noItemsMessage}}</div>
            <li
              v-else
              v-for="(item, index) in computedList"
              :key="item.value"
              class="dropdown-item"
              tabindex="0"
              ref="item"
              @click.stop="select(item)"
              @keyup.enter.space="select(item)"
              @keyup.esc="$refs.search.focus()"
              @keydown.down.prevent="setListItemFocus(index + 1)"
              @keydown.up.prevent="setListItemFocus(index - 1)"
            >{{item.label}}</li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputList',

  props: {
    label: String,

    list: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    options: Object,

    value: Array
  },

  methods: {
    search (e) {
      const searchText = e.target.textContent

      this.searchText = searchText

      if (searchText.length > 0) {
        if (this.computedOptions.searchMode === 'customOnType') {
          this.$emit('search', searchText)
        } else if (this.computedOptions.searchMode === 'customOnEnter') {
          if (e.key === 'Enter') {
            this.$emit('search', searchText)
          }
        }

        this.showList()
      } else {
        this.hideList()
      }
    },

    searchPreventNewLine (e) {
      if (e.key === 'Enter') {
        e.preventDefault()
      }

      if (e.key === 'Backspace' && e.target.textContent.length === 0) {
        this.selectedItems.pop()

        this.$emit('input', this.selectedItems)
      }
    },

    showList () {
      this.showListFlag = true

      document.body.addEventListener('click', () => {
        this.hideList()
      }, {
        once: true
      })
    },

    hideList () {
      setTimeout(() => {
        this.$emit('listClosed')
      }, 300)

      this.showListFlag = false
    },

    setListItemFocus (index) {
      const itemsCount = this.$refs.item.length

      if (itemsCount === 0) return

      let item = null

      if (index === -1) {
        item = this.$refs.item[itemsCount - 1]
      } else if (index === itemsCount) {
        item = this.$refs.item[0]
      } else {
        item = this.$refs.item[index]
      }

      if (item !== null) {
        item.focus()
        item.scrollIntoView({
          block: 'center',
          behavior: 'instant'
        })
      }
    },

    select (item) {
      this.$refs.search.textContent = ''

      this.$nextTick().then(() => {
        this.$refs.search.focus()
      })

      this.selectedItems.push(item)

      this.$emit('input', this.selectedItems)
    },

    deleteItem (item) {
      this.selectedItems = this.selectedItems.filter(i => i.value !== item.value)

      this.$emit('input', this.selectedItems)
    }
  },

  computed: {
    computedOptions () {
      return {
        listMaxHeight: 211,
        searchMode: 'filter',
        noItemsMessage: 'No items',
        ...this.options ?? {}
      }
    },

    computedList () {
      if (this.computedOptions.searchMode === 'filter') {
        return this.list.filter(i => i.label.toLowerCase().includes(this.searchText.toLowerCase()))
          .filter(i => !this.selectedItems.map(i => i.value).includes(i.value))
          .sort((a, b) => {
            return a.label.toLowerCase().indexOf(this.searchText.toLowerCase()) -
              b.label.toLowerCase().indexOf(this.searchText.toLowerCase())
          })
      }

      return this.list
    }
  },

  data () {
    return {
      searchText: '',
      selectedItems: this.value,
      showListFlag: false
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/shared.less';

.input-list-wrapper {
  position: relative;

  &.disabled {
    opacity: 0.5 !important;
    pointer-events: none;
  }

  label {
    color: contrast(@main-color);
    font-family: Rajdhani, sans-serif;
  }

  &:focus-within .input-list-holder {
    border-color: @accent-color;
  }

  .input-list-holder {
    .base-input;
    cursor: text;
    padding: 7px;
    line-height: 1.5;

    .item {
      .locked-element-mixin;
      display: inline-block;
      position: relative;
      background: @accent-color;
      padding: 2px 5px;
      margin-right: 4px;
      border-radius: 5px;
      line-height: 1.1;
      pointer-events: none;

      &:hover {
        &::after {
          opacity: 1;
        }
      }

      &::after {
        content: '\f057';
        font-family: 'Font Awesome 5 Pro';
        font-weight: lighter;
        font-size: 14pt;
        margin-left: 5px;
        opacity: 0.7;
        cursor: pointer;
        pointer-events: all;

        &:active {
          opacity: 0.3;
        }
      }
    }

    .search {
      padding: 1px;
      display: inline;
      outline: none;
      line-height: 1.1;
    }
  }

  .dropdown-list {
    position: absolute;
    z-index: 100;
    background: @main-color;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 3px 1px #0000009e;

    .dropdown-items {
      overflow: auto;

      .loading {
        font-size: 18pt;
        text-align: center;
        padding: 10px 0;

        i {
          animation-duration: 500ms;
        }
      }

      .no-items {
        .locked-element-mixin;
        font-size: 16pt;
        font-weight: 500;
        opacity: 0.2;
        text-align: center;
        padding: 10px 0;
      }

      .dropdown-item {
        .locked-element-mixin;
        padding: 10px 20px;
        margin: 4px 0;
        border-radius: 7px;
        cursor: pointer;
        position: relative;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          background: tint(@main-color, 5%);
        }

        &:active,
        &:focus {
          opacity: 0.7;
          outline: none;

          &:before {
            background: fade(@accent-color, 40%);
          }
        }

        &.selected {
          background: tint(@main-color, 2%);

          &:hover {
            background: tint(@main-color, 5%);
          }

          &:before {
            background: fade(@accent-color, 80%);
          }
        }
      }
    }

  }
}
</style>
