<template>
  <transition name="show-up">
    <div v-if="show" class="message-box" :class="type" @click="dismiss">
      <div class="icon">
        <i v-if="type === 'success'" class="fas fa-circle-check"></i>
        <i v-else-if="type === 'danger'" class="fas fa-circle-xmark"></i>
        <i v-else class="fas fa-circle-info"></i>
      </div>
      <div class="message">
        {{ message }}
      </div>
      <div class="dismiss">
        CLICK TO DISMISS
      </div>
      <div class="progress" :style="{ 'animation-duration': options.timeout + 's' }"></div>
    </div>
  </transition>
</template>

<script>
import mitt from 'mitt'

export default {
  name: 'MessageBox',

  methods: {
    dismiss () {
      this.show = false
      clearTimeout(this.timeoutTimer)
    }
  },

  data () {
    return {
      type: 'info',
      message: '',
      options: {
        timeout: 4
      },
      show: false,
      timeoutTimer: null
    }
  },

  mounted () {
    window.sysadminComponentsEventBus = mitt()

    window.sysadminComponentsEventBus.on('open', (e) => {
      this.type = e.type
      this.message = e.message

      this.options.timeout = e.options.timeout ?? 4

      this.show = true

      this.$nextTick().then(() => {
        this.timeoutTimer = setTimeout(() => {
          this.show = false
        }, this.options.timeout * 1000)
      })
    })
  }
}
</script>

<style lang="less" scoped>
@import '../assets/shared.less';
@import '../assets/animations.css';

.message-box {
  position: fixed;
  bottom: 30px;
  left: 50%;
  background: fade(#1D5D9B, 50%);
  box-shadow: 0 0 0 6px fade(#1D5D9B, 15%);
  backdrop-filter: blur(5px);
  padding: 20px 20px 20px 80px;
  border-radius: 10px;
  color: white;
  overflow: hidden;
  cursor: pointer;
  user-select: none;

  &:active {
    opacity: 0.7;
  }

  .icon {
    font-size: 60pt;
    position: absolute;
    opacity: 0.5;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.success {
    background: fade(@success-color, 50%);
    box-shadow: 0 0 0 6px fade(@success-color, 15%);
  }

  &.danger {
    background: fade(@danger-color, 50%);
    box-shadow: 0 0 0 6px fade(@danger-color, 15%);
  }

  .dismiss {
    position: absolute;
    bottom: 4px;
    font-size: 7pt;
    left: 50px;
    right: 0;
    text-align: center;
    opacity: 0.5;
  }

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: white;
    opacity: 0.7;
    animation: progress linear 1 forwards;
  }
}

@keyframes progress {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
</style>
