<template>
  <page :title="pageTitle" backURI="/iam/roles" :loading="loading">

    <template v-if="isEditing" #controls>
      <form-button v-if="$checkPermission('iam.roles.delete')" icon="fal fa-trash" @click="confirmDeletion">Delete Role</form-button>
    </template>

    <template #body>
      <div class="form">
        <div class="input-group size-md">
          <text-input label="Name" v-model="role.name" :disabled="isEditing"/>
        </div>
        <div class="input-group">
          <text-input label="Description" v-model="role.description"/>
        </div>
        <div class="input-group">
          <list-box :options="roles" optionLabel="name" dataKey="id" v-model="selectedExtend" label="Extend Roles">
            <template #option="slot">
              <div class="option-item role-item">
                <div class="icon">
                  <i class="fas fa-box"></i>
                </div>
                <div>
                  <div class="name">{{slot.option.name}}</div>
                  <div class="description">{{slot.option.description}}</div>
                </div>
              </div>
            </template>
          </list-box>
        </div>
        <div v-if="roleExtendPermissions.length" class="input-group">
          <list-box :options="roleExtendPermissions" optionLabel="name" dataKey="name" v-model="selectedExtendExcept" label="Extend except">
            <template #option="slot">
              <div class="option-item permission-item">
                <div class="icon">
                  <i class="fas fa-shapes"></i>
                </div>
                <div>
                  <div class="name">{{slot.option.name}}</div>
                  <div class="description">{{slot.option.description}}</div>
                </div>
              </div>
            </template>
          </list-box>
        </div>
        <div class="input-group">
          <list-box :options="permissions" optionLabel="name" dataKey="name" v-model="selectedPermissions" label="Permissions">
            <template #option="slot">
              <div class="option-item permission-item">
                <div class="icon">
                  <i class="fas fa-shapes"></i>
                </div>
                <div>
                  <div class="name">{{slot.option.name}}</div>
                  <div class="description">{{slot.option.description}}</div>
                </div>
              </div>
            </template>
          </list-box>
        </div>

        <info-box type="success" :text="infoBoxText"/>

        <div class="input-group bottom">
          <form-button v-if="showSaveBtn" @click="save">Save</form-button>
        </div>
      </div>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import FormButton from '@/components/FormButton.vue'
import TextInput from '@/components/TextInput.vue'
import ListBox from '@/components/ListBox.vue'
import InfoBox from '@/components/InfoBox.vue'
import gql from 'graphql-tag'

export default {
  name: 'RoleEditorView',

  components: {
    Page,
    FormButton,
    TextInput,
    ListBox,
    InfoBox
  },

  methods: {
    getRole () {
      this.$apollo.query({
        query: gql`
          query ($id: ID!) {
            iam {
              role: getRole(id: $id) {
                id: _id
                name
                description
                permissions
                extend
                extendExcept
              }
            }
          }
        `,
        variables: {
          id: this.$route.params.id
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.role = response.data.iam.role

        this.getRoles()
        this.getPermissions()
      })
    },

    getRoles () {
      this.$apollo.query({
        query: gql`
          query {
            iam {
              roles: getRoles(includeSuper: false) {
                id: _id
                name
                description
                permissions
              }
            }
          }
        `,
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.roles = response.data.iam.roles.filter(i => i.id !== this.role.id)

        this.selectedExtend = this.roles.filter(i => this.role.extend?.includes(i.id))

        setTimeout(() => {
          this.selectedExtendExcept = this.permissions.filter(i => this.role.extendExcept?.includes(i.name))
        }, 300)

        this.loading = false

        this.setFocus()
      })
    },

    getPermissions () {
      this.$apollo.query({
        query: gql`
          query {
            iam {
              permissions: getPermissions {
                id: _id
                name
                description
              }
            }
          }
        `
      }).then(response => {
        this.permissions = response.data.iam.permissions

        this.selectedPermissions = this.permissions.filter(i => this.role.permissions?.includes(i.name))
      })
    },

    createRole () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($data: IamRoleInput!) {
            iam {
              createRole(data: $data) {
                _id
              }
            }
          }
        `,
        variables: {
          data: {
            name: this.role.name,
            description: this.role.description,
            extend: this.selectedExtend.map(i => i.id),
            extendExcept: this.selectedExtendExcept.map(i => i.name)
              .filter(i => this.roleExtendPermissions.map(j => j.name).includes(i)),
            permissions: this.selectedPermissions.map(i => i.name)
          }
        }
      }).then(() => {
        this.$router.push('/iam/roles')
      }).catch(error => {
        this.infoBoxText = `::error ${error.message}`
      })
    },

    updateRole () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($id: ID!, $data: IamRoleInput!) {
            iam {
              updateRole(id: $id, data: $data) {
                _id
              }
            }
          }
        `,
        variables: {
          id: this.role.id,
          data: {
            description: this.role.description,
            extend: this.selectedExtend.map(i => i.id),
            extendExcept: this.selectedExtendExcept.map(i => i.name)
              .filter(i => this.roleExtendPermissions.map(j => j.name).includes(i)),
            permissions: this.selectedPermissions.map(i => i.name)
          }
        }
      }).then(() => {
        this.$router.push('/iam/roles')
      }).catch(error => {
        this.infoBoxText = `::error ${error.message}`
      })
    },

    confirmDeletion () {
      this.$confirm.require({
        message: 'Are you sure you want to delete this role?',
        header: 'Delete',
        icon: 'fas fa-exclamation-triangle',

        accept: () => {
          this.deleteRole()
        }
      })
    },

    deleteRole () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($ids: [ID!]!) {
            iam {
              deleteRoles(ids: $ids)
            }
          }
        `,
        variables: {
          ids: [this.role.id]
        }
      }).then(() => {
        this.$router.push('/iam/roles')
      }).catch(error => {
        this.infoBoxText = `::error ${error.message}`
      })
    },

    save () {
      if (this.isEditing) {
        this.updateRole()
      } else {
        this.createRole()
      }
    },

    setFocus () {
      this.$nextTick().then(() => {
        if (!this.$isMobile()) {
          this.$el.querySelector('input:not(:disabled)').focus()
        }
      })
    }
  },

  computed: {
    isEditing () {
      return !!this.$route.params.id
    },

    pageTitle () {
      return this.isEditing ? 'Edit Role' : 'Create Role'
    },

    roleExtendPermissions () {
      return [...new Set(this.selectedExtend.map(i => i.permissions).flat())].sort().map(item => {
        return {
          name: item,
          description: this.permissions.find(i => i.name === item)?.description
        }
      })
    },

    filteredPermissions () {
      return this.permissions.filter(i => !this.roleExtendPermissions.map(j => j.name).includes(i.name))
    },

    showSaveBtn () {
      if (this.isEditing) {
        return this.$checkPermission('iam.roles.update')
      } else {
        return this.$checkPermission('iam.roles.create')
      }
    }
  },

  data () {
    return {
      role: {},
      roles: [],
      permissions: [],
      selectedPermissions: [],
      selectedExtend: [],
      selectedExtendExcept: [],
      infoBoxText: null,
      loading: true
    }
  },

  mounted () {
    if (this.isEditing) {
      this.getRole()
    } else {
      this.getRoles()
      this.getPermissions()
    }
  }
}
</script>

<style lang="less" scoped>
.permission-item {
  display: flex;

  > div {
    flex: 1;
  }

  .icon {
    display: flex;
    flex: 0 0 45px;
    align-items: center;
    justify-content: center;
    font-size: 14pt;
    margin-right: 10px;
    color: tint(@accent-color, 10%);
  }

  .name {
    font-family: Consolas, monospace;
    display: inline-block;
    width: 300px;
  }

  .description {
    opacity: 0.5;
    display: inline-block;
  }
}

.role-item {
  display: flex;

  > div {
    flex: 1;
  }

  .icon {
    display: flex;
    flex: 0 0 45px;
    align-items: center;
    justify-content: center;
    font-size: 18pt;
    margin-right: 10px;
    color: tint(@accent-color, 10%);
  }

  .description {
    opacity: 0.5;
  }
}
</style>
