<template>
  <page title="Accounts" :loading="loading" wide>
    <template #controls>
      <form-button v-if="$checkPermission('platform.accounts.create')" icon="fal fa-plus" @click="$router.push('/platform2/accounts/create')">Create an Account</form-button>
    </template>
    <template #body>
      <data-table :value="accounts" @refresh="getAccounts">
        <column field="id" header="Account ID" headerStyle="width: 200px" bodyStyle="text-align: center" sortable>
          <template #body="slot">
            <router-link class="editor-link" :to="'/platform2/accounts/update/' + slot.data.id">{{String(slot.data.id).padStart(6, '0')}}</router-link>
          </template>
        </column>
        <column field="name" header="Name" bodyStyle="text-align: center" sortable/>
        <column field="status" header="Status" bodyStyle="text-align: center" headerStyle="width: 150px" sortable>
          <template #body="slot">
            <span class="resource-status-badge" :class="slot.data.status">{{ slot.data.status }}</span>
          </template>
        </column>
      </data-table>
    </template>
  </page>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import FormButton from '@/components/FormButton.vue'
import Page from '@/components/Page.vue'
import Column from 'primevue/column'

import { tireviewliveApi } from '@/lib/tireviewlive-api'

export default {
  name: 'AccountsView',

  components: {
    Page,
    FormButton,
    DataTable,
    Column
  },

  methods: {
    async getAccounts () {
      this.loading = true

      this.accounts = await tireviewliveApi.get('/sysadmin/accounts')

      this.loading = false
    }
  },

  data () {
    return {
      accounts: [],
      selection: [],
      loading: true
    }
  },

  mounted () {
    this.getAccounts()
  }
}
</script>
