<template>
  <page title="Billing Accounts" :loading="loading" wide>
    <template #controls>
      <form-button v-if="$checkPermission('billing.accounts.delete')" icon="fal fa-trash" :disabled="selection.length" @click="confirmDeletion">Delete Selected Billing Accounts</form-button>
      <form-button v-if="$checkPermission('billing.accounts.create')" icon="fal fa-plus" @click="$router.push('/billing/accounts/create')">Create a new Billing Account</form-button>
    </template>

    <template #body>
      <data-table :value="accounts" :selection.sync="selection" @refresh="getAccounts">
        <column field="account.name" header="Account" bodyStyle="text-align: center" headerStyle="width: 220px" sortable>
          <template #body="slot">
            <router-link :to="'/billing/accounts/update/' + slot.data.id">{{slot.data.account.name}}</router-link>
          </template>
        </column>
        <column field="account.system.name" header="System" headerStyle="width: 200px" bodyStyle="text-align: center" sortable/>
        <column field="currency" header="Currency" headerStyle="width: 200px" bodyStyle="text-align: center" sortable/>
      </data-table>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import FormButton from '@/components/FormButton.vue'
import DataTable from '@/components/DataTable.vue'
import Column from 'primevue/column'
import gql from 'graphql-tag'

export default {
  name: 'BillingAccountsView',

  components: {
    Page,
    FormButton,
    DataTable,
    Column
  },

  methods: {
    getAccounts () {
      this.loading = true

      this.$apollo.query({
        query: gql`
          query {
            billing {
              accounts: getAccounts {
                id: _id
                account {
                  name,
                  system {
                    name
                  }
                }
                currency
              }
            }
          }
        `,
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.accounts = response.data.billing.accounts

        this.loading = false
      })
    },

    confirmDeletion () {
      this.$confirm.require({
        message: `Are you sure you want to delete ${this.selection.length > 1 ? `these ${this.selection.length} billing accounts` : 'this billing account'}?`,
        header: 'Delete',
        icon: 'fas fa-exclamation-triangle',

        accept: () => {
          this.deleteSelectedAccounts()
        }
      })
    },

    deleteSelectedAccounts () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($ids: [ID!]!) {
            billing {
              deleteAccounts(ids: $ids)
            }
          }
        `,
        variables: {
          ids: this.selection.map(i => i.id)
        }
      }).then(() => {
        this.getAccounts()
      })
    }
  },

  data () {
    return {
      accounts: [],
      selection: [],
      loading: true
    }
  },

  mounted () {
    this.getAccounts()
  }
}
</script>

<style lang="less" scoped>
</style>
