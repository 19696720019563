import gql from 'graphql-tag'
import { decode as decodeToken } from 'jsonwebtoken'
import Vue from 'vue'
import Vuex from 'vuex'

import { apolloClient } from '../apollo'
import { destroyPusherClient } from '../pusher'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: {
      token: null,
      permissions: null,
      user: null,
      sessionId: null,
      beamsToken: null,
      tireviewliveToken: null
    },
    findOrdersFilter: {
      orderId: null,
      number: null,
      deviceId: null,
      sensorId: null,
      employeeId: null,
      clientId: null,
      dateFrom: null,
      dateTo: null
    },
    menuVisibility: false,
    workingAccount: JSON.parse(localStorage.getItem('working-account') ?? '{ "id": 0, "name": "All" }')
  },
  mutations: {
    setAuth (state, payload) {
      state.auth = payload
    },

    setFindOrdersFilter (state, payload) {
      state.findOrdersFilter = payload
    },

    setMenuVisibility (state, payload) {
      state.menuVisibility = payload
    },

    setWorkingAccount (state, payload) {
      localStorage.setItem('working-account', JSON.stringify(payload))

      state.workingAccount = payload
    }
  },
  actions: {
    SET_AUTH ({ commit }, token) {
      const tokenData = decodeToken(token)

      localStorage.setItem('token', token)

      commit('setAuth', {
        token,
        permissions: tokenData.per,
        user: tokenData.usr,
        sessionId: tokenData.sid,
        beamsToken: tokenData.btk,
        tireviewliveToken: tokenData.tvl
      })
    },

    async UNSET_AUTH () {
      return new Promise(resolve => {
        apolloClient.mutate({
          mutation: gql`
            mutation {
              auth {
                logout
              }
            }
          `
        }).then(() => {
          localStorage.removeItem('token')

          destroyPusherClient().then(() => {
            resolve()
          })
        })
      })
    },

    SET_FIND_ORDERS_FILTER ({ commit }, filter) {
      commit('setFindOrdersFilter', filter)
    },

    TOGGLE_MENU_VISIBILITY ({ commit, state }) {
      commit('setMenuVisibility', !state.menuVisibility)
    },

    SET_MENU_VISIBILITY ({ commit }, visible) {
      commit('setMenuVisibility', visible)
    }
  },
  modules: {
  }
})
