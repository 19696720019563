<template>
  <div class="page">
    <page-title :backURI="backURI">{{title}}</page-title>

    <div v-if="loading" class="page-loading">
      <div class="loading-progress">
        <span v-if="loadingProgress" class="progress">{{ Math.round(loadingProgress) }}%</span>
      </div>
    </div>

    <div v-else>
      <div v-if="$slots.controls" class="page-controls">
        <slot name="controls"/>
      </div>

      <div class="page-contents" :class="{ wide }">
        <slot name="body"/>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue'

export default {
  name: 'Page',

  props: {
    title: String,
    backURI: String,
    loading: {
      type: Boolean,
      default: false
    },
    loadingProgress: {
      type: Number
    },
    wide: {
      type: Boolean,
      default: false
    }
  },

  components: {
    PageTitle
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../assets/shared.less';

.page {
  position: absolute;
  right: 10px;
  left: @sidebar-width;
  top: 0;
  bottom: 0;
  overflow: auto;

  @media @phone {
    left: 0;
    right: 0;
  }

  /deep/ .page-controls {
    text-align: right;
    position: sticky;
    top: 20px;
    right: 30px;
    z-index: 100;

    @media @phone {
      top: 10px;
      float: right;
      right: 20px;

      & ~ .page-contents {
        padding-top: 60px;
      }
    }

    button {
      width: auto;
      padding: 10px 15px;
      font-size: 14pt;
      margin-left: 8px;
      background-color: transparent;
      color: @accent-color;

      i {
        display: none;
      }

      @media @phone {
        margin-left: 10px;
        background: fade(contrast(@main-color), 3%);
        padding: 0;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        font-size: 16pt;
        text-decoration: none !important;

        i {
          display: unset;
        }

        span {
          display: none;
        }
      }

      &:hover {
        text-decoration: underline;
      }

      &:disabled {
        text-decoration: none;
      }
    }
  }

  .page-loading {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 100;
    font-size: 50pt;
    text-align: center;
    margin-top: 120px;
    opacity: 0.3;
    position: relative;

    &::before {
      content: '\f3f4';
      animation: fa-spin 1s linear infinite;
      display: inline-block;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 10pt;
      font-weight: bold;
    }
  }

  .page-contents {
    padding: 40px 20px;
    max-width: 2500px;
    margin: 0 auto;
    max-width: 900px;

    &.wide {
      max-width: unset;

      @media @phone {
        width: 100%;
      }
    }

    /deep/ .form {
      .input-group {
        margin-bottom: 10px;
        flex: 1;

        &.size-md {
          width: 50%;
          flex: 0 0 50%;

          @media @phone {
            width: 100%;
          }
        }

        &.size-sm {
          width: 25%;
          flex: 0 0 25%;

          @media @phone {
            width: 50%;
          }
        }

        &.size-sm-md {
          width: 37.5%;
          flex: 0 0 37.5%;

          @media @phone {
            width: 100%;
          }
        }

        &.size-auto {
          width: auto;
          flex: none;

          @media @phone {
            width: 100%;
          }
        }

        &.size-sm-full {
          @media @phone {
            width: 100% !important;
          }
        }

        &.bottom {
          margin-top: 50px;
          text-align: right;

          .form-button {
            width: auto;
            padding-left: 30px;
            padding-right: 30px;
            margin-left: 5px;
          }
        }
      }

      .form-row {
        display: flex;
        align-items: end;
        margin-bottom: 15px;
        gap: 7px;

        @media @phone {
          display: unset;

          .form-item {
            margin-bottom: 10px;
          }
        }

        .form-item {
          flex: 1;

          .label {
            font-size: 15pt;
            opacity: 0.5;
            font-weight: 100;
          }

          .value {
            font-size: 17pt;

            @media @phone {
              font-size: 14pt;
            }
          }
        }
      }
    }

    /deep/ .info-box {
      margin: 40px 0;
    }
  }
}
</style>
