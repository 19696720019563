<template>
  <page title="Locations" :loading="loading" wide>
    <template #controls>
      <form-button v-if="$checkPermission('platform.locations.create')" icon="fal fa-plus" @click="$router.push('/platform2/locations/create')">Create a Location</form-button>
    </template>

    <template #body>
      <data-table :value="locations" @refresh="getLocations">
        <column field="id" header="Location ID" bodyStyle="text-align: center" headerStyle="width: 180px" sortable>
          <template #body="slot">
            <router-link class="editor-link" :to="'/platform2/locations/update/' + slot.data.id">{{String(slot.data.id).padStart(6, '0')}}</router-link>
          </template>
        </column>
        <column field="name" header="Name" bodyStyle="text-align: center" sortable/>
      </data-table>
    </template>
  </page>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import FormButton from '@/components/FormButton.vue'
import Page from '@/components/Page.vue'
import Column from 'primevue/column'

import { tireviewliveApi } from '@/lib/tireviewlive-api'

export default {
  name: 'LocationView',

  components: {
    Page,
    DataTable,
    Column,
    FormButton
  },

  methods: {
    async getLocations () {
      this.loading = true

      const accountId = this.$store.state.workingAccount.value

      this.locations = await tireviewliveApi.get('/sysadmin/locations', { accountId })

      this.loading = false
    }
  },

  watch: {
    '$store.state.workingAccount': function () {
      this.getLocations()
    }
  },

  data () {
    return {
      loading: true,
      locations: []
    }
  },

  mounted () {
    this.getLocations()
  }
}
</script>
