<template>
  <page title="Find Third-Party Orders">

    <template #body>
      <div class="basic-search">
        <div class="form">
          <div class="input-group size-sm">
            <text-input label="Order Number" v-model="filter.number" @keyup.enter="findOrders"/>
          </div>
        </div>
      </div>

      <div class="controls">
        <form-button @click="findOrders">Find</form-button>
      </div>
    </template>
  </page>
</template>

<script>
import FormButton from '@/components/FormButton.vue'
import Page from '@/components/Page.vue'
import TextInput from '@/components/TextInput.vue'

export default {
  name: 'FindThirdPartyOrders',

  components: {
    Page,
    TextInput,
    FormButton
  },

  methods: {
    findOrders () {
      this.$router.push({
        path: '/third-party-orders/find-orders/result',
        query: this.queryParams
      })
    }
  },

  computed: {
    queryParams () {
      return Object.fromEntries(Object.entries(this.filter).filter(i => (i[1] !== '' && i[1] !== null)))
    }
  },

  data () {
    return {
      filter: {
        number: null
      }
    }
  }
}
</script>

<style lang="less" scoped>
.controls {
  margin-top: 40px;
  text-align: right;

  .form-button {
    width: 150px;
  }
}
</style>
