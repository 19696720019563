<template>
  <page title="Billing Details" :loading="loading" wide>
    <template #controls>
      <form-button @click="exportCsv" icon="fal fa-file-csv">Export to CSV</form-button>
    </template>

    <template #body>
      <div class="form">
        <div class="form-row">
          <div class="form-item">
            <div class="label">Account</div>
            <div class="value">{{current.account.name}}</div>
          </div>
          <div class="form-item">
            <div class="label">System</div>
            <div class="value">{{current.account.system.name}}</div>
          </div>
          <div class="form-item">
            <div class="label">Due Date</div>
            <div class="value">{{$formatUTCDate(bill.dueDate)}}</div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-item">
            <div class="label">Billable Count</div>
            <div class="value">{{bill.totals.count.active}}</div>
          </div>
          <div class="form-item">
            <div class="label">Holding Count</div>
            <div class="value">{{bill.totals.count.holding}}</div>
          </div>
          <div class="form-item">
            <div class="label">Billable Total</div>
            <div class="value ">{{bill.totals.total.active}} {{current.currency}}</div>
          </div>
        </div>
      </div>

      <div v-for="group in bill.groups" :key="group.rate.id" class="billing-section">
        <div v-if="group.rate.description !== 'Default'" class="billing-section-title">{{ group.rate.description }}</div>

        <div class="billing-section-content">
          <data-table :value="group.devices" :refresh="false" :paginator="false" :rows="1000">
            <column bodyStyle="text-align: center;" field="id" header="IMEI" sortable/>
            <column bodyStyle="text-align: center;" field="name" header="Vehicle ID" sortable/>
            <column bodyStyle="text-align: center;" field="type" header="Type" sortable/>
            <column bodyStyle="text-align: center;" field="fleet" header="Fleet" sortable/>
            <column bodyStyle="text-align: center;" field="carrier" header="Carrier" sortable>
              <template #body="slot">
                <span>
                  {{carriersMap[slot.data.carrier]}}
                </span>
              </template>
            </column>
            <column bodyStyle="text-align: center;" field="activation.status" header="Status" sortable>
              <template #body="slot">
                <span class="badge" :class="slot.data.activation.status">
                  {{activationStatusMap[slot.data.activation.status]}}<span v-if="slot.data.activation.forced">*</span>
                </span>
              </template>
            </column>
            <column bodyStyle="text-align: center;" field="activate.status.forced" header="Rate" sortable>
              <template #body="slot">
                <span class="rate" :class="slot.data.activation.status">
                  {{group.rate.rate}} {{current.currency}}
                </span>
              </template>
            </column>
          </data-table>
        </div>

        <div class="total">
          <span>COUNT <strong>{{group.totals.count.count}}</strong></span>
          <span class="sep"></span>
          <span>BILLABLE <strong>{{group.totals.count.active}}</strong></span>
          <span class="sep"></span>
          <span>HOLDING <strong>{{group.totals.count.holding}}</strong></span>
          <span class="sep"></span>
          <span>TOTAL <strong>{{group.totals.total.active}} {{current.currency}}</strong>/{{group.cycle.cycle}}</span>
        </div>
      </div>
    </template>
  </page>

</template>

<script>
import DataTable from '@/components/DataTable.vue'
import FormButton from '@/components/FormButton.vue'
import Page from '@/components/Page.vue'

import Column from 'primevue/column'

import gql from 'graphql-tag'

export default {
  name: 'BillingReportDetailView',

  components: {
    Page,
    DataTable,
    Column,
    FormButton
  },

  methods: {
    getBill () {
      this.$apollo.query({
        query: gql`
          query ($systemId: String!, $accountId: ID!) {
            billing {
              current: getCurrentBillingBill(systemId: $systemId, accountId: $accountId) {
                billingAccountId
                currency
                account {
                  name
                  system {
                    name
                  }
                }
                rates {
                  normal {
                    dueDate
                    totals {
                      count {
                        active
                        holding
                      }
                      total {
                        active
                      }
                    }
                    groups {
                      totals {
                        total {
                          active
                        }
                        count {
                          count
                          active
                          holding
                        }
                      }
                      devices {
                        id
                        name
                        type
                        fleet
                        carrier
                        activation {
                          status
                          forced
                        }
                      }
                      cycle {
                        cycle
                      }
                      rate {
                        id
                        description
                        rate
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          systemId: this.$route.params.systemId,
          accountId: this.$route.params.accountId
        }
      }).then(response => {
        this.current = response.data.billing.current
        this.loading = false
      })
    },

    exportCsv () {
      const csv = [
        [
          'sep=,'
        ],
        [
          'IMEI',
          'Vehicle ID',
          'Type',
          'Fleet',
          'Carrier',
          'Status',
          `Rate (${this.current.currency})`
        ]
      ]

      this.bill.groups.forEach(group => {
        group.devices.forEach(device => {
          csv.push([
            device.id,
            device.name,
            device.type,
            device.fleet,
            this.carriersMap[device.carrier],
            this.activationStatusMap[device.activation.status],
            group.rate.rate
          ])
        })
      })

      const csvText = 'data:text/csv;charset=utf-8,' + encodeURI(csv.map(i => i.join(',')).join('\n'))
      const link = document.createElement('a')

      link.setAttribute('href', csvText)
      link.setAttribute('download', `bill_${this.current.account.name}_${this.bill.dueDate.substring(0, 10)}.csv`)

      link.click()
    }
  },

  computed: {
    bill () {
      return this.current.rates.normal.find(i => i.dueDate === this.$route.params.dueDate)
    }
  },

  data () {
    return {
      current: {},
      loading: true,
      carriersMap: {
        at_t: 'AT&T',
        'kore-us': 'Kore (North America)',
        'kore-au': 'Kore (Australia)',
        'kore-eu': 'Kore (Europe/South America)'
      },
      activationStatusMap: {
        active: 'Active',
        holding: 'Installation Holding'
      }
    }
  },

  mounted () {
    this.getBill()
  }
}
</script>

<style lang="less" scoped>
.form {
  padding-bottom: 15px;
  border-bottom: 1px dotted fade(contrast(@main-color), 10%);
  margin-bottom: 15px;
}

.billing-section {
  margin-bottom: 30px;

  .billing-section-title {
    font-size: 18pt;
    opacity: 0.5;

    &.static {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .total {
    text-align: right;
    font-size: 14pt;
    margin-top: 10px;

    strong {
      margin-left: 10px;
    }

    .sep {
      &::before {
        content: '//';
      }

      margin: 0 20px;
      color: contrast(@main-color);
      opacity: 0.3;
    }
  }
}

.badge {
  background: fade(@success-color, 10%);
  color: @success-color;
  padding: 5px 12px;
  border-radius: 3px;
  font-weight: bold;

  &.holding {
    background: fade(@warning-color, 10%);
    color: @warning-color;
  }
}

.rate.holding {
  text-decoration: line-through;
  opacity: 0.5;
}
</style>
