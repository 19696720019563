<template>
  <page :title="pageTitle" backURI=":back" :loading="loading">
    <template #controls>
      <form-button v-if="isEditing && $checkPermission('platform.roles.delete')" icon="fal fa-trash" @click="confirmDeletion">Delete Role</form-button>
    </template>

    <template #body>
      <div class="form">
        <div class="input-group size-sm">
          <text-input v-if="isEditing" type="number" label="ID" :value="String(role.id).padStart(6, 0)" disabled/>
        </div>

        <div class="input-group size-md">
          <text-input :disabled="!$checkPermission('platform.roles.update')" label="Name" v-model="role.name"/>
        </div>
        <div class="input-group size-md">
          <text-input :disabled="!$checkPermission('platform.roles.update')" label="Description" v-model="role.description"/>
        </div>
        <div class="input-group size-sm">
          <dropdown :disabled="!$checkPermission('platform.roles.update')" :list="contextList" label="Context" :options="{searchMode: 'none'}" v-model="role.context"/>
        </div>

        <list-box
          :options="permissionsList"
          optionLabel="name"
          dataKey="name"
          v-model="selectedPermissions"
          label="Permissions"
          listStyle="height: 500px;"
          :disabled="!$checkPermission('platform.roles.update')"
        >
          <template #option="slot">
            <div class="permissions-list-item">
              <div class="name">{{slot.option.name}}</div>
              <div class="description">{{slot.option.description}}</div>
            </div>
          </template>
        </list-box>

        <div class="input-group bottom">
          <form-button v-if="$checkPermission('platform.roles.update')" icon="fal fa-check" class="default" @click="save">Save</form-button>
        </div>
      </div>
    </template>
  </page>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue'
import FormButton from '@/components/FormButton.vue'
import ListBox from '@/components/ListBox.vue'
import Page from '@/components/Page.vue'
import TextInput from '@/components/TextInput.vue'

import { tireviewliveApi } from '@/lib/tireviewlive-api'

export default {
  name: 'RolesEditorView',

  components: {
    Dropdown,
    Page,
    TextInput,
    FormButton,
    ListBox
  },

  methods: {
    async save () {
      const data = {
        ...this.role,
        permissions: this.selectedPermissions.map(i => i.name),
        accountId: this.accountId,
        context: this.role.context.value
      }

      if (this.isEditing) {
        await tireviewliveApi.put(`/sysadmin/role/${this.roleId}`, data).then(() => {
          this.$messageBox('Role successfully saved', 'success')
        }).catch(error => {
          this.$messageBox(error.message, 'danger')
        })
      } else {
        await tireviewliveApi.post('/sysadmin/role', data).then((newRole) => {
          this.$messageBox('Role successfully saved', 'success')

          this.$router.replace(`/platform2/roles/update/${newRole.id}`)
        }).catch(error => {
          this.$messageBox(error.message, 'danger')
        })
      }
    },

    async confirmDeletion () {
      this.$confirm.require({
        message: 'Are you sure you want to delete this role?',
        header: 'Delete',
        icon: 'fas fa-exclamation-triangle',

        accept: async () => {
          await tireviewliveApi.delete(`/sysadmin/role/${this.roleId}`)

          this.$router.replace('/platform2/roles')
        }
      })
    }
  },

  computed: {
    isEditing () {
      return !!this.$route.params.roleId
    },

    pageTitle () {
      return this.isEditing ? 'Edit Role' : 'Create Role'
    },

    roleId () {
      return this.$route.params.roleId
    },

    accountId () {
      return this.$store.state.workingAccount.value
    }
  },

  watch: {
    '$store.state.workingAccount': function () {
      this.$router.replace('/platform2/roles')
    }
  },

  data () {
    return {
      loading: true,
      role: {},
      contextList: this.$mapListItems(['sysadmin', 'account', 'both']),
      permissionsList: [],
      selectedPermissions: []
    }
  },

  async mounted () {
    this.permissionsList = await tireviewliveApi.get('/sysadmin/permissions')

    if (this.isEditing) {
      this.role = await tireviewliveApi.get(`/sysadmin/role/${this.roleId}`)

      this.role.context = this.contextList.fromValue(this.role.context)

      this.selectedPermissions = this.permissionsList.filter(i => this.role.permissions.includes(i.name))
    } else {
      this.role.context = this.contextList.fromValue('account')
    }

    this.loading = false
  }
}
</script>

<style lang="less" scoped>
.permissions-list-item {
  padding-left: 10px;

  .name {
    font-family: 'Consolas', 'Courier New', Courier, monospace;
  }

  .description {
    font-size: 10pt;
    opacity: 0.5;
  }
}
</style>
