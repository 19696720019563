<template>
  <h1 class="page-title">
    <i v-if="backURI" @click="back" class="back-btn fal fa-arrow-left"></i>
    <slot/>
  </h1>
</template>

<script>
export default {
  name: 'PageTitle',

  props: {
    backURI: {
      type: String,
      default: null
    }
  },

  methods: {
    back () {
      if (this.backURI === ':back') {
        this.$router.back()
      } else {
        this.$router.push(this.backURI)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../assets/shared.less';

.page-title {
  user-select: none;
  cursor: default;
  font-size: 25pt;
  font-weight: 300;
  font-family: Rajdhani, sans-serif;
  padding: 20px 40px;
  color: fade(contrast(@main-color), 60%);
  border-bottom: 1px solid fade(contrast(@main-color), 5%);
  position: sticky;
  top: 0;
  background-color: fade(contrast(contrast(@main-color)), 0%);
  z-index: 10;
  margin: 0 0 10px 0;
  backdrop-filter: blur(5px);

  .back-btn {
    display: none;
  }

  @media @phone {
    font-size: 18pt;
    padding: 15px 20px;
    backdrop-filter: none;
    background-color: tint(contrast(contrast(@main-color)), 6%);

    .back-btn {
      display: initial;
      font-family: 'Font Awesome 5 Pro';
      content: '';
      font-weight: 100;
      font-size: 14pt;
      margin-right: 15px;
      opacity: 0.6;
    }
  }
}
</style>
