<template>
  <page title="Roles" :loading="loading" wide>

    <template #controls>
      <form-button v-if="$checkPermission('iam.roles.delete')" icon="fal fa-trash" @click="confirmDeletion" :disabled="selection.length === 0">Delete Selected Roles</form-button>
      <form-button v-if="$checkPermission('iam.roles.create')" icon="fal fa-plus" @click="$router.push('/iam/roles/create')">Add Role</form-button>
    </template>

    <template #body>
      <data-table :value="roles" :selection.sync="selection" @refresh="getRoles">
        <column field="name" header="Name" bodyStyle="text-align: center" headerStyle="width: 300px" sortable>
          <template #body="slot">
            <router-link :to="`/iam/roles/update/${slot.data.id}`">{{slot.data.name}}</router-link>
          </template>
        </column>
        <column field="description" header="Description" headerStyle="width: 400px"/>
      </data-table>

      <info-box type="success" :text="infoBoxText"/>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import FormButton from '@/components/FormButton.vue'
import DataTable from '@/components/DataTable.vue'
import InfoBox from '@/components/InfoBox.vue'
import gql from 'graphql-tag'
import Column from 'primevue/column'

export default {
  name: 'RolesView',

  components: {
    Page,
    FormButton,
    DataTable,
    Column,
    InfoBox
  },

  methods: {
    getRoles () {
      this.$apollo.query({
        query: gql`
          query {
            iam {
              roles: getRoles(includeSuper: false) {
                id: _id
                name
                description
              }
            }
          }
        `,
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.roles = response.data.iam.roles
        this.loading = false
      })
    },

    confirmDeletion () {
      this.$confirm.require({
        message: `Are you sure you want to delete ${this.selection.length > 1 ? `these ${this.selection.length} roles` : 'this role'}?`,
        header: 'Delete',
        icon: 'fas fa-exclamation-triangle',

        accept: () => {
          this.deleteSelectedRoles()
        }
      })
    },

    deleteSelectedRoles () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($ids: [ID!]!) {
            iam {
              deleteRoles(ids: $ids)
            }
          }
        `,
        variables: {
          ids: this.selection.map(i => i.id)
        }
      }).then(() => {
        this.getRoles()
      }).catch(error => {
        this.infoBoxText = `::error ${error.message}`
      })
    }
  },

  data () {
    return {
      roles: [],
      selection: [],
      infoBoxText: null,
      loading: true
    }
  },

  mounted () {
    this.getRoles()
  }
}
</script>
