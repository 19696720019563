<template>
  <page title="Sessions" :loading="loading" wide>
    <template #controls>
      <form-button v-if="$checkPermission('platform.sessions.delete')" :disabled="selection.length === 0">Remove Selected Sessions</form-button>
    </template>

    <template #body>
      <data-table :value="sessions" :selection.sync="selection" @refresh="getSessions">
        <column field="id" header="Session ID" bodyStyle="text-align: center" headerStyle="width: 180px" sortable/>
        <column field="user.fullName" header="User" headerStyle="width: 250px" bodyStyle="text-align: center" sortable/>
        <column field="account.name" header="Account" headerStyle="width: 250px" bodyStyle="text-align: center" sortable/>
        <column field="accountUserId" header="Account User ID" headerStyle="width: 250px" bodyStyle="text-align: center" sortable/>
        <column field="type" header="Type" headerStyle="width: 250px" bodyStyle="text-align: center" sortable/>
        <column field="location" header="Location" headerStyle="width: 250px" bodyStyle="text-align: center" sortable/>
        <column field="ip" header="IP" headerStyle="width: 250px" bodyStyle="text-align: center" sortable/>
      </data-table>
    </template>
  </page>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import FormButton from '@/components/FormButton.vue'
import Page from '@/components/Page.vue'
import Column from 'primevue/column'

import { tireviewliveApi } from '@/lib/tireviewlive-api'

export default {
  name: 'SessionView',

  components: {
    Page,
    DataTable,
    Column,
    FormButton
  },

  methods: {
    async getSessions () {
      this.loading = true

      const accountId = this.$store.state.workingAccount.value

      this.sessions = await tireviewliveApi.get('/sysadmin/sessions', {
        accountId,
        include: 'user,account'
      })

      this.loading = false
    }
  },

  watch: {
    '$store.state.workingAccount': function () {
      this.getSessions()
    }
  },

  data () {
    return {
      loading: true,
      selection: [],
      sessions: []
    }
  },

  mounted () {
    this.getSessions()
  }
}
</script>
