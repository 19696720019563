<template>
  <div class="data-table-wrapper">
    <data-table class="data-table" v-bind="tableProps" v-on="$listeners">
      <template v-if="tableProps.searchBar" #header>
        <div class="table-header">
          <div class="search-text-input-wrapper">
            <text-input v-model="filters.global" placeholder="Search"/>
            <button v-if="refresh" class="refresh-btn" @click="$emit('refresh')">
              <i class="fal fa-redo"></i>
            </button>
          </div>
        </div>
      </template>

      <column v-if="$attrs.selection !== undefined" selectionMode="multiple" headerStyle="width: 3em"></column>

      <slot/>
    </data-table>
  </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

export default {
  components: {
    TextInput,
    DataTable,
    Column
  },

  props: {
    refresh: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    tableProps () {
      return {
        paginator: true,
        rows: 10,
        resizableColumns: true,
        columnResizeMode: 'fit',
        filters: this.filters,
        sortMode: 'multiple',
        searchBar: true,
        paginatorTemplate: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        rowsPerPageOptions: [10, 20, 50],
        currentPageReportTemplate: 'Showing {first} to {last} of {totalRecords}',
        ...this.$attrs
      }
    }
  },

  data () {
    return {
      filters: {}
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../assets/shared.less';

.data-table-wrapper {
  /deep/ .data-table {
    .search-text-input-wrapper {
      text-align: right;
      margin-bottom: 10px;

      @media @phone {
        display: flex;
      }

      .text-input {
        width: 300px;
        display: inline-block;

        input {
          font-size: 12pt;
          border-radius: 5px;
        }
      }

      button {
        border: none;
        width: 50px;
        height: 40px;
        font-family: Rajdhani, sans-serif;
        font-size: 13pt;
        font-weight: 800;
        background-color: tint(@main-color, 5%);
        color: contrast(@main-color);
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 200ms;
        margin-left: 10px;

        &:hover {
          background-color: lighten(@main-color, 3%);
        }

        &:active {
          background-color: darken(@main-color, 3%);
        }

        &:disabled {
          opacity: 0.3;
          background-color: tint(@main-color, 5%);
          cursor: default;
        }
      }
    }

    .p-datatable-wrapper {
      @header-color: tint(@main-color, 5%);

      border: 1px solid @header-color;
      border-radius: 8px;

      table {
        thead {
          th {
            background-color: @header-color;
            padding: 15px 0;
            border-right: 1px solid fade(contrast(@main-color), 5%);
            color: fade(contrast(@main-color), 50%);
            font-weight: normal;

            &:last-child {
              border-right: none;
            }

            &.p-sortable-column {
              &:hover {
                background-color: tint(@header-color, 2%);
              }

              &:active {
                background-color: tint(@header-color, -1%);
              }

              &.p-highlight {
                color: contrast(@main-color);

                .p-sortable-column-icon {
                  color: contrast(@main-color);
                }
              }

              .p-sortable-column-icon {
                font-family: 'Font Awesome 5 Pro';
                margin: 0 10px;
                color: fade(contrast(@main-color), 50%);
                font-weight: 100;
                width: 20px;
                display: inline-block;

                &.pi-sort-alt::after {
                  content: '\f883'
                }

                &.pi-sort-amount-up-alt::after {
                  content: '\f160'
                }

                &.pi-sort-amount-down::after {
                  content: '\f885'
                }
              }

              .p-sortable-column-badge {
                font-size: 6pt;
                background: fade(contrast(@main-color), 10%);
                width: 19px;
                height: 19px;
                margin-left: 20px;
                transform: scale(1.3);
                border-radius: 100%;
                opacity: 0.6;
                position: absolute;
              }
            }

            &.p-resizable-column {
              .p-column-resizer {
                &:hover {
                  background-color: tint(@main-color, 10%);
                }
              }
            }
          }
        }

        tbody {
          background-color: fade(contrast(@main-color), 1%);

          tr {
            td {
              padding: 15px 20px;
              border-bottom: 1px solid fade(contrast(@main-color), 5%);
              border-right: 1px solid fade(contrast(@main-color), 5%);

              &:last-child {
                border-right: none;
              }

              &.p-selection-column {
                text-align: center;
              }
            }

            &.p-highlight td {
              background-color: fade(contrast(@main-color), 5%);
            }

            &.p-datatable-emptymessage td {
              text-align: center;
              font-weight: bold;
              font-size: 20pt;
              padding: 20px 0;
              opacity: 0.2;

              &::before {
                content: 'No data'
              }
            }

            &:last-child td {
              border-bottom: none;
            }
          }
        }
      }
    }

    .p-paginator {
      @button-border-radius: 5px;

      margin-top: 20px;
      position: relative;

      button {
        width: 40px;
        height: 40px;
        border: none;
        font-family: Rajdhani, sans-serif;
        font-size: 12pt;
        font-weight: 800;
        background-color: tint(@main-color, 5%);
        color: contrast(@main-color);
        border-radius: @button-border-radius;
        cursor: pointer;
        transition: background-color 200ms;
        margin: 0 3px;

        &:hover {
          background-color: lighten(@main-color, 3%);
        }

        &:active {
          background-color: darken(@main-color, 3%);
        }

        &:disabled {
          opacity: 0.3;
          background-color: tint(@main-color, 5%);
          cursor: default;
        }

        &.p-highlight {
          background-color: lighten(@main-color, 20%);
        }

        span {
          font-family: 'Font Awesome 5 Pro';
          font-weight: 100;
          font-size: 15pt;
        }

        .pi-angle-double-left::before {
          content: '\f100'
        }

        .pi-angle-left::before {
          content: '\f104'
        }

        .pi-angle-double-right::before {
          content: '\f101'
        }

        .pi-angle-right::before {
          content: '\f105'
        }
      }

      .p-paginator-pages {
        margin: 0 15px;

        @media @phone {
          display: none;
        }

        button {
          margin: 0;
          border-radius: 0;

          &:first-child {
            border-top-left-radius: @button-border-radius;
            border-bottom-left-radius: @button-border-radius;
          }

          &:last-child {
            border-top-right-radius: @button-border-radius;
            border-bottom-right-radius: @button-border-radius;
          }
        }
      }

      .p-paginator-current {
        margin-right: 20px;
        opacity: 0.7;
        position: absolute;
        right: 10px;
        top: 14px;

        @media @phone {
          display: none;
        }
      }
    }

    .p-column-resizer-helper {
      background-color: fade(contrast(@main-color), 10%);
      width: 3px;
      top: 51px !important;
      height: calc(100% - 112px) !important;
    }

    &.p-unselectable-text {
      user-select: none;
    }
  }
}
</style>
