<template>
  <page :title="pageTitle" backURI="/iam/users" :loading="loading">
    <template v-if="isEditing" #controls>
      <form-button v-if="$checkPermission('iam.users.update')" :icon="isUserBlocked ? 'fal fa-lock' : 'fal fa-unlock'" class="block-btn" @click="toggleUserBlock">{{blockButtonTitle}}</form-button>
      <form-button v-if="$checkPermission('iam.users.update')" icon="fal fa-key" @click="confirmResetPassword">Reset Password</form-button>
      <form-button v-if="$checkPermission('iam.users.delete')" icon="fal fa-trash" @click="confirmDeletion">Delete User</form-button>
    </template>

    <template #body>
      <div class="form">
        <div class="input-group size-sm">
          <text-input type="number" label="Employee ID" v-model="user.employeeId" :disabled="isEditing"/>
        </div>
        <div class="input-group">
          <text-input label="Name" v-model="user.name"/>
        </div>
        <div class="input-group">
          <text-input type="email" label="E-mail" v-model="user.email"/>
        </div>
        <div class="input-group size-sm">
          <text-input type="tel" label="Phone" v-model="user.phone"/>
        </div>
        <div class="input-group">
          <list-box :options="roles" optionLabel="name" dataKey="id" v-model="selectedRoles" label="Role">
            <template #option="slot">
              <div class="option-item role-item">
                <div class="icon">
                  <i class="fas fa-box"></i>
                </div>
                <div>
                  <div class="name">{{slot.option.name}}</div>
                  <div class="description">{{slot.option.description}}</div>
                </div>
              </div>
            </template>
          </list-box>
        </div>

        <info-box type="success" :text="infoBoxText"/>

        <div class="input-group bottom">
          <form-button v-if="showSaveBtn" @click="save">Save</form-button>
        </div>
      </div>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import FormButton from '@/components/FormButton.vue'
import TextInput from '@/components/TextInput.vue'
import ListBox from '@/components/ListBox.vue'
import InfoBox from '@/components/InfoBox.vue'
import gql from 'graphql-tag'

export default {
  name: 'UserEditorView',

  components: {
    Page,
    FormButton,
    TextInput,
    ListBox,
    InfoBox
  },

  methods: {
    getUser () {
      this.$apollo.query({
        query: gql`
          query ($id: ID) {
            iam {
              user: getUser(id: $id) {
                id: _id
                employeeId
                name
                email
                phone
                roles {
                  id: _id
                }
                lock {
                  type
                  expiresAt
                }
              }
            }
          }
        `,
        variables: {
          id: this.$route.params.id
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.user = response.data.iam.user
        this.user.roles = this.user.roles.map(i => i.id)

        this.getRoles()
      })
    },

    getRoles () {
      this.$apollo.query({
        query: gql`
          query {
            iam {
              roles: getRoles {
                id: _id
                name
                description
              }
            }
          }
        `,
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.roles = response.data.iam.roles

        this.selectedRoles = this.roles.filter(i => this.user.roles?.includes(i.id))

        this.loading = false

        this.setFocus()
      })
    },

    createUser () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($data: IamUserInput!) {
            iam {
              createUser(data: $data) {
                _id
              }
            }
          }
        `,
        variables: {
          data: {
            employeeId: this.user.employeeId,
            name: this.user.name,
            email: this.user.email,
            phone: this.user.phone,
            roles: this.selectedRoles.map(i => i.id)
          }
        }
      }).then(() => {
        this.$router.push('/iam/users')
      })
    },

    updateUser () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($id: ID!, $data: IamUserInput!) {
            iam {
              updateUser(id: $id, data: $data) {
                _id
              }
            }
          }
        `,
        variables: {
          id: this.user.id,
          data: {
            name: this.user.name,
            email: this.user.email,
            phone: this.user.phone,
            roles: this.selectedRoles.map(i => i.id)
          }
        }
      }).then(() => {
        this.$router.push('/iam/users')
      }).catch(error => {
        this.infoBoxText = `::error ${error.message}`
      })
    },

    confirmDeletion () {
      this.$confirm.require({
        message: 'Are you sure you want to delete this user?',
        header: 'Delete',
        icon: 'fas fa-exclamation-triangle',

        accept: () => {
          this.deleteUser()
        }
      })
    },

    deleteUser () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($ids: [ID!]!) {
            iam {
              deleteUsers(ids: $ids)
            }
          }
        `,
        variables: {
          ids: [this.user.id]
        }
      }).then(() => {
        this.$router.push('/iam/users')
      }).catch(error => {
        this.infoBoxText = `::error ${error.message}`
      })
    },

    confirmResetPassword () {
      this.$confirm.require({
        message: 'Are you sure you want to reset this user\'s password?',
        header: 'Reset Password',
        icon: 'fas fa-exclamation-triangle',

        accept: () => {
          this.resetPassword()
        }
      })
    },

    resetPassword () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($id: ID!, $data: IamUserInput!) {
            iam {
              updateUser(id: $id, data: $data) {
                _id
              }
            }
          }
        `,
        variables: {
          id: this.user.id,
          data: {
            password: 'dftpwd',
            mustChangePassword: true
          }
        }
      }).then(() => {
        this.infoBoxText = 'Password successfully reset. Password: <strong>"dftpwd"<strong>'
      }).catch(error => {
        this.infoBoxText = `::error ${error.message}`
      })
    },

    blockUser () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($id: ID!, $data: IamUserInput!) {
            iam {
              updateUser(id: $id, data: $data) {
                _id
              }
            }
          }
        `,
        variables: {
          id: this.user.id,
          data: {
            lock: {
              type: 'MANUAL_PERMANENT_BLOCK'
            }
          }
        }
      }).then(() => {
        this.infoBoxText = 'User successfully blocked'
        this.user.lock = {
          type: 'MANUAL_PERMANENT_BLOCK'
        }
      }).catch(error => {
        this.infoBoxText = `::error ${error.message}`
      })
    },

    unblockUser () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($id: ID!, $data: IamUserInput!) {
            iam {
              updateUser(id: $id, data: $data) {
                _id
              }
            }
          }
        `,
        variables: {
          id: this.user.id,
          data: {
            lock: null
          }
        }
      }).then(() => {
        this.infoBoxText = 'User successfully unblocked'
        this.user.lock = null
      }).catch(error => {
        this.infoBoxText = `::error ${error.message}`
      })
    },

    toggleUserBlock () {
      if (this.isUserBlocked) {
        this.unblockUser()
      } else {
        this.blockUser()
      }
    },

    save () {
      if (this.isEditing) {
        this.updateUser()
      } else {
        this.createUser()
      }
    },

    setFocus () {
      this.$nextTick().then(() => {
        if (!this.$isMobile()) {
          this.$el.querySelector('input:not(:disabled)').focus()
        }
      })
    }
  },

  computed: {
    isEditing () {
      return !!this.$route.params.id
    },

    isUserBlocked () {
      return this.user.lock !== null
    },

    pageTitle () {
      return this.isEditing ? 'Edit User' : 'Create User'
    },

    blockButtonTitle () {
      return this.isUserBlocked ? 'Unblock User' : 'Block User'
    },

    showSaveBtn () {
      if (this.isEditing) {
        return this.$checkPermission('iam.users.update')
      } else {
        return this.$checkPermission('iam.users.create')
      }
    }
  },

  data () {
    return {
      user: {},
      roles: [],
      selectedRoles: null,
      infoBoxText: null,
      loading: true
    }
  },

  mounted () {
    if (this.isEditing) {
      this.getUser()
    } else {
      this.getRoles()
    }
  }
}
</script>

<style lang="less" scoped>
.block-btn {
  color: @danger-color !important;
}

.role-item {
  display: flex;

  > div {
    flex: 1;
  }

  .icon {
    display: flex;
    flex: 0 0 45px;
    align-items: center;
    justify-content: center;
    font-size: 18pt;
    margin-right: 10px;
    color: tint(@accent-color, 10%);
  }

  .description {
    opacity: 0.5;
  }
}
</style>
