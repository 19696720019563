<template>
  <div v-if="visible">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Tab',

  props: {
    title: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      visible: false
    }
  }
}
</script>
