<template>
  <page :title="'Order Details' + (loading ? '' : ' #' + order.number)" :loading="loading" ref="page" backURI=":back">
    <template #controls>
      <form-button v-if="order.status.code === 'REPLACEMENT_REQUESTED'" @click="issueReplacementOrder" icon="fal fa-check-circle">Issue Replacement Order</form-button>
      <form-button @click="print" icon="fal fa-print">Print Order</form-button>
    </template>

    <template #body>
      <div class="form">
        <div class="form-row">
          <div class="form-item">
            <div class="label">ID</div>
            <div class="value"><copiable-text>{{order.id}}</copiable-text></div>
          </div>
          <div class="form-item" ref="status">
            <div class="label">Status</div>
            <div class="value status" :class="{retrying: loadingRetry}">
              <span class="badge" @click="displayFailReason = !displayFailReason" :class="order.status.code.toLowerCase()" :title="order.status.reason">{{order.status.code}}</span>
              <a v-if="$checkPermission('fast.ui.change-order-status')" href="#" @click.prevent="showChangeStatusModal"><i class="fal fa-user-bounty-hunter"></i> ADVANCED</a>
              <button v-if="order.status.code === 'FAILED'" class="retry-btn" @click="retry" :disabled="loadingRetry"><i class="far fa-sync-alt"></i><span>Retry</span></button>
              <div v-if="displayFailReason" class="reason">{{order.status.reason}}</div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <div class="label">Order Number</div>
            <div class="value">#<copiable-text>{{order.number}}</copiable-text></div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <div class="label">Client</div>
            <div class="value">
              {{order.client.name}} <span class="id">#{{order.client.id}}</span>
            </div>
          </div>
          <div class="form-item">
            <div class="label">System</div>
            <div class="value">{{order.system.name}}</div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <div class="label">Total devices</div>
            <div class="value">{{totalDevices}}</div>
          </div>
          <div class="form-item">
            <div class="label">Total types</div>
            <div class="value">{{totalVehicleTypes}}</div>
          </div>
          <div class="form-item">
            <div class="label">Total sensors</div>
            <div class="value">{{totalSensors}}</div>
          </div>
          <div v-if="order.status.code === 'REPLACEMENT_REQUESTED'" class="form-item">
            <div class="label">Failed QC Devices</div>
            <div class="value">{{failedQcDevices.length}}</div>
          </div>
        </div>
      </div>

      <div class="status-list" :class="{ overflow: order.statusHistory.length > 2, expanded: expandedSatusList }">
        <div class="status-list-title">Status History</div>

        <div class="list">
          <div v-for="status in order.statusHistory" :key="status.createdAt" class="status-list-item">
            <div class="date-time">{{$formatDateTime(status.createdAt)}}</div>
            <div v-if="status.code !== 'LOG'" class="status" :class="status.code.toLowerCase()">
              {{status.code}}
              <span v-if="status.meta && status.meta.subjects" class="counter">+{{status.meta.subjects.length}}</span>
            </div>
            <div v-if="status.code === 'FAILED'" class="error">{{status.reason}}</div>
            <div v-if="status.code === 'LOG'" class="log" :class="{ failed: status.reason && status.reason.includes('failed') }">
              <i class="fas fa-square-info"></i> {{status.reason}}
            </div>
            <div class="user">{{status.user.name}} • #{{status.user.employeeId}}</div>
          </div>
        </div>

        <div class="expand-list" @click="expandedSatusList = !expandedSatusList">
          <i v-if="expandedSatusList" class="fal fa-chevron-up"></i>
          <i v-else class="fal fa-chevron-down"></i>
        </div>
      </div>

      <div class="device-list">
        <div class="device-list-title">Devices</div>

        <div v-if="order.totalDevices > 4" class="navigation-bar">
          <div class="wrapper">
            <div v-if="false" @click="toggleFilter">
              <i class="fas fa-filter"></i>
            </div>

            <div @click="goToFirstDeviceItem">
              <i class="fas fa-arrow-to-top"></i>
            </div>
            <div @click="goToPreviousDeviceItem">
              <i class="fas fa-arrow-up"></i>
            </div>
            <div @click="goToNextDeviceItem">
              <i class="fas fa-arrow-down"></i>
            </div>
            <div @click="goToLastDeviceItem">
              <i class="fas fa-arrow-to-bottom"></i>
            </div>
          </div>
        </div>

        <div v-for="orderItem in order.items" :key="orderItem._id" class="device-group">
          <div class="device-group-title">
            {{orderItem.batchSettings.name}} <span class="id">#{{orderItem.batchSettings.id}}</span>
            <span class="bullet">•</span>
            {{orderItem.vehicle.name}} <span class="id">#{{orderItem.vehicle.id}}</span>
            <span class="bullet">•</span>
            {{orderItem.devices.length}} device{{orderItem.devices.length > 1 ? 's' : ''}}

            <div v-if="orderItem.locations.length > 0" class="locations">
              <i class="fas fa-map-marker-alt"></i>
              {{orderItem.locations.map(i => i.name).join(', ')}}
            </div>
          </div>

          <div v-for="orderItemDevice in orderItem.devices"
               v-observe="{
                 callback: updateInViewDevice,
                 id: orderItemDevice._id,
                 intersection: {
                   threshold: 1
                 }
               }"
               :key="orderItemDevice._id"
               class="device-item"
               :class="{ 'qc-failed': orderItemDevice.qcFailed }"
               ref="deviceItem"
          >
            <span v-if="orderItemDevice.qcFailed" class="qc-failed-overlay">QC FAILED</span>

            <div class="head">
              <div class="device-id">
                <copiable-text>{{orderItemDevice.deviceId}}</copiable-text>

                <span
                  v-if="order.status.code !== 'FAILED' && (new Date(order.createdAt).getTime() > new Date('2023-10-18').getTime())"
                  :set="status = getAllCommandsStatus(orderItemDevice.cmdIds)"
                  class="command-status"
                  title="Show all commands"
                  @click="orderItemDevice.deviceCommandExpanded = !orderItemDevice.deviceCommandExpanded"
                >
                  <i v-if="status === false" class="far fa-spinner-third fa-spin loading"></i>
                  <i v-else-if="orderItemDevice.qcForciblyPass" title="Executed (forced)" class="fas fa-check-circle success"></i>
                  <i v-else-if="status === 'QUEUED'" title="Pending" class="fas fa-clock warning"></i>
                  <i v-else-if="status === 'RETRY'" title="Waiting to retry" class="far fa-history warning"></i>
                  <i v-else-if="status === 'NOT_FOUND'" title="Command not found" class="fas fa-exclamation-triangle warning"></i>
                  <i v-else-if="status === 'IN_PROGRESS'" title="In Progress..." class="fas fa-play-circle warning"></i>
                  <i v-else-if="status === 'OK'" title="Executed" class="fas fa-check-circle success"></i>
                  <i v-else :title="status" class="fas fa-minus-circle error"></i>

                  <i class="fas caret" :class="orderItemDevice.deviceCommandExpanded ? 'fa-caret-up' : 'fa-caret-down'"></i>
                </span>

                <button
                  v-if="$checkPermission('fast.ui.retry-failed-device') && order.status.code === 'PROCESSED' && !orderItemDevice.qcForciblyPass && !orderItemDevice.qcFailed"
                  class="retry-btn"
                  title="Retry device" @click="confirmRetryDevice(orderItemDevice)"
                >
                  <i class="far fa-refresh" :class="{'fa-spin': loadingRetryDevice[orderItemDevice.deviceId]}"></i>
                </button>
              </div>

              <div class="controls">
                <button
                  v-if="$checkPermission('fast.ui.qc-force-pass') && order.status.code === 'PROCESSED' && !orderItemDevice.qcForciblyPass && !orderItemDevice.qcFailed && !isAllCommandsSuccess(orderItemDevice)"
                  @click="confirmForciblyPass(orderItemDevice._id)"
                  class="control-btn"
                >
                  Force Pass
                </button>

                <button
                  v-if="$checkPermission('fast.ui.revert-failed-qc') && order.status.code === 'PROCESSED' && orderItemDevice.qcFailed && !orderItemDevice.qcReplaced"
                  @click="confirmRevertQcFailed(orderItemDevice._id)"
                  class="control-btn"
                >
                  Revert QC Failed
                </button>

                <button
                  v-if="$checkPermission('fast.ui.remove-order-item') && (order.status.code === 'PROCESSED' || order.status.code === 'FAILED')"
                  @click="confirmRemoveItem(orderItemDevice._id)"
                  class="control-btn"
                >
                  Remove Device
                </button>

                <span v-if="orderItemDevice.qcReplacement" class="badge qc-replacement">REPLACEMENT</span>
                <span v-if="orderItemDevice.qcReplaced" class="badge qc-replaced">REPLACED</span>
                <span v-if="orderItemDevice.qcForciblyPass" class="badge qc-forcibly-pass">FORCED PASS</span>

                <div v-if="!orderItemDevice.qcFailed && !orderItemDevice.qcForciblyPass && order.status.code === 'PROCESSED'" class="qc-failed-container">
                  <span>QC FAILED</span> <checkbox v-model="qcFailedItems" :item="orderItemDevice._id"/>
                </div>
              </div>
            </div>
            <div v-if="orderItemDevice.deviceCommandExpanded" class="device-commands">
              <div v-for="orderItemDeviceCommand in orderItemDevice.cmdIds" :key="orderItemDeviceCommand"
                   :set="command = getCommand(orderItemDeviceCommand)"
                   class="command"
              >
                <div v-if="order.status.code !== 'FAILED'" class="command-status">
                  <i v-if="command === false" class="far fa-spinner-third fa-spin loading"></i>
                  <i v-else-if="orderItemDevice.qcForciblyPass" title="Executed (forced)" class="fas fa-check-circle success"></i>
                  <i v-else-if="command.status === 'QUEUED'" title="Pending" class="fas fa-clock warning"></i>
                  <i v-else-if="command.status === 'RETRY'" title="Waiting to retry" class="far fa-history warning"></i>
                  <i v-else-if="command.status === 'NOT_FOUND'" title="Command not found" class="fas fa-exclamation-triangle warning"></i>
                  <i v-else-if="command.status === 'IN_PROGRESS'" title="In Progress..." class="fas fa-play-circle warning"></i>
                  <i v-else-if="command.status === 'OK'" title="Executed" class="fas fa-check-circle success"></i>
                  <i v-else :title="command.status" class="fas fa-minus-circle error"></i>
                </div>
                <div class="name"><copiable-text>{{ command.cmd }}</copiable-text></div>
                <div class="params" v-html="getFomattedCommandParams(command)"></div>
              </div>
            </div>

            <div class="sensors">
              <div v-for="orderItemDeviceSensor in orderItemDevice.sensors"
                   :key="orderItemDeviceSensor._id"
                   :set="command = getCommand(orderItemDeviceSensor.cmdId)"
                   class="sensor-item"
              >
                <div v-if="order.status.code !== 'FAILED'" class="command-status">
                  <i v-if="command === false" class="far fa-spinner-third fa-spin loading"></i>
                  <i v-else-if="orderItemDevice.qcForciblyPass" title="Executed (forced)" class="fas fa-check-circle success"></i>
                  <i v-else-if="command.status === 'QUEUED'" title="Pending" class="fas fa-clock warning"></i>
                  <i v-else-if="command.status === 'RETRY'" title="Waiting to retry" class="far fa-history warning"></i>
                  <i v-else-if="command.status === 'NOT_FOUND'" title="Command not found" class="fas fa-exclamation-triangle warning"></i>
                  <i v-else-if="command.status === 'IN_PROGRESS'" title="In Progress..." class="fas fa-play-circle warning"></i>
                  <i v-else-if="command.status === 'OK'" title="Executed" class="fas fa-check-circle success"></i>
                  <i v-else :title="command.status" class="fas fa-minus-circle error"></i>
                </div>
                <div class="sensor-id">
                  <copiable-text>{{orderItemDeviceSensor.id}}</copiable-text>

                  <!-- Button hidden due to undone task -->
                  <button
                    v-if="false && $checkPermission('fast.ui.retry-failed-sensor') && order.status.code === 'PROCESSED' && !orderItemDevice.qcForciblyPass && ['QUEUED', 'NOT_FOUND'].includes(status)"
                    @click="retrySensor(orderItemDevice.deviceId, orderItemDeviceSensor.index)"
                    class="retry-btn"
                  >
                    <i class="far fa-refresh"></i>
                  </button>
                </div>
                <div class="sensor-name">{{orderItemDeviceSensor.name}}</div>

                <div class="meta-info-container">
                  <div v-if="orderItemDeviceSensor.meta.manufacturingDateCode" class="meta-info">{{orderItemDeviceSensor.meta.manufacturingDateCode}}</div>
                  <div v-if="orderItemDeviceSensor.meta.manufacturingPartNumber" class="meta-info">{{orderItemDeviceSensor.meta.manufacturingPartNumber}}</div>
                  <div v-if="orderItemDeviceSensor.meta.manufacturingQualityControlCode" class="meta-info">{{orderItemDeviceSensor.meta.manufacturingQualityControlCode}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="order.status.code === 'PROCESSED'" class="input-group bottom">
        <form-button
          :icon="'fal ' + (qcFailedItems.length === 0 ? 'fa-check-circle' : 'fa-exclamation-triangle')"
          :success="qcFailedItems.length === 0" :warning="qcFailedItems.length > 0"
          icon-float
          icon-right
          @click="setQcProcessing"
        >
          <strong>QC {{qcFailedItems.length === 0 ? 'OK' : 'FAILED'}}</strong>
          <div v-if="qcFailedItems.length > 0">Request replacement for {{qcFailedItems.length}} item{{qcFailedItems.length > 1 ? 's' : ''}}</div>
        </form-button>
      </div>

      <modal title="filter" ref="filterModal">
        <div class="form">
          <div class="input-group">
            <div class="label">Select status you want to see:</div>
          </div>
          <div class="input-group">
            <checkbox label="Failed" item="failed" v-model="filter"/>
          </div>
          <div class="input-group">
            <checkbox label="Replacement" item="replacement" v-model="filter"/>
          </div>
        </div>
      </modal>

      <modal title="Change Order Status" ref="changeStatusModal">
        <div class="form">
          <div style="width: 400px;">
            <list-box :options="changeStatusList" optionLabel="label" dataKey="value" v-model="changeStatusStatus" :filter="false" :multiple="false">
              <template #option="slot">
                <div class="name" style="margin-left: 10px;">{{slot.option.label}}</div>
              </template>
            </list-box>
          </div>

          <text-input
            label="Reason"
            v-if="changeStatusStatus.value === 'FAILED' || changeStatusStatus.value === 'LOG' || changeStatusStatus.value === 'CANCELED'"
            :invalid="changeStatusReasonInvalid"
            v-model="changeStatusReason"
            style="margin-top: 20px;"
          />
        </div>

        <template #footer>
          <button class="btn" @click="$refs.changeStatusModal.close()">Cancel</button>
          <button class="btn primary" @click="changeOrderStatus">Change Status</button>
        </template>
      </modal>

      <iframe id="print-helper-frame"></iframe>
    </template>
  </page>
</template>

<script>
import gql from 'graphql-tag'

import Checkbox from '@/components/Checkbox.vue'
import CopiableText from '@/components/CopiableText.vue'
import FormButton from '@/components/FormButton.vue'
import ListBox from '@/components/ListBox.vue'
import Modal from '@/components/Modal.vue'
import Page from '@/components/Page.vue'
import TextInput from '@/components/TextInput.vue'

import { ObserveVisibility } from 'vue-observe-visibility'

let t1 = null
let t2 = null
let t3 = null

export default {
  name: 'OrderDetailsView',

  components: {
    Page,
    CopiableText,
    FormButton,
    Checkbox,
    Modal,
    TextInput,
    ListBox
  },

  directives: {
    observe: ObserveVisibility
  },

  methods: {
    getOrder () {
      this.$apollo.query({
        query: gql`
          query ($id: ID!) {
            fast {
              order: getOrder(id: $id) {
                id: _id
                number
                totalDevices
                status {
                  code
                  reason
                }
                statusHistory {
                  code
                  reason
                  user {
                    name
                    employeeId
                  }
                  meta
                  createdAt
                }
                system {
                  id
                  name
                }
                client {
                  id
                  name
                }
                items {
                  _id
                  batchSettings {
                    id
                    name
                  }
                  locations {
                    id
                    name
                  }
                  vehicle {
                    id
                    name
                  }
                  devices {
                    _id
                    deviceId
                    qcFailed
                    qcReplacement
                    qcReplaced
                    qcForciblyPass
                    cmdIds
                    sensors {
                      _id
                      index
                      id
                      name
                      type
                      cmdId
                      meta {
                        manufacturingDateCode
                        manufacturingPartNumber
                        manufacturingQualityControlCode
                      }
                    }
                  }
                }
                createdAt
              }
            }
          }
        `,
        variables: {
          id: this.$route.params.id
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.order = response.data.fast.order
        this.loading = false
        this.loadingRetry = false

        this.order.items.forEach(item => {
          item.devices.forEach(device => {
            this.$set(device, 'deviceCommandExpanded', false)
          })
        })

        if (this.order.status.code !== 'FAILED') {
          this.getCommandStatus()
        }
      })
    },

    getCommandStatus () {
      const commandIds = []

      this.order.items.forEach(item => {
        item.devices.forEach(device => {
          commandIds.push(...device.cmdIds ?? [])

          device.sensors.forEach(sensor => {
            commandIds.push(sensor.cmdId)
          })
        })
      })

      this.$apollo.query({
        query: gql`
          query ($systemId: ID!, $commandIds: [Int!]!) {
            platform {
              statuses: getCommandStatus(systemId: $systemId, commandIds: $commandIds) {
                id
                cmd
                params
                status
              }
            }
          }
        `,
        variables: {
          systemId: this.order.system.id,
          commandIds
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.commandStatus = response.data.platform.statuses
      })
    },

    getCommand (commandId) {
      return this.commandStatus.find(i => i.id === commandId) ?? false
    },

    maybeQcFailed (item) {
      const commandIds = item.sensors.map(i => i.cmdId)

      return (!this.commandStatus.filter(i => commandIds.includes(i.id))
        .every(i => i.status === 'OK')) && (this.order.status.code === 'PROCESSED' || this.order.status.code === 'REPLACEMENT_READY') && (item.qcFailed !== true)
    },

    isAllCommandsSuccess (item) {
      const commandIds = item.sensors.map(i => i.cmdId)

      return this.commandStatus.filter(i => commandIds.includes(i.id))
        .every(i => i.status === 'OK')
    },

    getAllCommandsStatus (cmdIds) {
      const commands = this.commandStatus.filter(i => cmdIds.includes(i.id))

      if (commands.length === 0) {
        return false
      }

      if (commands.every(i => i.status === 'OK')) {
        return 'OK'
      } else if (commands.some(i => i.status === 'TIMED_OUT')) {
        return 'TIMED_OUT'
      } else if (commands.some(i => i.status === 'IN_PROGRESS')) {
        return 'IN_PROGRESS'
      } else if (commands.some(i => i.status === 'NOT_FOUND')) {
        return 'NOT_FOUND'
      } else if (commands.some(i => i.status === 'RETRY')) {
        return 'RETRY'
      } else if (commands.some(i => i.status === 'QUEUED')) {
        return 'QUEUED'
      }

      return false
    },

    getFomattedCommandParams (command) {
      return Object.entries(command.params).map(([k, v]) => `<span style="color: #f5ff9e;">${k}="<span style="color: #ff9300;">${v}</span>"<span>`).join('<br>')
    },

    setQcProcessing () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($orderId: ID!, $failedDevices: [ID]!) {
            fast {
              setQcProcessing(orderId: $orderId, failedDevices: $failedDevices)
            }
          }
        `,
        variables: {
          orderId: this.order.id,
          failedDevices: this.qcFailedItems
        }
      }).then(() => {
        this.getOrder()

        this.$refs.page.$el.scroll({
          top: 0,
          behavior: 'smooth'
        })

        setTimeout(() => {
          this.$refs.status.classList.add('pulse')

          setTimeout(() => {
            this.$refs.status.classList.remove('pulse')
          }, 3000)
        }, 1500)
      })
    },

    setForciblyPass (deviceItemId) {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($orderId: ID!, $deviceItemId: ID!) {
            fast {
              setQcForciblyPass(orderId: $orderId, deviceItemId: $deviceItemId)
            }
          }
        `,
        variables: {
          orderId: this.order.id,
          deviceItemId: deviceItemId
        }
      }).then(() => {
        this.getOrder()

        this.qcFailedItems = this.qcFailedItems.filter(i => i !== deviceItemId)
      })
    },

    revertQcFailed (deviceItemId) {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($orderId: ID!, $deviceItemId: ID!) {
            fast {
              revertQcFailed(orderId: $orderId, deviceItemId: $deviceItemId)
            }
          }
        `,
        variables: {
          orderId: this.order.id,
          deviceItemId: deviceItemId
        }
      }).then(() => {
        this.getOrder()
      })
    },

    removeDevice (deviceItemId) {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($orderId: ID!, $deviceItemId: ID!) {
            fast {
              removeDeviceItem(orderId: $orderId, deviceItemId: $deviceItemId)
            }
          }
        `,
        variables: {
          orderId: this.order.id,
          deviceItemId: deviceItemId
        }
      }).then(() => {
        this.getOrder()
      })
    },

    confirmForciblyPass (deviceItemId) {
      this.$confirm.require({
        message: 'Are you sure you want to force this device to pass the QC tests?',
        header: 'WARNING!',
        icon: 'fas fa-exclamation-triangle',

        accept: () => {
          this.setForciblyPass(deviceItemId)
        }
      })
    },

    confirmRevertQcFailed (deviceItemId) {
      this.$confirm.require({
        message: 'Are you sure you want revert this failed QC?',
        header: 'WARNING!',
        icon: 'fas fa-exclamation-triangle',

        accept: () => {
          this.revertQcFailed(deviceItemId)
        }
      })
    },

    confirmRemoveItem (deviceItemId) {
      this.$confirm.require({
        message: 'Are you sure you want remove this device?',
        header: 'WARNING!',
        icon: 'fas fa-exclamation-triangle',

        accept: () => {
          this.removeDevice(deviceItemId)
        }
      })
    },

    confirmRetryDevice (deviceItem) {
      this.$confirm.require({
        message: 'This will reset all device sensors and settings. Continue?',
        header: 'WARNING!',
        icon: 'fas fa-exclamation-triangle',

        accept: () => {
          this.retryDevice(deviceItem)
        }
      })
    },

    showChangeStatusModal () {
      this.changeStatusStatus = {
        label: this.order.status.code,
        value: this.order.status.code
      }

      this.changeStatusReason = ''
      this.changeStatusReasonInvalid = null

      this.$refs.changeStatusModal.open()
    },

    changeOrderStatus () {
      if ((this.changeStatusStatus.value === 'FAILED' || this.changeStatusStatus.value === 'LOG' || this.changeStatusStatus.value === 'CANCELED') && this.changeStatusReason.length === 0) {
        this.changeStatusReasonInvalid = 'Reason is mandatory for this type of status'
        return
      }

      this.$apollo.mutate({
        mutation: gql`
          mutation ($orderId: ID!, $status: String!, $reason: String) {
            fast {
              changeOrderStatus(orderId: $orderId, status: $status, reason: $reason)
            }
          }
        `,
        variables: {
          orderId: this.order.id,
          status: this.changeStatusStatus.value,
          reason: this.changeStatusReason.length > 0
            ? this.changeStatusReason
            : undefined
        }
      }).then(() => {
        this.$refs.changeStatusModal.close()

        this.getOrder()
      })
    },

    issueReplacementOrder () {
      sessionStorage.setItem('order-generator', JSON.stringify({
        number: this.order.number,
        replacementOrder: true,
        account: {
          value: this.order.client.id,
          label: this.order.client.name,
          systemId: this.order.system.id,
          systemName: this.order.system.name
        },
        items: this.order.items.map(item => {
          const devices = item.devices.filter(i => i.qcFailed && !i.qcReplaced)

          item.quantity = devices.length
          item.vehicle.sensors = devices.reduce((a, i) => a + i.sensors.length, 0)

          return item
        }),
        issuedBy: this.$store.state.auth.user.employeeId
      }))

      document.querySelector('#print-helper-frame').src = '/fast/order-generator/print'
    },

    retry () {
      this.loadingRetry = true

      this.$apollo.mutate({
        mutation: gql`
          mutation ($orderId: ID!) {
            fast {
              status: retryProcessingOrder(orderId: $orderId) {
                code
                reason
              }
            }
          }
        `,
        variables: {
          orderId: this.order.id
        }
      }).then(() => {
        this.displayFailReason = true

        this.getOrder()
      })
    },

    retryDevice (deviceItem) {
      this.loadingRetryDevice[deviceItem.deviceId] = true

      this.$apollo.mutate({
        mutation: gql`
          mutation ($orderId: ID!, $deviceId: String) {
            fast {
              status: retryProcessingOrder(orderId: $orderId, deviceId: $deviceId) {
                code
                reason
              }
            }
          }
        `,
        variables: {
          orderId: this.order.id,
          deviceId: deviceItem.deviceId
        }
      }).then(() => {
        this.getOrder()

        delete this.loadingRetryDevice[deviceItem.deviceId]
      })
    },

    retrySensor (deviceId, sensorIndex) {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($orderId: ID!, $deviceId: String!, $sensorIndex: Int!) {
            fast {
              result: retryProcessingSensor(orderId: $orderId, deviceId: $deviceId, sensorIndex: $sensorIndex)
            }
          }
        `,
        variables: {
          orderId: this.order.id,
          deviceId,
          sensorIndex
        }
      }).then(() => {
        this.getOrder()
      })
    },

    print () {
      const frame = document.querySelector('#print-helper-frame')

      frame.src = this.$route.path + '/print'
    },

    updateInViewDevice (isVisible, entry) {
      if (isVisible) {
        this.currentDeviceItem = {
          el: entry.target,
          id: entry.target._vue_visibilityState.options.id
        }
      }
    },

    toggleFilter () {
      this.$refs.filterModal.open()
    },

    goToFirstDeviceItem () {
      this.$refs.deviceItem[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    },

    goToLastDeviceItem () {
      this.$refs.deviceItem[this.$refs.deviceItem.length - 1].scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    },

    goToNextDeviceItem () {
      let el = this.currentDeviceItem.el?.nextElementSibling

      if (el === undefined) {
        el = this.$refs.deviceItem[0]
      }

      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    },

    goToPreviousDeviceItem () {
      if (this.currentDeviceItem.el.previousElementSibling !== null) {
        this.currentDeviceItem.el.previousElementSibling.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    }
  },

  computed: {
    totalVehicleTypes () {
      return this.order.items.length
    },

    totalDevices () {
      return this.order.totalDevices
    },

    totalSensors () {
      return this.order.items.reduce((a, b) => a + b.devices.filter(j => !j.qcFailed).reduce((c, d) => c + d.sensors.length, 0), 0)
    },

    failedQcDevices () {
      const items = []

      this.order.items.forEach(item => {
        item.devices.forEach(device => {
          if (device.qcFailed) {
            items.push(device)
          }
        })
      })

      return items
    }

    // filteredOrderItems () {
    //   if (this.filter.length > 0) {
    //     const ret = []

    //     this.order.items.forEach(item => {
    //       item.devices.forEach(device => {
    //         if (this.filter.includes('failed')) {
    //           if ()
    //         }
    //       })
    //     })
    //   } else {
    //     return this.order.items
    //   }
    // }
  },

  data () {
    return {
      order: {},
      loading: true,
      loadingRetry: false,
      displayFailReason: false,
      qcFailedItems: [],
      commandStatus: [],
      expandedSatusList: false,
      currentDeviceItem: {
        el: null,
        id: null
      },

      observerOptions: {
        callback: this.updateInViewDevice,
        intersection: {
          threshold: 1
        }
      },

      filter: [],

      changeStatusList: [
        {
          label: 'DONE',
          value: 'DONE'
        },
        {
          label: 'FAILED',
          value: 'FAILED'
        },
        {
          label: 'REPLACEMENT_REQUESTED',
          value: 'REPLACEMENT_REQUESTED'
        },
        {
          label: 'PROCESSED',
          value: 'PROCESSED'
        },
        {
          label: 'LOG',
          value: 'LOG'
        },
        {
          label: 'CANCELED',
          value: 'CANCELED'
        }
      ],

      changeStatusStatus: '',
      changeStatusReason: '',
      changeStatusReasonInvalid: null,

      loadingRetryDevice: {}
    }
  },

  mounted () {
    setTimeout(() => {
      this.getOrder()
    }, 1000)

    const timerHandler = () => {
      if (this.order.status.code !== 'FAILED' && this.commandStatus.some(i => ['QUEUED', 'IN_PROGRESS', 'RETRY'].includes(i.status))) {
        this.getCommandStatus()
      }
    }

    let t1ExecutionCount = 1
    let t2ExecutionCount = 1
    let t3ExecutionCount = 1

    t1 = setInterval(() => {
      timerHandler()

      if (t1ExecutionCount === 24) {
        clearInterval(t1)

        t2 = setInterval(() => {
          timerHandler()

          if (t2ExecutionCount === 30) {
            clearInterval(t2)

            t3 = setInterval(() => {
              timerHandler()

              if (t3ExecutionCount === 60) {
                clearInterval(t3)
              }

              t3ExecutionCount++
            }, 30_000)
          }

          t2ExecutionCount++
        }, 10_000)
      }

      t1ExecutionCount++
    }, 5_000)
  },

  beforeDestroy () {
    clearInterval(t1)
    clearInterval(t2)
    clearInterval(t3)
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../../assets/shared.less';

.id {
  opacity: 0.5;
  margin-left: 10px;
  font-size: 12pt;
}

.form-item.pulse {
  transform-origin: left;
  animation: pulse-scale 1000ms;
}

.value.status {
  &.retrying {
    .badge,
    .reason {
      opacity: 0.3 !important;
    }
  }

  a {
    font-size: 11pt;
    vertical-align: middle;
    font-weight: bold;
    margin: 0 10px;
  }

  .badge {
    @color: @success-color;

    padding: 1px 5px;
    border-radius: 3px;
    transition: opacity 0.5s;
    background: fade(@color, 10%);
    color: @color;
    pointer-events: none;

    &:hover {
      background: fade(@color, 80%);
    }

    &:active {
      background: fade(@color, 50%);
    }

    &.processed,
    &.replacement_ready {
      @color: lighten(@success-color, 20%);

      background: fade(@color, 10%);
      color: @color;
    }

    &.replacement_requested {
      @color: lighten(@warning-color, 20%);

      background: fade(@color, 10%);
      color: @color;
    }

    &.failed {
      @color: @danger-color;

      background: fade(@color, 50%);
      color: contrast(@color);
      pointer-events: all;
      cursor: pointer;
    }
  }

  .retry-btn {
    vertical-align: middle;
    font-size: 12pt;
    margin-left: 20px;
    margin-top: -5px;
    background: transparent;
    color: @accent-color;
    border: none;
    cursor: pointer;

    i {
      transition: all 200ms;
      margin-right: 5px;
      opacity: 0.5;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }

    &:hover {
      text-decoration: underline;

      i {
        opacity: 1;
      }
    }

    &:active {
      opacity: 0.5;
    }

    &:disabled {
      pointer-events: none;

      span {
        opacity: 0.3;
      }

      i {
        animation: fa-spin 1s linear infinite;
        opacity: 1;
        font-size: 25px;
      }
    }
  }

  .reason {
    margin-left: 5px;
    font-size: 12pt;
    font-family: Consolas, monospace;
    color: lighten(@danger-color, 10%);
    transition: opacity 0.5s;
  }
}

.status-list {
  margin: 20px 0;

  &.overflow {
    .list {
      mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgb(0, 0, 0)));
      max-height: 150px;
    }

    .expand-list {
      display: block;
    }

    &.expanded {
      .list {
        max-height: 2000px;
        mask-image: none;
      }
    }
  }

  .status-list-title {
    font-size: 15pt;
    opacity: 0.5;
    font-weight: 100;
  }

  .list {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    transition: all 300ms;

    .status-list-item {
      padding: 0 0 12px 10px;
      margin-left: 5px;
      border-left: 2px solid fade(contrast(@main-color), 20%);
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        top: 4px;
        left: -6px;
        background: tint(contrast(contrast(@main-color)), 6%);
        border: 2px solid shade(@accent-color, 50%);
        border-radius: 50%;
      }

      &:last-child {
        padding-bottom: 0;
        border-left-color: transparent
      }

      .date-time {
        opacity: 0.5;
        font-size: 11pt;
      }

      .status {
        font-size: 14pt;
        color: @success-color;
        padding-left: 10px;

        &.replacement_requested {
          color: @warning-color;
        }

        &.failed {
          color: @danger-color;
        }

        &.processed,
        &.replacement_ready {
          color: lighten(@success-color, 20%);
        }

        .counter {
          font-size: 8pt;
          padding: 1px 3px;
          background: #ff5421;
          color: #ffffff;
          border-radius: 3px;
          text-align: center;
          min-width: 20px;
          opacity: 0.5;
          margin-left: 10px;
          display: inline-block;
          vertical-align: middle;
        }
      }

      .error {
        font-family: monospace;
        color: @danger-color;
        font-size: 10pt;
        margin-left: 10px;
        padding: 5px;
        border: 1px dotted fade(@danger-color, 50%);
        display: inline-block;
        border-radius: 5px;
      }

      .log {
        opacity: 0.4;
        margin-left: 10px;

        &.failed {
          color: @danger-color;
          opacity: 1;
        }

        i {
          opacity: 0.5;
          margin-right: 5px;
        }
      }

      .user {
        font-size: 11pt;
        opacity: 0.7;
        padding-left: 10px;
      }
    }
  }

  .expand-list {
    display: none;
    padding: 3px;
    text-align: center;
    background: fade(contrast(@main-color), 1%);
    margin-top: 5px;
    margin-left: 25px;
    border-radius: 5px;
    cursor: pointer;

    i {
      opacity: 0.6;
    }

    &:hover {
      background: fade(contrast(@main-color), 5%);

      i {
        opacity: 1;
      }
    }

    &:active {
      background: fade(contrast(@main-color), 2%);
    }
  }
}

.device-list {
  position: relative;

  .device-list-title {
    font-size: 15pt;
    opacity: 0.5;
    font-weight: 100;
  }

  .navigation-bar {
    position: sticky;
    top: 150px;
    width: 0;
    float: right;
    transform: translate(30px, 50px);

    @media @phone {
      display: none;
    }

    .wrapper {
      opacity: 0.6;
      padding: 10px;
      width: 65px;

      &:hover {
        opacity: 1;
      }

      > div {
        background: fade(contrast(@main-color), 10%);
        border-radius: 50px;
        margin-top: 5px;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.8;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }

        &:active {
          opacity: 0.6;
          transform: scale(0.9);
        }
      }
    }
  }

  .device-group {
    margin-bottom: 40px;

    .device-group-title {
      font-size: 16pt;
      font-weight: 800;
      opacity: 0.8;
      color: @accent-color;
      margin-bottom: 10px;

      .locations {
        font-size: 13pt;
        color: contrast(@main-color);
        opacity: 0.5;
        font-weight: normal;
      }

      @media @phone {
        font-size: 14pt;
      }

      .bullet {
        margin: 0 10px;

        @media @phone {
          height: 0;
          display: block;
        }
      }
    }

    .device-item {
      padding-left: 15px;
      padding-bottom: 15px;
      margin-left: 10px;
      margin-top: -7px;
      border-left: 2px solid shade(contrast(@main-color), 80%);
      position: relative;

      .retry-btn {
        background-color: @accent-color;
        border: none;
        border-radius: 50px;
        width: 25px;
        height: 25px;
        font-size: 14pt;
        margin-left: 20px;
        cursor: pointer;

        .fa-spin {
          animation-duration: 1s;
        }

        &:hover {
          background: tint(@accent-color, 40%);
        }

        &:active {
          background: shade(@accent-color, 10%);
        }

        &:disabled {
          opacity: 0.5;
        }
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        top: 7px;
        left: -9px;
        background: tint(contrast(contrast(@main-color)), 6%);
        border: 3px solid shade(@accent-color, 50%);
        border-radius: 50%;
      }

      &:last-child {
        padding-bottom: 0;
        border-left-color: transparent
      }

      &.qc-failed {
        opacity: 0.5;
      }

      .qc-failed-overlay {
        font-weight: bold;
        font-size: 35pt;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-15deg);
        color: @danger-color;
        border: 5px dashed @danger-color;
        border-radius: 15px;
        background: fade(@danger-color, 30%);
        padding: 15px 25px;
        text-align: center;
      }

      .badge {
        padding: 1px 3px;
        border-radius: 3px;
        height: 21px;
        margin-top: 1px;
        margin-left: 10px;

        @media @phone {
          display: block;
          margin: 10px;
        }

        &.qc-replacement {
          background: fade(@warning-color, 30%);
          color: @warning-color;
        }

        &.qc-replaced {
            background: fade(@danger-color, 30%);
            color: @danger-color;
        }

        &.qc-forcibly-pass {
            background: fade(@success-color, 30%);
            color: @success-color;
        }
      }

      .head {
        display: flex;

        @media @phone {
          display: block;
        }

        .device-id {
          font-family: Consolas, monospace;
          font-size: 18pt;
          flex: 1;

          .command-status {
            margin-left: 10px;
            cursor: pointer;
            padding: 2px 8px;
            border-radius: 4px;

            &:hover {
              background: rgba(255, 255, 255, 0.1);
            }

            &:active {
              opacity: 0.7;
            }

            .caret {
              margin-left: 5px;
              opacity: 0.5;
            }
          }
        }

        .controls {
          flex: 1;
          text-align: right;

          @media @phone {
            text-align: center;
            margin-top: 15px;
          }

          .control-btn {
            background: fade(@accent-color, 50%);
            border: none;
            color: #bbb;
            padding: 5px 10px;
            border-radius: 50px;
            margin-left: 10px;
            cursor: pointer;

            &:hover {
              background: fade(@accent-color, 70%);
            }

            &:active {
              background: fade(@accent-color, 30%);
            }
          }

          .qc-failed-container {
            display: inline;
            margin-left: 20px;

            @media @phone {
              display: block;
              margin: 20px 0;
            }

            span {
              font-weight: 800;
              margin-right: 5px;
              font-size: 10pt;
              cursor: pointer;
              user-select: none;
            }
          }
        }
      }

      .device-commands {
        margin: 5px 0 15px 0;

        .command {
          border-radius: 7px;
          cursor: default;
          border: 2px solid transparent;
          background: fade(contrast(@main-color), 1%);
          margin-bottom: 5px;
          padding: 3px;
          display: flex;

          &:nth-child(even) {
            background: fade(contrast(@main-color), 2%);
          }

          &:hover {
            background: fade(contrast(@main-color), 4%);
            border-color: fade(contrast(@main-color), 10%);
          }

          .command-status {
            flex: none !important;
          }

          .name {
            display: inline-block;
            margin-left: 10px;
            font-family: Consolas, monospace;
            width: 370px;
          }

          .params {
            display: inline-block;
            font-size: 10pt;
            margin-left: 10px;
            font-family: Consolas, monospace;
          }
        }
      }

      .sensors {
        @media @phone {
          width: 100%;
        }

        .sensor-item {
          display: flex;
          border-radius: 7px;
          cursor: default;
          border: 2px solid transparent;
          text-align: center;
          flex-wrap: wrap;
          background: fade(contrast(@main-color), 1%);
          margin-bottom: 5px;

          &:nth-child(even) {
            background: fade(contrast(@main-color), 2%);
          }

          &:hover {
            background: fade(contrast(@main-color), 4%);
            border-color: fade(contrast(@main-color), 10%);
          }

          > div {
            flex: 1;
            font-family: Consolas, monospace;
            font-size: 16pt;
            padding: 5px;
          }

          .sensor-id {
            flex: 0 0 170px;
          }

          .sensor-name {
            background: fade(contrast(@main-color), 0.8%);
          }

          .meta-info-container {
            display: flex;
            padding: 0;
            flex: 0 0 320px;

            @media @phone {
              flex: 0 0 100%;
            }

            .meta-info {
              padding: 5px;
              opacity: 0.5;
              font-size: 12pt;
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1;
            }
          }
        }
      }
    }

    .command-status {
      display: inline;
      flex: 0 0 40px !important;

      .loading {
        animation-duration: 1s;
      }

      .warning {
        color: @warning-color;
      }

      .error {
        color: @danger-color;
      }

      .success {
        color: @success-color;
      }
    }
  }
}

iframe {
  display: none;
}

@keyframes pulse-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
</style>
