<template>
  <page title="Edit User" backURI=":back" :loading="loading">
    <template #body>
      <h1 class="title">Account of <strong>{{ accountUser.user.fullName }}</strong> in <strong>{{ accountUser.account.name }}</strong></h1>

      <tabs>
        <tab title="Account">
          <div class="form">
            <div class="input-group size-sm">
              <text-input type="number" label="ID" :value="String(accountUser.id).padStart(6, 0)" disabled/>
            </div>

            <div class="input-group size-md">
              <dropdown :disabled="!$checkPermission('platform.users.account-management')" :list="languageList" label="Language" :options="{searchMode: 'none'}" v-model="accountUser.language"/>
            </div>
            <div class="input-group size-md">
              <dropdown :disabled="!$checkPermission('platform.users.account-management')" :list="timezoneList" label="Time Zone" v-model="accountUser.timeZone"/>
            </div>
            <div class="input-group size-sm">
              <dropdown :disabled="!$checkPermission('platform.users.account-management')" :list="unitSystemList" label="Unit System" :options="{searchMode: 'none'}" v-model="accountUser.unitSystem"/>
            </div>
            <div class="input-group size-sm">
              <dropdown :disabled="!$checkPermission('platform.users.account-management')" :list="temperatureUnitList" label="Temperature Unit" :options="{searchMode: 'none'}" v-model="accountUser.temperatureUnit"/>
            </div>
            <div class="input-group size-sm">
              <dropdown :disabled="!$checkPermission('platform.users.account-management')" :list="pressureUnitList" label="Pressure Unit" :options="{searchMode: 'none'}" v-model="accountUser.pressureUnit"/>
            </div>
            <div class="input-group size-sm">
              <dropdown :disabled="!$checkPermission('platform.users.account-management')" :list="themeList" label="Theme" :options="{searchMode: 'none'}" v-model="accountUser.theme"/>
            </div>

            <div class="input-group size-sm">
              <dropdown :disabled="!$checkPermission('platform.users.account-management') && accountUser.user.type !== 'super'" :list="statusList" label="Status" :options="{searchMode: 'none'}" v-model="accountUser.status"/>
            </div>
            <div class="input-group bottom">
              <form-button v-if="$checkPermission('platform.users.account-management')" class="default" icon="fal fa-check" @click="save">Save</form-button>
            </div>
          </div>
        </tab>

        <tab title="Devices">
          <div class="form">
            <div class="input-group size-sm">
              <dropdown
                :list="devicesOriginList"
                label="Devices Origin"
                :options="{ searchMode: 'none'}"
                @select="saveDevicesOrigin"
                v-model="accountUser.devicesOrigin"
                :disabled="!$checkPermission('platform.users.account-management')"
              />
            </div>

            <div class="input-group">
              <picklist
                v-if="accountUser.devicesOrigin.value === 'list' || accountUser.devicesOrigin.value === 'mixed'"
                :list="devicesList"
                @add-items="addDevices"
                @remove-items="removeDevices"
                height="500"
                v-model="selectedDevices"
                :disabled="!$checkPermission('platform.users.account-management')"
              >
                <template #body="item">
                  <device-picklist-item :device="item"/>
                </template>
              </picklist>
            </div>
          </div>
        </tab>

        <tab title="Locations">
          <div class="form">
            <div class="input-group size-sm">
              <dropdown
                :list="locationsOriginList"
                label="Locations Origin"
                :options="{ searchMode: 'none'}"
                v-model="accountUser.locationsOrigin"
                :disabled="!$checkPermission('platform.users.account-management')"
              />
            </div>

            <div class="input-group">
              <picklist
                v-if="accountUser.locationsOrigin.value === 'list'"
                :list="locationsList"
                @add-items="addLocations"
                @remove-items="removeLocations"
                height="500"
                v-model="selectedLocations"
                :disabled="!$checkPermission('platform.users.account-management')"
              ></picklist>
            </div>
          </div>
        </tab>

        <tab title="Roles" :disabled="accountUser.user.type === 'super'">
          <div class="form">
            <div class="input-group">
              <picklist
                :list="rolesList"
                @add-items="addRoles"
                @remove-items="removeRoles"
                height="250"
                v-model="selectedRoles"
                :disabled="!$checkPermission('platform.users.account-management')"
              ></picklist>
            </div>

            <div class="input-group">
              <list-box
                :options="permissionsList"
                optionLabel="name"
                dataKey="name"
                v-model="selectedPermissions"
                label="Custom Permissions"
                listStyle="height: 500px;"
                @select="addPermissions"
                @deselect="removePermissions"
                :disabled="!$checkPermission('platform.users.account-management')"
              >
                <template #option="slot">
                  <div class="permissions-list-item">
                    <div class="name">{{slot.option.name}}</div>
                    <div class="description">{{slot.option.description}}</div>
                  </div>
                </template>
              </list-box>
            </div>
          </div>
        </tab>
      </tabs>
    </template>
  </page>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue'
import FormButton from '@/components/FormButton.vue'
import ListBox from '@/components/ListBox.vue'
import Page from '@/components/Page.vue'
import Picklist from '@/components/Picklist.vue'
import Tab from '@/components/Tabs/Tab.vue'
import Tabs from '@/components/Tabs/index.vue'
import TextInput from '@/components/TextInput.vue'
import DevicePicklistItem from './components/DevicePicklistItem.vue'

import { tireviewliveApi } from '@/lib/tireviewlive-api'

export default {
  name: 'AccountUserView',

  components: {
    Dropdown,
    Page,
    FormButton,
    Tab,
    Tabs,
    Picklist,
    ListBox,
    DevicePicklistItem,
    TextInput
  },

  methods: {
    async save () {
      const data = {
        ...this.accountUser,
        language: this.accountUser.language.value,
        timeZone: this.accountUser.timeZone.value,
        unitSystem: this.accountUser.unitSystem.value,
        temperatureUnit: this.accountUser.temperatureUnit.value,
        pressureUnit: this.accountUser.pressureUnit.value,
        theme: this.accountUser.theme.value,
        status: this.accountUser.status.value,
        devicesOrigin: undefined,
        locationsOrigin: undefined
      }

      await tireviewliveApi.put(`/sysadmin/account-user/${this.accountUserId}`, data).then(() => {
        this.$messageBox('Account User successfully saved', 'success')
      }).catch(error => {
        this.$messageBox(error.message, 'danger')
      })
    },

    async addDevices (items) {
      await tireviewliveApi.post(`/sysadmin/account-user/${this.accountUserId}/attach-accounts-devices`, {
        accountsDevices: items.map(i => i.value)
      })
    },

    async removeDevices (items) {
      await tireviewliveApi.delete(`/sysadmin/account-user/${this.accountUserId}/dettach-accounts-devices`, {
        accountsDevices: items.map(i => i.value)
      })
    },

    async addLocations (items) {
      await tireviewliveApi.post(`/sysadmin/account-user/${this.accountUserId}/attach-locations`, {
        locations: items.map(i => i.value)
      })
    },

    async removeLocations (items) {
      await tireviewliveApi.delete(`/sysadmin/account-user/${this.accountUserId}/dettach-locations`, {
        locations: items.map(i => i.value)
      })
    },

    async addRoles (items) {
      await tireviewliveApi.post(`/sysadmin/account-user/${this.accountUserId}/attach-roles`, {
        roles: items.map(i => i.value)
      })
    },

    async removeRoles (items) {
      await tireviewliveApi.delete(`/sysadmin/account-user/${this.accountUserId}/dettach-roles`, {
        roles: items.map(i => i.value)
      })
    },

    async addPermissions (item) {
      await tireviewliveApi.post(`/sysadmin/account-user/${this.accountUserId}/attach-permissions`, {
        permissions: [item.name]
      })
    },

    async removePermissions (item) {
      await tireviewliveApi.delete(`/sysadmin/account-user/${this.accountUserId}/dettach-permissions`, {
        permissions: [item.name]
      })
    },

    async saveDevicesOrigin (item) {
      await tireviewliveApi.put(`/sysadmin/account-user/${this.accountUserId}`, {
        devicesOrigin: item.value
      })
    }
  },

  computed: {
    accountUserId () {
      return this.$route.params.accountUserId
    }
  },

  watch: {
    '$store.state.workingAccount': function () {
      this.$router.replace('/platform2/users')
    }
  },

  data () {
    return {
      accountUser: {},
      loading: true,
      languageList: this.$mapListItems([{ value: 'en-US', label: 'English (United States)' }]),
      timezoneList: this.$mapListItems([
        { label: 'Automatic', value: 'auto' },
        'UTC',
        'Pacific/Midway',
        'Pacific/Niue',
        'Pacific/Pago_Pago',
        'America/Adak',
        'Pacific/Honolulu',
        'Pacific/Johnston',
        'Pacific/Rarotonga',
        'Pacific/Tahiti',
        'Pacific/Marquesas',
        'America/Anchorage',
        'Pacific/Gambier',
        'America/Juneau',
        'America/Nome',
        'America/Sitka',
        'America/Yakutat',
        'America/Dawson',
        'America/Los_Angeles',
        'America/Metlakatla',
        'Pacific/Pitcairn',
        'America/Santa_Isabel',
        'America/Tijuana',
        'America/Vancouver',
        'America/Whitehorse',
        'America/Boise',
        'America/Cambridge_Bay',
        'America/Chihuahua',
        'America/Creston',
        'America/Dawson_Creek',
        'America/Denver',
        'America/Edmonton',
        'America/Hermosillo',
        'America/Inuvik',
        'America/Mazatlan',
        'America/Ojinaga',
        'America/Phoenix',
        'America/Shiprock',
        'America/Yellowknife',
        'America/Bahia_Banderas',
        'America/Belize',
        'America/North_Dakota/Beulah',
        'America/Cancun',
        'America/North_Dakota/Center',
        'America/Chicago',
        'America/Costa_Rica',
        'Pacific/Easter',
        'America/El_Salvador',
        'Pacific/Galapagos',
        'America/Guatemala',
        'America/Indiana/Knox',
        'America/Managua',
        'America/Matamoros',
        'America/Menominee',
        'America/Merida',
        'America/Mexico_City',
        'America/Monterrey',
        'America/North_Dakota/New_Salem',
        'America/Rainy_River',
        'America/Rankin_Inlet',
        'America/Regina',
        'America/Resolute',
        'America/Swift_Current',
        'America/Tegucigalpa',
        'America/Indiana/Tell_City',
        'America/Winnipeg',
        'America/Atikokan',
        'America/Bogota',
        'America/Cayman',
        'America/Detroit',
        'America/Grand_Turk',
        'America/Guayaquil',
        'America/Havana',
        'America/Indiana/Indianapolis',
        'America/Iqaluit',
        'America/Jamaica',
        'America/Lima',
        'America/Kentucky/Louisville',
        'America/Indiana/Marengo',
        'America/Kentucky/Monticello',
        'America/Montreal',
        'America/Nassau',
        'America/New_York',
        'America/Nipigon',
        'America/Panama',
        'America/Pangnirtung',
        'America/Indiana/Petersburg',
        'America/Port-au-Prince',
        'America/Thunder_Bay',
        'America/Toronto',
        'America/Indiana/Vevay',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Caracas',
        'America/Anguilla',
        'America/Antigua',
        'America/Aruba',
        'America/Asuncion',
        'America/Barbados',
        'Atlantic/Bermuda',
        'America/Blanc-Sablon',
        'America/Boa_Vista',
        'America/Campo_Grande',
        'America/Cuiaba',
        'America/Curacao',
        'America/Dominica',
        'America/Eirunepe',
        'America/Glace_Bay',
        'America/Goose_Bay',
        'America/Grenada',
        'America/Guadeloupe',
        'America/Guyana',
        'America/Halifax',
        'America/Kralendijk',
        'America/La_Paz',
        'America/Lower_Princes',
        'America/Manaus',
        'America/Marigot',
        'America/Martinique',
        'America/Moncton',
        'America/Montserrat',
        'Antarctica/Palmer',
        'America/Port_of_Spain',
        'America/Porto_Velho',
        'America/Puerto_Rico',
        'America/Rio_Branco',
        'America/Santiago',
        'America/Santo_Domingo',
        'America/St_Barthelemy',
        'America/St_Kitts',
        'America/St_Lucia',
        'America/St_Thomas',
        'America/St_Vincent',
        'America/Thule',
        'America/Tortola',
        'America/St_Johns',
        'America/Araguaina',
        'America/Bahia',
        'America/Belem',
        'America/Argentina/Buenos_Aires',
        'America/Argentina/Catamarca',
        'America/Cayenne',
        'America/Argentina/Cordoba',
        'America/Fortaleza',
        'America/Godthab',
        'America/Argentina/Jujuy',
        'America/Argentina/La_Rioja',
        'America/Maceio',
        'America/Argentina/Mendoza',
        'America/Miquelon',
        'America/Montevideo',
        'America/Paramaribo',
        'America/Recife',
        'America/Argentina/Rio_Gallegos',
        'Antarctica/Rothera',
        'America/Argentina/Salta',
        'America/Argentina/San_Juan',
        'America/Argentina/San_Luis',
        'America/Santarem',
        'America/Sao_Paulo',
        'Atlantic/Stanley',
        'America/Argentina/Tucuman',
        'America/Argentina/Ushuaia',
        'America/Noronha',
        'Atlantic/South_Georgia',
        'Atlantic/Azores',
        'Atlantic/Cape_Verde',
        'America/Scoresbysund',
        'Africa/Abidjan',
        'Africa/Accra',
        'Africa/Bamako',
        'Africa/Banjul',
        'Africa/Bissau',
        'Atlantic/Canary',
        'Africa/Casablanca',
        'Africa/Conakry',
        'Africa/Dakar',
        'America/Danmarkshavn',
        'Europe/Dublin',
        'Africa/El_Aaiun',
        'Atlantic/Faroe',
        'Africa/Freetown',
        'Europe/Guernsey',
        'Europe/Isle_of_Man',
        'Europe/Jersey',
        'Europe/Lisbon',
        'Africa/Lome',
        'Europe/London',
        'Atlantic/Madeira',
        'Africa/Monrovia',
        'Africa/Nouakchott',
        'Africa/Ouagadougou',
        'Atlantic/Reykjavik',
        'Africa/Sao_Tome',
        'Atlantic/St_Helena',
        'Africa/Algiers',
        'Europe/Amsterdam',
        'Europe/Andorra',
        'Africa/Bangui',
        'Europe/Belgrade',
        'Europe/Berlin',
        'Europe/Bratislava',
        'Africa/Brazzaville',
        'Europe/Brussels',
        'Europe/Budapest',
        'Europe/Busingen',
        'Africa/Ceuta',
        'Europe/Copenhagen',
        'Africa/Douala',
        'Europe/Gibraltar',
        'Africa/Kinshasa',
        'Africa/Lagos',
        'Africa/Libreville',
        'Europe/Ljubljana',
        'Arctic/Longyearbyen',
        'Africa/Luanda',
        'Europe/Luxembourg',
        'Europe/Madrid',
        'Africa/Malabo',
        'Europe/Malta',
        'Europe/Monaco',
        'Africa/Ndjamena',
        'Africa/Niamey',
        'Europe/Oslo',
        'Europe/Paris',
        'Europe/Podgorica',
        'Africa/Porto-Novo',
        'Europe/Prague',
        'Europe/Rome',
        'Europe/San_Marino',
        'Europe/Sarajevo',
        'Europe/Skopje',
        'Europe/Stockholm',
        'Europe/Tirane',
        'Africa/Tripoli',
        'Africa/Tunis',
        'Europe/Vaduz',
        'Europe/Vatican',
        'Europe/Vienna',
        'Europe/Warsaw',
        'Africa/Windhoek',
        'Europe/Zagreb',
        'Europe/Zurich',
        'Europe/Athens',
        'Asia/Beirut',
        'Africa/Blantyre',
        'Europe/Bucharest',
        'Africa/Bujumbura',
        'Africa/Cairo',
        'Europe/Chisinau',
        'Asia/Damascus',
        'Africa/Gaborone',
        'Asia/Gaza',
        'Africa/Harare',
        'Asia/Hebron',
        'Europe/Helsinki',
        'Europe/Istanbul',
        'Asia/Jerusalem',
        'Africa/Johannesburg',
        'Europe/Kiev',
        'Africa/Kigali',
        'Africa/Lubumbashi',
        'Africa/Lusaka',
        'Africa/Maputo',
        'Europe/Mariehamn',
        'Africa/Maseru',
        'Africa/Mbabane',
        'Asia/Nicosia',
        'Europe/Riga',
        'Europe/Simferopol',
        'Europe/Sofia',
        'Europe/Tallinn',
        'Europe/Uzhgorod',
        'Europe/Vilnius',
        'Europe/Zaporozhye',
        'Africa/Addis_Ababa',
        'Asia/Aden',
        'Asia/Amman',
        'Indian/Antananarivo',
        'Africa/Asmara',
        'Asia/Baghdad',
        'Asia/Bahrain',
        'Indian/Comoro',
        'Africa/Dar_es_Salaam',
        'Africa/Djibouti',
        'Africa/Juba',
        'Europe/Kaliningrad',
        'Africa/Kampala',
        'Africa/Khartoum',
        'Asia/Kuwait',
        'Indian/Mayotte',
        'Europe/Minsk',
        'Africa/Mogadishu',
        'Europe/Moscow',
        'Africa/Nairobi',
        'Asia/Qatar',
        'Asia/Riyadh',
        'Antarctica/Syowa',
        'Asia/Tehran',
        'Asia/Baku',
        'Asia/Dubai',
        'Indian/Mahe',
        'Indian/Mauritius',
        'Asia/Muscat',
        'Indian/Reunion',
        'Europe/Samara',
        'Asia/Tbilisi',
        'Europe/Volgograd',
        'Asia/Yerevan',
        'Asia/Kabul',
        'Asia/Aqtau',
        'Asia/Aqtobe',
        'Asia/Ashgabat',
        'Asia/Dushanbe',
        'Asia/Karachi',
        'Indian/Kerguelen',
        'Indian/Maldives',
        'Antarctica/Mawson',
        'Asia/Oral',
        'Asia/Samarkand',
        'Asia/Tashkent',
        'Asia/Colombo',
        'Asia/Kolkata',
        'Asia/Kathmandu',
        'Asia/Almaty',
        'Asia/Bishkek',
        'Indian/Chagos',
        'Asia/Dhaka',
        'Asia/Qyzylorda',
        'Asia/Thimphu',
        'Antarctica/Vostok',
        'Asia/Yekaterinburg',
        'Indian/Cocos',
        'Asia/Rangoon',
        'Asia/Bangkok',
        'Indian/Christmas',
        'Antarctica/Davis',
        'Asia/Ho_Chi_Minh',
        'Asia/Hovd',
        'Asia/Jakarta',
        'Asia/Novokuznetsk',
        'Asia/Novosibirsk',
        'Asia/Omsk',
        'Asia/Phnom_Penh',
        'Asia/Pontianak',
        'Asia/Vientiane',
        'Asia/Brunei',
        'Antarctica/Casey',
        'Asia/Choibalsan',
        'Asia/Chongqing',
        'Asia/Harbin',
        'Asia/Hong_Kong',
        'Asia/Kashgar',
        'Asia/Krasnoyarsk',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Asia/Macau',
        'Asia/Makassar',
        'Asia/Manila',
        'Australia/Perth',
        'Asia/Shanghai',
        'Asia/Singapore',
        'Asia/Taipei',
        'Asia/Ulaanbaatar',
        'Asia/Urumqi',
        'Australia/Eucla',
        'Asia/Dili',
        'Asia/Irkutsk',
        'Asia/Jayapura',
        'Pacific/Palau',
        'Asia/Pyongyang',
        'Asia/Seoul',
        'Asia/Tokyo',
        'Australia/Adelaide',
        'Australia/Broken_Hill',
        'Australia/Darwin',
        'Australia/Brisbane',
        'Pacific/Chuuk',
        'Australia/Currie',
        'Antarctica/DumontDUrville',
        'Pacific/Guam',
        'Australia/Hobart',
        'Asia/Khandyga',
        'Australia/Lindeman',
        'Australia/Melbourne',
        'Pacific/Port_Moresby',
        'Pacific/Saipan',
        'Australia/Sydney',
        'Asia/Yakutsk',
        'Australia/Lord_Howe',
        'Pacific/Efate',
        'Pacific/Guadalcanal',
        'Pacific/Kosrae',
        'Antarctica/Macquarie',
        'Pacific/Noumea',
        'Pacific/Pohnpei',
        'Asia/Sakhalin',
        'Asia/Ust-Nera',
        'Asia/Vladivostok',
        'Pacific/Norfolk',
        'Asia/Anadyr',
        'Pacific/Auckland',
        'Pacific/Fiji',
        'Pacific/Funafuti',
        'Asia/Kamchatka',
        'Pacific/Kwajalein',
        'Asia/Magadan',
        'Pacific/Majuro',
        'Antarctica/McMurdo',
        'Pacific/Nauru',
        'Antarctica/South_Pole',
        'Pacific/Tarawa',
        'Pacific/Wake',
        'Pacific/Wallis',
        'Pacific/Chatham',
        'Pacific/Apia',
        'Pacific/Enderbury',
        'Pacific/Fakaofo',
        'Pacific/Tongatapu',
        'Pacific/Kiritimati'
      ], { capitalize: false }),
      unitSystemList: this.$mapListItems([
        'metric',
        'imperial'
      ]),
      temperatureUnitList: this.$mapListItems([
        { value: 'c', label: 'Celsius' },
        { value: 'f', label: 'Fahrenheit' },
        { value: 'k', label: 'Kelvin' }
      ]),
      pressureUnitList: this.$mapListItems([
        { value: 'kpa', label: 'kPa' },
        { value: 'psi', label: 'PSI' },
        'bar'
      ], { capitalize: false }),
      themeList: this.$mapListItems([{ value: 'auto', label: 'Automatic' }, 'light', 'dark']),
      statusList: this.$mapListItems(['active', 'blocked']),
      devicesOriginList: this.$mapListItems(['all', 'locations', 'list', 'mixed']),
      locationsOriginList: this.$mapListItems(['all', 'list']),
      devicesList: [],
      selectedDevices: [],
      locationsList: [],
      selectedLocations: [],
      rolesList: [],
      selectedRoles: [],
      permissionsList: [],
      selectedPermissions: []
    }
  },

  async mounted () {
    this.accountUser = await tireviewliveApi.get(`/sysadmin/account-user/${this.accountUserId}`, {
      include: 'user,account,accountsDevices,locations,roles,customPermissions'
    })

    this.devicesList = await tireviewliveApi.get('/sysadmin/devices', {
      accountId: this.accountUser.accountId,
      include: 'accountDevice'
    }).then(response => {
      this.selectedDevices = this.$mapListItems(this.accountUser.accountsDevices, item => {
        const device = response.find(i => i.id === item.deviceId)

        return {
          ...device,
          label: device.name,
          value: item.id
        }
      })

      return this.$mapListItems(response, ['name', 'accountDevice.id'])
    })

    this.locationsList = await tireviewliveApi.get('/sysadmin/locations', {
      accountId: this.accountUser.accountId
    }).then(response => {
      return this.$mapListItems(response, ['name', 'id'])
    })

    this.rolesList = await tireviewliveApi.get('/sysadmin/roles', {
      accountId: this.accountUser.accountId
    }).then(response => {
      return this.$mapListItems(response, ['name', 'id'])
    })

    this.permissionsList = await tireviewliveApi.get('/sysadmin/permissions')

    this.selectedLocations = this.$mapListItems(this.accountUser.locations, ['name', 'id'])
    this.selectedRoles = this.$mapListItems(this.accountUser.roles, ['name', 'id'])
    this.selectedPermissions = this.permissionsList.filter(i => this.accountUser.customPermissions.map(i => i.permission).includes(i.name))

    this.accountUser.language = this.languageList.fromValue(this.accountUser.language)
    this.accountUser.timeZone = this.timezoneList.fromValue(this.accountUser.timeZone)
    this.accountUser.unitSystem = this.unitSystemList.fromValue(this.accountUser.unitSystem)
    this.accountUser.temperatureUnit = this.temperatureUnitList.fromValue(this.accountUser.temperatureUnit)
    this.accountUser.pressureUnit = this.pressureUnitList.fromValue(this.accountUser.pressureUnit)
    this.accountUser.theme = this.themeList.fromValue(this.accountUser.theme)
    this.accountUser.status = this.statusList.fromValue(this.accountUser.status)
    this.accountUser.devicesOrigin = this.devicesOriginList.fromValue(this.accountUser.devicesOrigin)
    this.accountUser.locationsOrigin = this.locationsOriginList.fromValue(this.accountUser.locationsOrigin)

    this.loading = false
  }
}
</script>

<style lang="less" scoped>
.title {
  opacity: 0.6;
  margin-bottom: 20px;
  font-family: Rajdhani, sans-serif;
  font-weight: 500;
}

.permissions-list-item {
  padding-left: 10px;

  .name {
    font-family: 'Consolas', 'Courier New', Courier, monospace;
  }

  .description {
    font-size: 10pt;
    opacity: 0.5;
  }
}
</style>
