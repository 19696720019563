<template>
  <button class="form-button" @click="$emit('click', $event)" :class="{ success, danger, warning }">
    <div class="btn-content" :class="{ 'icon-right': iconRight, 'icon-float': iconFloat }">
      <i v-if="icon !== null && !iconRight" class="icon" :class="icon"></i>

      <span>
        <slot/>
      </span>

      <i v-if="icon !== null && iconRight" class="icon" :class="icon"></i>
    </div>
  </button>
</template>

<script>
export default {
  name: 'FormButton',

  props: {
    icon: {
      type: String,
      default: null
    },
    iconRight: Boolean,
    iconFloat: Boolean,
    success: Boolean,
    danger: Boolean,
    warning: Boolean
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../assets/shared.less';

.form-button {
  .base-btn;
  width: 100%;
  padding: 15px;
  font-family: Rajdhani, sans-serif;
  font-size: 15pt;
  transition: background-color 200ms;

  .btn-content {
    display: flex;
    text-align: left;
    align-items: center;

    > .icon {
      margin-right: 10px;
      text-align: right;
      flex: 1;
    }

    &.icon-right {
      text-align: right;

      > .icon {
        text-align: left;
        margin-right: 0;
        margin-left: 10px;
      }
    }

    &.icon-float {
      text-align: center;

      > .icon {
        flex: 0 0 auto;
      }
    }

    > span {
      flex: 1;
    }
  }
}
</style>
