<template>
  <page :title="pageTitle" backURI=":back" :loading="loading">
    <template #controls>
      <form-button v-if="isEditing && $checkPermission('platform.users.delete')" icon="fal fa-trash" @click="confirmDeletion">Delete User</form-button>
    </template>

    <template #body>
      <h1 class="title"><span v-if="!isEditing">New</span> User <strong>{{ user.firstName }} {{ user.lastName }}</strong></h1>

      <tabs>
        <tab title="User">
          <div class="form">
            <div class="input-group size-sm">
              <text-input v-if="isEditing" type="number" label="ID" :value="String(user.id).padStart(6, 0)" disabled/>
            </div>

            <div class="input-group size-md">
              <text-input :disabled="!$checkPermission('platform.accounts.update')" label="Username" v-model="user.username"/>
            </div>
            <div v-if="!isEditing" class="input-group size-md">
              <text-input :disabled="!$checkPermission('platform.accounts.update')" type="password" label="Password" v-model="user.password"/>
            </div>
            <div class="input-group size-md">
              <text-input :disabled="!$checkPermission('platform.accounts.update')" label="First Name" autocapitalize="words" v-model="user.firstName"/>
            </div>
            <div class="input-group size-md">
              <text-input :disabled="!$checkPermission('platform.accounts.update')" label="Last Name" autocapitalize="words" v-model="user.lastName"/>
            </div>
            <div class="input-group size-md">
              <text-input :disabled="!$checkPermission('platform.accounts.update')" type="email" label="Email" v-model="user.email"/>
            </div>
            <div class="input-group size-sm">
              <dropdown :disabled="!$checkPermission('platform.users.update')" label="Type" :list="typeList" :options="{searchMode: 'none'}" v-model="user.type"/>
            </div>
            <div class="input-group size-sm">
              <dropdown :disabled="!$checkPermission('platform.users.update')" v-if="user.type.value !== 'super'" label="Status" :list="statusList" :options="{searchMode: 'none'}" v-model="user.status"/>
            </div>
            <div class="input-group size-sm">
              <text-input :disabled="!$checkPermission('platform.users.update')" label="Legacy User ID" type="number" v-model="user.legacyUserId" />
            </div>
            <div class="input-group bottom">
              <form-button v-if="$checkPermission('platform.users.update')" class="default" icon="fal fa-check" @click="save">Save</form-button>
            </div>
          </div>
        </tab>

        <tab title="Accounts" :disabled="!isEditing">
          <div class="controls">
            <form-button v-if="$checkPermission('platform.users.account-management')" class="link" @click="openAccountsManagementModal">Manage Accounts</form-button>
          </div>

          <data-table :value="user.accounts" @refresh="getUser">
            <column field="accountUser.id" header="Account User ID" bodyStyle="text-align: center" headerStyle="width: 220px">
              <template #body="slot">
                <router-link class="editor-link" :to="'/platform2/users/account-user/' + slot.data.accountUser.id">{{ String(slot.data.accountUser.id).padStart(6, '0') }}</router-link>
              </template>
            </column>
            <column field="name" header="Account" headerStyle="width: 250px" bodyStyle="text-align: center;" sortable/>
            <column field="accountUser.status" header="Status" bodyStyle="text-align: center" headerStyle="width: 150px" sortable>
              <template #body="slot">
                <span class="resource-status-badge" :class="slot.data.accountUser.status">{{ slot.data.accountUser.status }}</span>
              </template>
            </column>
          </data-table>
        </tab>
      </tabs>

      <modal
        title="Accounts Management"
        ref="accountsManagementModal"
        @closed="getUser"
      >
        <div class="modal-container">
          <picklist
            :list="accountsList"
            @add-items="addAccounts"
            @remove-items="removeAccounts"
            :height="300"
            v-model="selectedAccounts"
          >
          </picklist>
        </div>
      </modal>
    </template>
  </page>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import Dropdown from '@/components/Dropdown.vue'
import FormButton from '@/components/FormButton.vue'
import Modal from '@/components/Modal.vue'
import Page from '@/components/Page.vue'
import Picklist from '@/components/Picklist.vue'
import Tabs from '@/components/Tabs'
import Tab from '@/components/Tabs/Tab'
import TextInput from '@/components/TextInput.vue'
import Column from 'primevue/column'

import { tireviewliveApi } from '@/lib/tireviewlive-api'

export default {
  name: 'UserEditorView',

  components: {
    Page,
    TextInput,
    Tabs,
    Tab,
    Dropdown,
    DataTable,
    Column,
    FormButton,
    Modal,
    Picklist
  },

  methods: {
    async getUser () {
      this.user = await tireviewliveApi.get(`/sysadmin/user/${this.userId}`, {
        include: 'accounts'
      })
    },

    async save () {
      const data = {
        ...this.user,
        type: this.user.type.value,
        status: this.user.status.value
      }

      if (this.isEditing) {
        await tireviewliveApi.put(`/sysadmin/user/${this.userId}`, data).then(() => {
          this.$messageBox('User successfully saved', 'success')
        }).catch(error => {
          this.$messageBox(error.message, 'danger')
        })
      } else {
        await tireviewliveApi.post('/sysadmin/user', data).then((newUser) => {
          this.$messageBox('User successfully saved', 'success')

          if (this.workingAccount.value > 0) {
            setTimeout(() => {
              this.confirmAddUser(newUser.id)
            }, 2000)
          }
        }).catch(error => {
          this.$messageBox(error.message, 'danger')
        })
      }
    },

    async addAccounts (items) {
      await tireviewliveApi.post(`/sysadmin/user/${this.userId}/attach-accounts`, {
        accounts: items.map(i => i.value)
      })
    },

    async removeAccounts (items) {
      await tireviewliveApi.delete(`/sysadmin/user/${this.userId}/dettach-accounts`, {
        accounts: items.map(i => i.value)
      })
    },

    openAccountsManagementModal () {
      this.$refs.accountsManagementModal.open()
    },

    async init () {
      if (this.isEditing) {
        await this.getUser()

        this.accountsList = await tireviewliveApi.get('/sysadmin/accounts').then(response => {
          return this.$mapListItems(response, ['name', 'id'])
        })

        this.user.type = this.typeList.fromValue(this.user.type)
        this.user.status = this.statusList.fromValue(this.user.status)

        this.selectedAccounts = this.$mapListItems(this.user.accounts, ['name', 'id'])
      } else {
        this.user.type = this.typeList.fromValue('regular')
        this.user.status = this.statusList.fromValue('active')
      }

      this.loading = false
    },

    async confirmAddUser (userId) {
      this.$confirm.require({
        message: `Do you want to add this user to the selected working account ${this.workingAccount.label}?`,
        header: 'Add User',
        icon: 'far fa-user-plus',

        accept: () => {
          this.addUserToAccount(userId)
        },

        reject: () => {
          this.$router.replace(`/platform2/users/update/${userId}`)
          this.init()
        }
      })
    },

    async addUserToAccount (userId) {
      await tireviewliveApi.post(`/sysadmin/account/${this.workingAccount.value}/attach-users`, {
        users: [userId]
      })

      this.$router.replace(`/platform2/users/update/${userId}`)
      this.init()
    },

    async confirmDeletion () {
      this.$confirm.require({
        message: 'Are you sure you want to delete this user?',
        header: 'Delete',
        icon: 'fas fa-exclamation-triangle',

        accept: async () => {
          await tireviewliveApi.delete(`/sysadmin/user/${this.userId}`)

          this.$router.replace('/platform2/users')
        }
      })
    }
  },

  watch: {
    '$store.state.workingAccount': function () {
      this.$router.replace('/platform2/users')
    }
  },

  computed: {
    isEditing () {
      return !!this.$route.params.userId
    },

    pageTitle () {
      return this.isEditing ? 'Edit User' : 'Create User'
    },

    userId () {
      return this.$route.params.userId
    },

    workingAccount () {
      return this.$store.state.workingAccount
    }
  },

  data () {
    return {
      loading: true,
      user: {},
      accountsList: [],
      selectedAccounts: [],
      statusList: this.$mapListItems(['active', 'blocked']),
      typeList: this.$mapListItems(['regular', 'agent', 'super', 'service'])
    }
  },

  async mounted () {
    this.init()
  }

}
</script>

<style lang="less">
.modal-container {
  width: 600px;
}
</style>
