<template>
  <page title="Devices" :loading="loading" :loading-progress="loadingProgress" wide>
    <template #body>
      <data-table :value="devices" @refresh="getDevices">
        <column field="id" header="Device ID" bodyStyle="text-align: center" headerStyle="width: 180px" sortable>
          <template #body="slot">
            <router-link class="editor-link" :to="'/platform2/devices/update/' + slot.data.id">{{String(slot.data.id).padStart(6, '0')}}</router-link>
          </template>
        </column>
        <column field="name" header="Name" bodyStyle="text-align: center" sortable/>
        <column field="imei" header="IMEI" headerStyle="width: 200px" bodyStyle="text-align: center" sortable/>
        <column field="fleet" header="Fleet" headerStyle="width: 200px" bodyStyle="text-align: center" sortable/>
        <column field="type" header="Type" headerStyle="width: 250px" bodyStyle="text-align: center" sortable/>
      </data-table>
    </template>
  </page>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import Page from '@/components/Page.vue'
import Column from 'primevue/column'

import { tireviewliveApi } from '@/lib/tireviewlive-api'

export default {
  name: 'DevicesView',

  components: {
    Page,
    DataTable,
    Column
  },

  methods: {
    async getDevices () {
      this.loading = true

      const accountId = this.$store.state.workingAccount.value

      this.devices = await tireviewliveApi.get('/sysadmin/devices', { accountId, itemsPerPage: 50 }, true, (percent) => {
        this.loadingProgress = percent
      })

      this.loading = false
    }
  },

  watch: {
    '$store.state.workingAccount': function () {
      this.getDevices()
    }
  },

  data () {
    return {
      devices: [],
      loading: true,
      loadingProgress: 0
    }
  },

  mounted () {
    this.getDevices()
  }

}
</script>
