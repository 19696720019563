<template>
  <div class="switch-button" @click="toggle" :class="{ active: val }">
    <div class="switch">
      <i v-if="computedIcon" :class="computedIcon"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchButton',

  props: {
    icon: String,
    onIcon: String,
    offIcon: String,
    value: Boolean
  },

  methods: {
    toggle () {
      this.val = !this.val

      this.$emit('input', this.val)
      this.$emit('change', this.val)
    }
  },

  watch: {
    value () {
      this.val = this.value
    }
  },

  computed: {
    computedIcon () {
      let ret = this.icon

      if (this.onIcon !== undefined && this.val) {
        ret = this.onIcon
      }

      if (this.offIcon !== undefined && !this.val) {
        ret = this.offIcon
      }

      return ret
    }
  },

  data () {
    return {
      val: this.value
    }
  }
}
</script>

<style lang="less" scoped>
.switch-button {
  --width: 50px;

  display: inline-block;
  border: 1px solid red;
  width: var(--width);
  border-radius: var(--width);
  vertical-align: middle;
  transition: all 400ms;
  background: lighten(@main-color, 5%);
  border: 1px solid lighten(@main-color, 10%);
  cursor: pointer;

  &.active {
    background: @accent-color;

    .switch {
      transform: translateX(calc(100% - 2px));
    }
  }

  .switch {
    border: 1px solid yellow;
    width: calc(var(--width) / 2);
    height: calc(var(--width) / 2);
    border-radius: var(--width);
    transition: all 200ms;
    background: lighten(@main-color, 20%);
    border: 1px solid lighten(@main-color, 10%);
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 10pt;
      text-align: center;
      opacity: 0.7;
    }
  }
}
</style>
