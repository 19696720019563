<template>
  <page title="Users" :loading="loading" wide>
    <template #controls>
      <form-button v-if="$checkPermission('platform.users.create')" icon="fal fa-plus" @click="$router.push('/platform2/users/create')">Create an User</form-button>
    </template>

    <template #body>
      <data-table :value="users" @refresh="getUsers">
        <column field="id" header="User ID" bodyStyle="text-align: center" headerStyle="width: 180px" sortable>
          <template #body="slot">
            <router-link class="editor-link" :to="'/platform2/users/update/' + slot.data.id">{{String(slot.data.id).padStart(6, '0')}}</router-link>
          </template>
        </column>
        <column field="username" header="Username" headerStyle="width: 200px" bodyStyle="text-align: center" sortable/>
        <column field="fullName" header="Name" bodyStyle="text-align: center" sortable/>
        <column field="email" header="Email" headerStyle="width: 250px" bodyStyle="text-align: center" sortable/>
        <column field="type" header="Type" bodyStyle="text-align: center" headerStyle="width: 150px" sortable>
          <template #body="slot">
            <span class="user-type" :class="slot.data.type">{{ slot.data.type }}</span>
          </template>
        </column>
        <column field="status" header="Status" bodyStyle="text-align: center" headerStyle="width: 150px" sortable>
          <template #body="slot">
            <span class="resource-status-badge" :class="slot.data.status">{{ slot.data.status }}</span>
          </template>
        </column>
      </data-table>
    </template>
  </page>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import FormButton from '@/components/FormButton.vue'
import Page from '@/components/Page.vue'
import Column from 'primevue/column'

import { tireviewliveApi } from '@/lib/tireviewlive-api'

export default {
  name: 'UsersView',

  components: {
    Page,
    FormButton,
    DataTable,
    Column
  },

  methods: {
    async getUsers () {
      this.loading = true

      this.users = await tireviewliveApi.get('/sysadmin/users', {
        accountId: this.accountId,
        includeSpecial: 'super'
      })

      this.loading = false
    }
  },

  computed: {
    accountId () {
      return this.$store.state.workingAccount.value
    }
  },

  watch: {
    '$store.state.workingAccount': function () {
      this.getUsers()
    }
  },

  data () {
    return {
      loading: true,
      users: []
    }
  },

  mounted () {
    this.getUsers()
  }

}
</script>

<style lang="less" scoped>
.user-type {
  background: fade(contrast(@main-color), 5%);
  color: fade(contrast(@main-color), 50%);
  padding: 5px 12px;
  border-radius: 3px;
  font-weight: bold;
  text-transform: capitalize;

  &.super {
    background: fade(#4942E4, 10%);
    color: #4942E4;
  }

  &.agent {
    background: fade(#C5E898, 10%);
    color: #C5E898;
  }

  &.service {
    background: fade(#FF0060, 10%);
    color: #FF0060;
  }
}
</style>
