<template>
  <page title="Billing Report" :loading="loading">
    <template #controls>
      <form-button icon="fal fa-cog" v-if="$checkPermission('billing.configs.set')" @click="$router.push('/billing/report/settings')">Billing Report Settings</form-button>
    </template>

    <template #body>
      <div class="form">
        <div class="input-group">
          <Checkbox label="All accounts" v-model="all"/>
        </div>

        <div v-if="all" class="input-group size-md">
          <div class="input-group">
            <dropdown
              label="System"
              v-model="system"
              :list="systemsList"
              :options="{ searchMode: 'none' }"/>
          </div>

          <div class="input-group">
            <dropdown
              label="Month of Reference"
              v-model="monthOfReference"
              :list="monthOfReferenceList"
              :options="{ searchMode: 'none' }"/>
          </div>

          <div class="input-group info">
            <p>This report is generated asynchronously. Please, provide a list of e-mails to receive the report when it's finished.</p>
          </div>

          <div class="input-group">
            <editable-list v-model="emails" placeholder="Type an e-mail"/>
          </div>

          <div class="input-group">
            <info-box type="success" :text="infobox"/>
          </div>

          <div class="input-group bottom">
            <FormButton @click="requestAllAccountsReport">Request Report</FormButton>
          </div>
        </div>

        <div v-else class="input-group size-md">
          <dropdown
            label="Account"
            v-model="account"
            :list="accountList"
            :loading="searchAccountLoading"
            @search="searchAccount"
            @listClosed="accountList = []"
            @select="getBills"
            :options="{ searchMode: 'customOnType' }"
          />
        </div>

        <div v-if="current !== null" class="input-group result">
          <div class="section">
            <div class="title">Bills</div>
            <data-table :value="current.rates.bills" :searchBar="false">
              <column field="dueDate" header="Due Date" bodyStyle="text-align: center" headerStyle="width: 220px" sortable>
                <template #body="slot">
                  <router-link :to="'/billing/report/detail/current/' + account.system.id + '/' + account.id + '/' + slot.data.dueDate">{{$formatUTCDate(slot.data.dueDate)}}</router-link>
                </template>
              </column>

              <column field="totals.count.active" header="Active Count" headerStyle="width: 110px" bodyStyle="text-align: center" sortable></column>
              <column field="totals.count.holding" header="Holding Count" headerStyle="width: 110px" bodyStyle="text-align: center" sortable></column>
              <column field="totals.count.count" header="Total Count" headerStyle="width: 110px" bodyStyle="text-align: center" sortable></column>

              <column field="totals.total.active" header="Total" headerStyle="width: 200px" bodyStyle="text-align: center" sortable>
                <template #body="slot">
                  {{slot.data.totals.total.active}} {{current.currency}}
                </template>
              </column>
            </data-table>
          </div>

          <div v-if="customerSatisfaction.length > 0" class="section">
            <div class="title">Customer Satisfaction</div>
            <data-table :value="customerSatisfaction" :searchBar="false">
              <column field="expiration" header="Expiration" bodyStyle="text-align: center" headerStyle="width: 100px" sortable>
                <template #body="slot">
                  {{ slot.data.expiration ? $formatUTCDate(slot.data.expiration) : 'Never' }}
                </template>
              </column>
              <column field="name" header="Vehicle ID" headerStyle="width: 110px" bodyStyle="text-align: center" sortable></column>
              <column field="type" header="Type" headerStyle="width: 110px" bodyStyle="text-align: center" sortable></column>
              <column field="fleet" header="Fleet" headerStyle="width: 110px" bodyStyle="text-align: center" sortable></column>
              <column field="activation.status" header="Status" headerStyle="width: 110px" bodyStyle="text-align: center" sortable>
                <template #body="slot">
                  <span class="badge" :class="slot.data.activation.status">
                    {{activationStatusMap[slot.data.activation.status]}}<span v-if="slot.data.activation.forced">*</span>
                  </span>
                </template>
              </column>
            </data-table>
          </div>

          <div v-if="researchAndDevelopment.length > 0" class="section">
            <div class="title">Research & Development</div>
            <data-table :value="researchAndDevelopment" :searchBar="false">
              <column field="expiration" header="Expiration" bodyStyle="text-align: center" headerStyle="width: 100px">
                <template #body="slot">
                  {{ slot.data.expiration ? $formatUTCDate(slot.data.expiration) : 'Never' }}
                </template>
              </column>
              <column field="name" header="Vehicle ID" headerStyle="width: 110px" bodyStyle="text-align: center" sortable></column>
              <column field="type" header="Type" headerStyle="width: 110px" bodyStyle="text-align: center" sortable></column>
              <column field="fleet" header="Fleet" headerStyle="width: 110px" bodyStyle="text-align: center" sortable></column>
              <column field="activation.status" header="Status" headerStyle="width: 110px" bodyStyle="text-align: center" sortable>
                <template #body="slot">
                  <span class="badge" :class="slot.data.activation.status">
                    {{activationStatusMap[slot.data.activation.status]}}<span v-if="slot.data.activation.forced">*</span>
                  </span>
                </template>
              </column>
            </data-table>
          </div>

          <div v-if="pause.length > 0" class="section">
            <div class="title">In Pause</div>
            <data-table :value="pause" :searchBar="false">
              <column field="expiration" header="Expiration" bodyStyle="text-align: center" headerStyle="width: 100px" sortable>
                <template #body="slot">
                  {{ slot.data.expiration ? $formatUTCDate(slot.data.expiration) : 'Never' }}
                </template>
              </column>
              <column field="name" header="Vehicle ID" headerStyle="width: 110px" bodyStyle="text-align: center" sortable></column>
              <column field="type" header="Type" headerStyle="width: 110px" bodyStyle="text-align: center" sortable></column>
              <column field="fleet" header="Fleet" headerStyle="width: 110px" bodyStyle="text-align: center" sortable></column>
              <column field="activation.status" header="Status" headerStyle="width: 110px" bodyStyle="text-align: center" sortable>
                <template #body="slot">
                  <span class="badge" :class="slot.data.activation.status">
                    {{activationStatusMap[slot.data.activation.status]}}<span v-if="slot.data.activation.forced">*</span>
                  </span>
                </template>
              </column>
            </data-table>
          </div>

          <div v-if="current.rates.notInDataPlan.length > 0" class="section">
            <div class="title">Not in a Data Plan</div>
            <data-table :value="current.rates.notInDataPlan" :searchBar="false">
              <column field="name" header="Vehicle ID" headerStyle="width: 110px" bodyStyle="text-align: center" sortable></column>
              <column field="type" header="Type" headerStyle="width: 110px" bodyStyle="text-align: center" sortable></column>
              <column field="fleet" header="Fleet" headerStyle="width: 110px" bodyStyle="text-align: center" sortable></column>
            </data-table>
          </div>

        </div>

        <h1 v-if="error" class="no-data">This account has no data plans set</h1>
      </div>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import Dropdown from '@/components/Dropdown.vue'
import DataTable from '@/components/DataTable.vue'
import Checkbox from '@/components/Checkbox.vue'
import FormButton from '@/components/FormButton.vue'
import InfoBox from '@/components/InfoBox.vue'
import EditableList from '@/components/EditableList'

import Column from 'primevue/column'

import gql from 'graphql-tag'

export default {
  name: 'BillingReportView',

  components: {
    Page,
    Dropdown,
    DataTable,
    Column,
    Checkbox,
    EditableList,
    FormButton,
    InfoBox
  },

  methods: {
    searchAccount (searchText) {
      if (searchText.length < 2) {
        return false
      }

      this.searchAccountLoading = true

      this.$apollo.query({
        query: gql`
          query ($searchText: String!) {
            platform {
              accounts: searchAccount (searchText: $searchText) {
                id
                name
                system {
                  id
                  name
                }
                value: id
                label: name
              }
            }
          }
        `,
        variables: {
          searchText
        }
      }).then(response => {
        this.searchAccountLoading = false
        this.accountList = response.data.platform.accounts
      })
    },

    getBills () {
      this.bills = []
      this.error = false
      this.loading = true

      this.$apollo.query({
        query: gql`
          query ($systemId: String!, $accountId: ID!) {
            billing {
              current: getCurrentBillingBill(systemId: $systemId, accountId: $accountId) {
                currency
                rates {
                  bills: normal {
                    dueDate
                    totals {
                      count {
                        active
                        holding
                        count
                      }
                      total {
                        active
                      }
                    }
                  }
                  customerSatisfaction {
                    dueDate
                    devices {
                      id
                      name
                      type
                      fleet
                      activation {
                        status
                        forced
                      }
                    }
                  }
                  researchAndDevelopment {
                    dueDate
                    devices {
                      id
                      name
                      type
                      fleet
                      activation {
                        status
                        forced
                      }
                    }
                  }
                  pause {
                    dueDate
                    devices {
                      id
                      name
                      type
                      fleet
                      activation {
                        status
                        forced
                      }
                    }
                  }
                  notInDataPlan {
                    id
                    name
                    type
                    fleet
                  }
                }
              }
            }
          }
        `,
        variables: {
          systemId: this.account.system.id,
          accountId: this.account.id
        }
      }).then(response => {
        this.current = response.data.billing.current
      }).catch(error => {
        if (error.graphQLErrors[0].extensions.code === 'BILLING_ACCOUNT_NOT_FOUND') {
          this.error = true
        }
      }).finally(() => {
        this.loading = false
      })
    },

    normalizeNonBillable (name) {
      const ret = []

      this.current.rates[name].forEach(item => {
        item.devices.forEach(device => {
          ret.push({
            expiration: item.dueDate,
            ...device
          })
        })
      })

      return ret
    },

    requestAllAccountsReport () {
      this.$apollo.query({
        query: gql`
          query ($systemId: String!, $emails: [String]!, $monthOfReference: String!) {
            billing {
              requestAllAccountsBillingReport(systemId: $systemId, emails: $emails, monthOfReference: $monthOfReference)
            }
          }
        `,
        variables: {
          systemId: this.system.value,
          monthOfReference: this.monthOfReference.value,
          emails: this.emails
        },
        fetchPolicy: 'no-cache'
      }).then(() => {
        this.infobox = "Your report has been successfully requested. Please, wait while it's being processed. You will receive an e-mail when it's finished"
      })
    }
  },

  computed: {
    customerSatisfaction () {
      return this.normalizeNonBillable('customerSatisfaction')
    },

    researchAndDevelopment () {
      return this.normalizeNonBillable('researchAndDevelopment')
    },

    pause () {
      return this.normalizeNonBillable('pause')
    },

    monthOfReferenceList () {
      const currentDate = new Date()

      currentDate.setMonth(currentDate.getMonth() + 1)

      const monthName = currentDate.toLocaleString('en-US', { month: 'long', year: 'numeric' })

      return [
        {
          label: `${monthName} (Current)`,
          value: currentDate.toISOString().substring(0, 7)
        }
      ]
    }
  },

  data () {
    return {
      all: false,
      system: {
        label: 'TireView',
        value: 'default'
      },
      monthOfReference: null,
      systemsList: [
        {
          label: 'TireView',
          value: 'default'
        },
        {
          label: 'Goodyear',
          value: 'goodyear'
        }
      ],
      emails: [this.$store.state.auth.user.email],
      infobox: null,
      account: null,
      accountList: [],
      searchAccountLoading: false,
      current: null,
      error: false,
      loading: false,
      activationStatusMap: {
        active: 'Active',
        holding: 'Installation Holding'
      }
    }
  },

  mounted () {
    this.$moveFocusToFirstElement()
  }
}
</script>

<style lang="less" scoped>
.info {
  padding: 10px;
  background: fade(@accent-color, 5%);
  color: @accent-color;
  border-radius: 10px;
}

.no-data {
  font-size: 18pt;
  font-weight: 100;
  color: contrast(@main-color);
  margin-top: 100px;
  opacity: 0.5;
  text-align: center;
}

.result {
  margin-top: 30px;
}

.badge {
  background: fade(@success-color, 10%);
  color: @success-color;
  padding: 5px 12px;
  border-radius: 3px;
  font-weight: bold;

  &.holding {
    background: fade(@warning-color, 10%);
    color: @warning-color;
  }
}

.section {
  margin-bottom: 25px;

  .title {
    opacity: 0.7;
  }
}
</style>
