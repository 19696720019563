<template>
  <page :title="pageTitle" backURI=":back" :loading="loading">
    <template #body>
      <h1 class="title">Device <strong>{{ device.name }}</strong></h1>

      <tabs>
        <tab title="Device">
          <div class="form">
            <div class="input-group size-sm">
              <text-input type="number" label="ID" :value="String(device.id).padStart(6, 0)" disabled/>
            </div>

            <div class="input-group size-sm">
              <text-input :disabled="!$checkPermission('platform.devices.update')" label="IMEI" v-model="device.imei"/>
            </div>
            <div class="input-group size-md">
              <text-input :disabled="!$checkPermission('platform.devices.update')" label="Name" v-model="device.name"/>
            </div>
            <div class="input-group size-md">
              <text-input :disabled="!$checkPermission('platform.devices.update')" label="Fleet" v-model="device.fleet"/>
            </div>
            <div class="input-group size-md">
              <text-input :disabled="!$checkPermission('platform.devices.update')" label="Type" v-model="device.type"/>
            </div>
            <div class="input-group size-sm">
              <text-input :disabled="!$checkPermission('platform.devices.update')" label="Year" type="number" v-model="device.year"/>
            </div>
            <div class="input-group size-sm">
              <text-input :disabled="!$checkPermission('platform.devices.update')" label="Make" v-model="device.make"/>
            </div>
            <div class="input-group size-sm">
              <text-input :disabled="!$checkPermission('platform.devices.update')" label="Model" v-model="device.model"/>
            </div>
            <div class="input-group size-sm">
              <text-input :disabled="!$checkPermission('platform.devices.update')" label="Color" v-model="device.color" />
            </div>
            <div class="input-group size-sm">
              <text-input :disabled="!$checkPermission('platform.devices.update')" label="License Plate" v-model="device.licensePlate" />
            </div>
            <div class="input-group size-sm">
              <text-input :disabled="!$checkPermission('platform.devices.update')" label="Vin" v-model="device.vin" />
            </div>
            <div class="input-group">
              <text-input :disabled="!$checkPermission('platform.devices.update')" label="Coments" type="textarea" v-model="device.comment" />
            </div>
            <div class="input-group bottom">
              <form-button v-if="$checkPermission('platform.devices.update')" class="default" icon="fal fa-check" @click="saveDevice">Save</form-button>
            </div>
          </div>
        </tab>

        <tab title="Configs">
          <div class="form">
            <div class="input-group size-sm">
              <text-input :disabled="!$checkPermission('platform.devices.update')" label="Installation Date" type="date" v-model="device.activationDate"/>
            </div>
            <div class="input-group size-sm">
              <dropdown :disabled="!$checkPermission('platform.devices.update')" :list="powerSourceList" label="Power Source" :options="{searchMode: 'none'}" v-model="device.powerSource"/>
            </div>
            <div class="input-group size-sm">
              <dropdown :disabled="!$checkPermission('platform.devices.update')" :list="atisFamilyList" label="ATIS Family" :options="{searchMode: 'none'}" v-model="device.atisFamily"/>
            </div>
            <div class="input-group bottom">
              <form-button v-if="$checkPermission('platform.devices.update')" class="default" icon="fal fa-check" @click="saveConfig">Save</form-button>
            </div>
          </div>
        </tab>

        <tab title="Type & Sensors">
          <div class="form">
            <div class="form-row" style="margin-bottom: 20px;">
              <div class="input-group">
                <dropdown label="Vehicle Type" :list="tireGraphicsList" v-model="device.settings.tireGraphicId" disabled/>
              </div>
              <div class="input-group size-auto">
                <form-button v-if="$checkPermission('platform.devices.update')" style="height: 46px; padding: 0 20px;" class="default" icon="fal fa-check" disabled>Save</form-button>
              </div>
            </div>
            <div v-for="sensor in device.sensors" :key="sensor.index" class="row" :style="{marginTop: sensor.index === tireGraphicBoundary.min ? '30px' : undefined, marginBottom: sensor.index === tireGraphicBoundary.max ? '30px' : undefined}">
              <div class="index">
                {{ sensor.index }}
              </div>
              <div class="sensor-id">
                <text-input :disabled="!$checkPermission('platform.devices.update')" text-align="center" class="input" v-model="sensor.id"/>
              </div>
              <div class="sensor-name">
                <text-input :disabled="!$checkPermission('platform.devices.update')"  v-model="sensor.name" :placeholder="sensor.placeholder"/>
              </div>
              <div class="sensor-type">
                <dropdown :disabled="!$checkPermission('platform.devices.update')" :list="sensorTypeList" :options="{searchMode: 'none'}" v-model="sensor.type"/>
              </div>
              <div class="btns" v-if="$checkPermission('platform.devices.update')">
                <button class="btn delete" @click="deleteSensor(sensor.index)">
                  <i class="fas fa-dash" />
                </button>
                <button class="btn save" @click="setSensor(sensor.index)">
                  <i class="fas fa-check" />
                </button>
              </div>
            </div>
          </div>
        </tab>

        <tab title="Tresholds">
          <div class="form thresholds">
            <div class="row report-interval">
              <div class="description">Report Interval</div>
              <div class="value">
                <text-input
                  text-align="center"
                  placeholder="(Disabled)"
                  v-model="device.settings.reportInterval"
                  :disabled="!$checkPermission('platform.devices.update')"
                />
              </div>
              <div class="unit">seconds</div>
              <div class="btns-treshold" v-if="$checkPermission('platform.devices.update')">
                <button class="btn delete" @click="deleteReportInterval">
                  <i class="fas fa-ban" />
                </button>
                <button class="btn save" @click="setReportInterval">
                  <i class="fas fa-check" />
                </button>
              </div>
            </div>
            <div class="row tire title">
              <div class="description"></div>
              <div class="value title">Tire</div>
              <div class="unit"></div>
              <div class="btns-treshold"></div>
            </div>
            <div class="row max-temperature">
              <div class="description">Max Temperature</div>
              <div class="value">
                <text-input :disabled="!$checkPermission('platform.devices.update')" text-align="center" placeholder="(Disabled)" v-model="device.settings.tireMaxTemperature.value"/>
              </div>
              <div class="unit">°F</div>
              <div class="btns-treshold" v-if="$checkPermission('platform.devices.update')">
                <button class="btn delete" @click="deleteTireMaxTemperature">
                  <i class="fas fa-ban" />
                </button>
                <button class="btn save" @click="setTireMaxTemperature">
                  <i class="fas fa-check" />
                </button>
              </div>
            </div>
            <div class="row tire min">
              <div class="description">Min Pressure</div>
              <div class="value">
                <text-input :disabled="!$checkPermission('platform.devices.update')" text-align="center" placeholder="(Disabled)" v-model="device.settings.tireMinPressure.value"/>
              </div>
              <div class="unit">PSI</div>
              <div class="btns-treshold" v-if="$checkPermission('platform.devices.update')">
                <button class="btn delete" @click="deleteTireMinPressure">
                  <i class="fas fa-ban" />
                </button>
                <button class="btn save" @click="setTireMinPressure">
                  <i class="fas fa-check" />
                </button>
              </div>
            </div>
            <div class="row tire max">
              <div class="description">Max Pressure</div>
              <div class="value">
                <text-input :disabled="!$checkPermission('platform.devices.update')" text-align="center" placeholder="(Disabled)" v-model="device.settings.tireMaxPressure.value"/>
              </div>
              <div class="unit">PSI</div>
              <div class="btns-treshold" v-if="$checkPermission('platform.devices.update')">
                <button class="btn delete" @click="deleteTireMaxPressure">
                  <i class="fas fa-ban" />
                </button>
                <button class="btn save" @click="setTireMaxPressure">
                  <i class="fas fa-check" />
                </button>
              </div>
            </div>
            <div class="row hub-temp title">
              <div class="description"></div>
              <div class="value title">Hub Temp</div>
              <div class="unit"></div>
              <div class="btns-treshold"></div>
            </div>
            <div class="row hub-temp">
              <div class="description">Max Temperature</div>
              <div class="value">
                <text-input :disabled="!$checkPermission('platform.devices.update')" text-align="center" placeholder="(Disabled)" v-model="device.settings.hubTempMaxTemperature.value"/>
              </div>
              <div class="unit">°F</div>
              <div class="btns-treshold" v-if="$checkPermission('platform.devices.update')">
                <button class="btn delete" @click="deleteHubTempMaxTemperature">
                  <i class="fas fa-ban" />
                </button>
                <button class="btn save" @click="setHubTempMaxTemperature">
                  <i class="fas fa-check" />
                </button>
              </div>
            </div>
            <div class="row air-tank title">
              <div class="description"></div>
              <div class="value title">Air Tank</div>
              <div class="unit"></div>
              <div class="btns-treshold"></div>
            </div>
            <div class="row air-tank">
              <div class="description">Min Pressure</div>
              <div class="value">
                <text-input :disabled="!$checkPermission('platform.devices.update')" text-align="center" placeholder="(Disabled)" v-model="device.settings.airTankMinPressure.value"/>
              </div>
              <div class="unit">PSI</div>
              <div class="btns-treshold" v-if="$checkPermission('platform.devices.update')">
                <button class="btn delete" @click="deleteAirTankMinPressure">
                  <i class="fas fa-ban" />
                </button >
                <button class="btn save" @click="setAirTankMinPressure">
                  <i class="fas fa-check" />
                </button>
              </div>
            </div>
            <div class="row atis title">
              <div class="description"></div>
              <div class="value title">ATIS</div>
              <div class="unit"></div>
              <div class="btns-treshold"></div>
            </div>
            <div class="row atis min">
              <div class="description">Outlet Min Pressure</div>
              <div class="value">
                <text-input :disabled="!$checkPermission('platform.devices.update')" text-align="center" placeholder="(Disabled)"/>
              </div>
              <div class="unit">PSI</div>
              <div class="btns-treshold" v-if="$checkPermission('platform.devices.update')">
                <button class="btn delete" @click="deleteAtisOutMinPressure">
                  <i class="fas fa-ban" />
                </button>
                <button class="btn save" @click="setAtisOutMinPressure">
                  <i class="fas fa-check" />
                </button>
              </div>
            </div>
            <div class="row atis max">
              <div class="description">Outlet Max Pressure</div>
              <div class="value">
                <text-input :disabled="!$checkPermission('platform.devices.update')" text-align="center" placeholder="(Disabled)"/>
              </div>
              <div class="unit">PSI</div>
              <div class="btns-treshold" v-if="$checkPermission('platform.devices.update')">
                <button class="btn delete" @click="deleteAtisOutMaxPressure">
                  <i class="fas fa-ban" />
                </button>
                <button class="btn save" @click="setAtisOutMaxPressure">
                  <i class="fas fa-check"/>
                </button>
              </div>
            </div>
            <div class="row cold-pressure title">
              <div class="description"></div>
              <div class="value title">Cold Pressure</div>
              <div class="unit"></div>
              <div class="btns-treshold"></div>
            </div>

            <div v-for="(coldPressure, type) of device.settings.coldPressures" :key="type" class="row">
              <div class="description">{{ type }}</div>
              <div class="value">
                <text-input :disabled="!$checkPermission('platform.devices.update')" text-align="center" placeholder="(Disabled)" v-model="coldPressure.min.value"/>
              </div>
              <div class="value">
                <text-input :disabled="!$checkPermission('platform.devices.update')" text-align="center" placeholder="(Disabled)" v-model="coldPressure.value.value"/>
              </div>
              <div class="value">
                <text-input :disabled="!$checkPermission('platform.devices.update')" text-align="center" placeholder="(Disabled)" v-model="coldPressure.max.value"/>
              </div>

              <div class="btns-treshold" v-if="$checkPermission('platform.devices.update')">
                <button class="btn delete" @click="deleteColdPressure(type)">
                  <i class="fas fa-ban" />
                </button>
                <button class="btn save" @click="setColdPressure(type)">
                  <i class="fas fa-check" />
                </button>
              </div>
            </div>
          </div>
        </tab>

        <tab title="Accounts">
          <div class="controls">
            <form-button v-if="$checkPermission('platform.users.account-management')" class="link"  @click="openAccountManagementModal">Manage Account</form-button>
          </div>

          <data-table :value="device.accounts" @refresh="getDevice">
            <column field="accountDevice.id" header="Account Device ID" bodyStyle="text-align: center" headerStyle="width: 220px">
              <template #body="slot">
                <router-link class="editor-link" :to="'/platform2/devices/locations/' + slot.data.accountDevice.id">{{String(slot.data.accountDevice.id).padStart(6, '0')}}</router-link>
              </template>
            </column>
            <column field="name" header="Account" headerStyle="width: 250px" bodyStyle="text-align: center;" sortable/>
          </data-table>
        </tab>
      </tabs>

      <modal
        title="Accounts Management"
        ref="accountManagementModal"
        @closed="refreshAccounts"
      >
        <div class="modal-container">
          <picklist
            :list="accountsList"
            @add-items="addAccounts"
            @remove-items="removeAccounts"
            :height="300"
            v-model="selectedAccounts"
          >
          </picklist>
        </div>
      </modal>
    </template>
  </page>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import Dropdown from '@/components/Dropdown.vue'
import FormButton from '@/components/FormButton.vue'
import Modal from '@/components/Modal.vue'
import Page from '@/components/Page.vue'
import Picklist from '@/components/Picklist.vue'
import Tabs from '@/components/Tabs'
import Tab from '@/components/Tabs/Tab'
import TextInput from '@/components/TextInput.vue'
import Column from 'primevue/column'

import _ from 'lodash'

import { tireviewliveApi } from '@/lib/tireviewlive-api'

export default {
  name: 'DeviceEditorView',

  components: {
    Page,
    TextInput,
    Tabs,
    Tab,
    FormButton,
    Dropdown,
    DataTable,
    Column,
    Picklist,
    Modal
  },

  methods: {
    async refreshAccounts () {
      const device = await tireviewliveApi.get(`/sysadmin/device/${this.deviceId}`, {
        include: 'accounts'
      })

      this.device.accounts = device.accounts
    },

    async getDevice () {
      this.device = await tireviewliveApi.get(`/sysadmin/device/${this.deviceId}`, {
        include: 'accounts'
      })
    },

    async saveDevice () {
      const data = this.device

      if (this.isEditing) {
        await tireviewliveApi.put(`/sysadmin/device/${this.deviceId}`, data).then(() => {
          this.$messageBox('Device successfully saved', 'success')
        }).catch(error => {
          this.$messageBox(error.message, 'danger')
        })
      } else {
        await tireviewliveApi.post('/sysadmin/device', data).then(() => {
          this.$messageBox('Device successfully saved', 'success')
        }).catch(error => {
          this.$messageBox(error.message, 'danger')
        })
      }
    },

    async saveConfig () {
      const data = {
        activationDate: this.device.activationDate,
        powerSource: this.device.powerSource.value,
        atisFamily: this.device.atisFamily.value
      }

      if (this.isEditing) {
        await tireviewliveApi.put(`/sysadmin/device/${this.deviceId}`, data).then(() => {
          this.$messageBox('Device successfully saved', 'success')
        }).catch(error => {
          this.$messageBox(error.message, 'danger')
        })
      } else {
        await tireviewliveApi.post('/sysadmin/device', data).then(() => {
          this.$messageBox('Device successfully saved', 'success')
        }).catch(error => {
          this.$messageBox(error.message, 'danger')
        })
      }
    },

    async setSensor (index) {
      const sensorList = this.device.sensors
      const sensor = sensorList[index]

      const data = sensor

      await tireviewliveApi.put(`/platform/${this.deviceId}/sensor/${sensor.index}`, data)
        .then(() => {
          this.$messageBox(`Set sensor ${sensor.index} successfully`, 'success')
        })
        .catch(error => {
          this.$messageBox(error.message, 'danger')
        })
    },

    async deleteSensor (index) {
      const sensorList = this.device.sensors
      const sensor = sensorList[index]

      await tireviewliveApi.delete(`/platform/${this.deviceId}/sensor/${sensor.index}`)
        .then(() => {
          this.$messageBox(`Delete sensor ${sensor.index} successfully`, 'success')
        })
        .catch(error => {
          this.$messageBox(error.message, 'danger')
        })
    },

    async setReportInterval () {
      const data = { value: this.device.settings.reportInterval }

      await tireviewliveApi.put(`/platform/${this.deviceId}/set-report-interval`, data).then(() => {
        this.$messageBox('Set sucessfully', 'success')
      }).catch(error => {
        this.$messageBox(error.message, 'danger')
      })
    },

    async deleteReportInterval () {
      const value = this.device.settings.reportInterval

      this.device.settings.reportInterval = ''

      await tireviewliveApi.put(`/platform/${this.deviceId}/set-report-interval`, {
        value: 'disable'
      }).then(() => {
        this.device.settings.reportInterval = ''
        this.$messageBox('Delete sucessfully', 'success')
      }).catch(error => {
        this.device.settings.reportInterval = value
        this.$messageBox(error.message, 'danger')
      })
    },

    async setTireMaxTemperature () {
      const data = { value: this.device.settings.tireMaxTemperature.value }

      await tireviewliveApi.put(`/platform/${this.deviceId}/set-tire-max-temperature`, data).then(() => {
        this.$messageBox('Set sucessfully', 'success')
      }).catch(error => {
        this.$messageBox(error.message, 'danger')
      })
    },

    async deleteTireMaxTemperature () {
      const value = this.device.settings.tireMaxTemperature.value

      this.device.settings.tireMaxTemperature.value = ''

      await tireviewliveApi.put(`/platform/${this.deviceId}/set-tire-max-temperature`, {
        value: 'disable'
      }).then(() => {
        this.device.settings.tireMaxTemperature.value = ''
        this.$messageBox('Delete sucessfully', 'success')
      }).catch(error => {
        this.device.settings.tireMaxTemperature.value = value
        this.$messageBox(error.message, 'danger')
      })
    },

    async setTireMinPressure () {
      const data = { value: this.device.settings.tireMinPressure.value }

      await tireviewliveApi.put(`/platform/${this.deviceId}/set-tire-min-pressure`, data).then(() => {
        this.$messageBox('Set sucessfully', 'sucess')
      }).catch(error => {
        this.$messageBox(error.message, 'danger')
      })
    },

    async deleteTireMinPressure () {
      const value = this.device.settings.tireMinPressure.value

      this.device.settings.tireMinPressure.value = ''

      await tireviewliveApi.put(`/platform/${this.deviceId}/set-tire-max-temperature`, {
        value: 'disable'
      }).then(() => {
        this.device.settings.tireMinPressure.value = ''
        this.$messageBox('Delete sucessfully', 'success')
      }).catch(error => {
        this.device.settings.tireMinPressure.value = value
        this.$messageBox(error.message, 'danger')
      })
    },

    async setTireMaxPressure () {
      const data = { value: this.device.settings.tireMaxPressure.value }

      await tireviewliveApi.put(`/platform/${this.deviceId}/set-tire-max-pressure`, data).then(() => {
        this.$messageBox('Set sucessfully', 'success')
      }).catch(error => {
        this.$messageBox(error.message, 'danger')
      })
    },

    async deleteTireMaxPressure () {
      const value = this.device.settings.tireMaxPressure.value

      this.device.settings.tireMaxPressure.value = ''

      await tireviewliveApi.put(`/platform/${this.deviceId}/set-tire-max-temperature`, {
        value: 'disable'
      }).then(() => {
        this.device.settings.tireMaxPressure.value = ''
        this.$messageBox('Delete sucessfully', 'success')
      }).catch(error => {
        this.device.settings.tireMaxPressure.value = value
        this.$messageBox(error.message, 'danger')
      })
    },

    async setHubTempMaxTemperature () {
      const data = { value: this.device.settings.hubTempMaxTemperature.value }

      await tireviewliveApi.put(`/platform/${this.deviceId}/set-hub-max-temperature`, data).then(() => {
        this.$messageBox('Set sucessfully', 'success')
      }).catch(error => {
        this.$messageBox(error.message, 'danger')
      })
    },

    async deleteHubTempMaxTemperature () {
      const value = this.device.settings.hubTempMaxTemperature.value

      this.device.settings.hubTempMaxTemperature.value = ''

      await tireviewliveApi.put(`/platform/${this.deviceId}/set-tire-max-temperature`, {
        value: 'disable'
      }).then(() => {
        this.device.settings.hubTempMaxTemperature.value = ''
        this.$messageBox('Delete sucessfully', 'success')
      }).catch(error => {
        this.device.settings.hubTempMaxTemperature.value = value
        this.$messageBox(error.message, 'danger')
      })
    },

    async setAirTankMinPressure () {
      const data = { value: this.device.settings.airTankMinPressure.value }

      await tireviewliveApi.put(`/platform/${this.deviceId}/set-air-tank-min-pressure`, data).then(() => {
        this.$messageBox('Delete sucessfully', 'success')
      }).catch(error => {
        this.$messageBox(error.message, 'danger')
      })
    },

    async deleteAirTankMinPressure () {
      const value = this.device.settings.airTankMinPressure.value

      this.device.settings.airTankMinPressure.value = ''

      await tireviewliveApi.put(`/platform/${this.deviceId}/set-tire-max-temperature`, {
        value: 'disable'
      }).then(() => {
        this.device.settings.airTankMinPressure.value = ''
        this.$messageBox('Delete sucessfully', 'success')
      }).catch(error => {
        this.device.settings.airTankMinPressure.value = value
        this.$messageBox(error.message, 'danger')
      })
    },

    async setAtisOutMinPressure () {
      const data = { value: this.device.settings.atisOutMinPressure.value }

      await tireviewliveApi.put(`/platform/${this.deviceId}/set-atis-out-min-pressure`, data).then(() => {
        this.$messageBox('Set sucessfully', 'success')
      }).catch(error => {
        this.$messageBox(error.message, 'danger')
      })
    },

    async deleteAtisOutMinPressure () {
      const value = this.device.settings.atisOutMinPressure.value

      this.device.settings.atisOutMinPressure.value = ''

      await tireviewliveApi.put(`/platform/${this.deviceId}/set-tire-max-temperature`, {
        value: 'disable'
      }).then(() => {
        this.device.settings.atisOutMinPressure.value = ''
        this.$messageBox('Delete sucessfully', 'success')
      }).catch(error => {
        this.device.settings.atisOutMinPressure.value = value
        this.$messageBox(error.message, 'danger')
      })
    },

    async setAtisOutMaxPressure () {
      const data = { value: this.device.settings.atisOutMaxPressure.value }

      await tireviewliveApi.put(`/platform/${this.deviceId}/set-atis-out-max-pressure`, data).then(() => {
        this.$messageBox('Set sucessfully', 'success')
      }).catch(error => {
        this.$messageBox(error.message, 'danger')
      })
    },

    async deleteAtisOutMaxPressure () {
      const value = this.device.settings.atisOutMaxPressure.value

      this.device.settings.atisOutMaxPressure.value = ''

      await tireviewliveApi.put(`/platform/${this.deviceId}/set-tire-max-temperature`, {
        value: 'disable'
      }).then(() => {
        this.device.settings.atisOutMaxPressure.value = ''
        this.$messageBox('Delete sucessfully', 'success')
      }).catch(error => {
        this.device.settings.atisOutMaxPressure.value = value
        this.$messageBox(error.message, 'danger')
      })
    },

    async setColdPressure (type) {
      const data = this.device.settings.coldPressures[type]

      try {
        await tireviewliveApi.put(`/platform/${this.deviceId}/set-cold-pressure/${type}`, data)
        this.$messageBox('Set successfully', 'success')
      } catch (error) {
        this.$messageBox(error.message, 'danger')
      }
    },

    async deleteColdPressure (type) {
      const data = { value: 'disable' }

      try {
        await tireviewliveApi.put(`/platform/${this.deviceId}/set-cold-pressure/${type}`, data)
        this.$messageBox('Delete successfully', 'success')
      } catch (error) {
        this.$messageBox(error.message, 'danger')
      }
    },

    async addAccounts (items) {
      await tireviewliveApi.post(`/sysadmin/device/${this.deviceId}/attach-accounts`, {
        accounts: items.map(i => i.value)
      })
    },

    async removeAccounts (items) {
      await tireviewliveApi.delete(`/sysadmin/device/${this.deviceId}/dettach-accounts`, {
        accounts: items.map(i => i.value)
      })
    },

    openAccountManagementModal () {
      this.$refs.accountManagementModal.open()
    },

    async init () {
      this.tireGraphicsList = await tireviewliveApi.get('/tire-graphics').then(list => {
        return this.$mapListItems(list, ['description', 'id'])
      })

      await this.getDevice()

      this.accountsList = await tireviewliveApi.get('/sysadmin/accounts').then(response => {
        return this.$mapListItems(response, ['name', 'id'])
      })

      this.selectedAccounts = this.$mapListItems(this.device.accounts, ['name', 'id'])

      const sensorList = [...this.device.sensors]

      for (let i = 0; i < 108; i++) {
        const sensor = sensorList.find(j => j.index === i)
        let tireGraphicSensor = this.device.settings.tireGraphic.layout.objects.find(item => {
          if (Array.isArray(item.sensor)) {
            return item.sensor.includes(i)
          }

          return item.sensor === i
        })

        if (sensor !== undefined) {
          sensor.placeholder = sensor.name
        }

        if (tireGraphicSensor !== undefined) {
          if (tireGraphicSensor.type === 'atis') {
            if (tireGraphicSensor.sensor[0] === i) {
              tireGraphicSensor = {
                name: 'ATIS IN',
                type: 'atisIn'
              }
            } else {
              tireGraphicSensor = {
                name: 'ATIS OUT',
                type: 'atisOut'
              }
            }
          }
        }

        this.device.sensors[i] = sensor ?? {
          index: i,
          id: '',
          name: '',
          type: tireGraphicSensor?.tireType ?? tireGraphicSensor?.type ?? '',
          placeholder: tireGraphicSensor?.name ?? ''
        }
      }

      this.device.sensors = this.device.sensors.map((item) => {
        if (item.type === 'tire') {
          item.type = item.type + _.capitalize(item.tireType)
        }

        item.type = this.sensorTypeList.fromValue(item.type)

        return item
      })

      this.device.settings.tireMaxTemperature ??= { value: '' }
      this.device.settings.tireMinPressure ??= { value: '' }
      this.device.settings.tireMaxPressure ??= { value: '' }
      this.device.settings.hubTempMaxTemperature ??= { value: '' }
      this.device.settings.airTankMinPressure ??= { value: '' }
      this.device.settings.atisOutMinPressure ??= { value: '' }
      this.device.settings.atisOutMaxPressure ??= { value: '' }

      const coldPressuresTypes = ['steer', 'drive', 'trailer', 'tag']
      const coldPressures = {}

      for (const type of coldPressuresTypes) {
        const coldPressure = this.device.settings.coldPressures[type]

        if (coldPressure !== undefined) {
          coldPressures[type] = coldPressure
        } else {
          coldPressures[type] = {
            min: { value: '' },
            value: { value: '' },
            max: { value: '' }
          }
        }
      }

      this.device.settings.coldPressures = coldPressures

      this.device.activationDate = this.device.activationDate.substring(0, 10)
      this.device.powerSource = this.powerSourceList.fromValue(this.device.powerSource)
      this.device.atisFamily = this.atisFamilyList.fromValue(this.device.atisFamily)
      this.device.settings.tireGraphicId = this.tireGraphicsList.fromValue(this.device.settings.tireGraphicId)

      this.loading = false
    }

  },

  watch: {
    '$store.state.workingAccount': function () {
      this.$router.replace('/platform2/devices')
    }
  },

  computed: {
    isEditing () {
      return !!this.$route.params.deviceId
    },

    pageTitle () {
      return this.isEditing ? 'Edit Device' : 'Create Device'
    },

    deviceId () {
      return this.$route.params.deviceId
    },

    tireGraphicBoundary () {
      return {
        min: this.device.settings.tireGraphic.layout.objects[0].sensor,
        max: this.device.settings.tireGraphic.layout.objects.toReversed()[0].sensor
      }
    }
  },

  data () {
    return {
      loading: true,
      device: {},
      tireGraphicsList: [],
      accountsList: [],
      selectedAccounts: [],
      powerSourceList: this.$mapListItems([
        'vehicle',
        { value: 'battery_backup', label: 'Battery Backup' }
      ]),
      atisFamilyList: this.$mapListItems([
        { value: 'none', label: 'None' },
        { value: 'psi', label: 'PSI-Meritor' },
        { value: 'hendrickson-cp', label: 'Hendrickson CP' },
        { value: 'hendrickson-pro', label: 'Hendrickson PRO' },
        { value: 'hendrickson-pro-lb', label: 'Hendrickson Pro LB' }

      ]),
      sensorTypeList: this.$mapListItems([
        { value: 'tire', label: 'Tire' },
        { value: 'tireSteer', label: 'Steer' },
        { value: 'tireDrive', label: 'Drive' },
        { value: 'tireTrailer', label: 'Trailer' },
        { value: 'tireSpare', label: 'Spare' },
        { value: 'tireTag', label: 'Tag' },
        { value: 'airTank', label: 'Air Tank' },
        { value: 'hubTemp', label: 'Hub Temp' },
        { value: 'atisIn', label: 'ATIS In' },
        { value: 'atisOut', label: 'ATIS Out' }
      ])
    }
  },

  async mounted () {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.row {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  gap: 10px;
  &:focus-within .btn {
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }

}
.index {
  flex: 0 0 40px;
  text-align: center;
  opacity: 0.7;
}
.sensor-id {
  flex: 0 0 120px;
}
.sensor-name {
  flex: 1;
}
.sensor-type {
  flex: 0 0 170px;
}
.btns {
  flex: 0 0 100px;
  text-align: right;
}
.btn {
  width: 45px;
  height: 45px;
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  padding: 0;

  &.save {
    background-color: #20a020;
    opacity: 0.2;
    color: #FFF;
    margin-left: 10px;

    &:active {
      opacity: 0.7;
    }
  }
  &.delete {
    background-color: #c30000;
    opacity: 0.2;
    color: #FFF;

    &:active {
      opacity: 0.7;
    }
  }

}

.thresholds {
  .title {
    font-size: 14pt;
    font-weight: bold;
    font-family: Rajdhani, sans-serif;
    opacity: 0.8;
    text-align: center;
    margin: 10px 10px;
  }
  .description {
    flex: 1;
    font-size: 16pt;
    font-weight: 400;
    font-family: Rajdhani, sans-serif;
    opacity: 0.8;
    text-align: center;
    text-transform: capitalize;
  }
  .value {
    flex: 0 0 120px;
    text-align: center;
  }
  .unit {
    flex: 0 0 80px;
    font-size: 14pt;
    font-family: Rajdhani, sans-serif;
    opacity: 0.5;
  }
  .btns-treshold {
    flex: 0 0 200px;
    text-align: right;
  }
}
</style>
