<template>
  <page :title="pageTitle" backURI=":back" :loading="loading">
    <template #controls>
      <form-button v-if="isEditing && $checkPermission('platform.locations.delete')" icon="fal fa-trash" @click="confirmDeletion">Delete Location</form-button>
    </template>

    <template #body>
      <h1 class="title"><span v-if="!isEditing">New</span> Location <strong>{{ location.name }}</strong></h1>

      <tabs>
        <tab title="Location">
          <div class="form">
            <div class="input-group size-sm">
              <text-input v-if="isEditing" type="number" label="ID" :value="String(location.id).padStart(6, 0)" disabled/>
            </div>

            <div class="input-group size-md">
              <text-input :disabled="!$checkPermission('platform.locations.update')" label="Name" v-model="location.name"/>
            </div>
            <div class="input-group size-sm">
              <text-input :disabled="!$checkPermission('platform.locations.update')" label="Legacy Location ID" type="number" v-model="location.legacyLocationId"/>
            </div>
            <div class="input-group bottom">
              <form-button v-if="$checkPermission('platform.locations.update')" icon="fal fa-check" class="default" @click="save">Save</form-button>
            </div>
          </div>
        </tab>

        <tab title="Users" :disabled="!isEditing">
          <picklist
            :list="usersList"
            @add-items="addUsers"
            @remove-items="removeUsers"
            :height="500"
            v-model="selectedUsers"
            :disabled="!$checkPermission('platform.locations.update')"
          >
            <template #body="item">
              <user-picklist-item :user="item" />
            </template>
          </picklist>
        </tab>

        <tab title="Devices" :disabled="!isEditing">
          <picklist
            :list="devicesList"
            @add-items="addDevices"
            @remove-items="removeDevices"
            :height="500"
            v-model="selectedDevices"
            :disabled="!$checkPermission('platform.locations.update')"
          >
            <template #body="item">
              <device-picklist-item :device="item" />
            </template>
          </picklist>
        </tab>

      </tabs>

    </template>
  </page>
</template>

<script>
import FormButton from '@/components/FormButton.vue'
import Page from '@/components/Page.vue'
import Picklist from '@/components/Picklist.vue'
import Tabs from '@/components/Tabs'
import Tab from '@/components/Tabs/Tab'
import TextInput from '@/components/TextInput.vue'
import DevicePicklistItem from './components/DevicePicklistItem.vue'
import UserPicklistItem from './components/UserPicklistItem.vue'

import { tireviewliveApi } from '@/lib/tireviewlive-api'

export default {
  name: 'LocationEditorView',

  components: {
    Page,
    TextInput,
    FormButton,
    Tabs,
    Tab,
    Picklist,
    DevicePicklistItem,
    UserPicklistItem
  },

  methods: {
    async save () {
      const data = {
        ...this.location,
        accountId: this.accountId
      }

      if (this.isEditing) {
        await tireviewliveApi.put(`/sysadmin/location/${this.locationId}`, data).then(() => {
          this.$messageBox('Location successfully saved', 'success')
        }).catch((error) => {
          this.$messageBox(error.message, 'danger')
        })
      } else {
        await tireviewliveApi.post('/sysadmin/location', data).then((newLocation) => {
          this.$messageBox('Location successfully saved', 'success')

          this.$router.replace(`/platform2/locations/update/${newLocation.id}`)

          this.init()
        }).catch((error) => {
          this.$messageBox(error.message, 'danger')
        })
      }
    },

    async addUsers (items) {
      await tireviewliveApi.post(`/sysadmin/location/${this.locationId}/attach-users`, {
        accountsUsers: items.map(i => i.value)
      })
    },

    async removeUsers (items) {
      await tireviewliveApi.delete(`/sysadmin/location/${this.locationId}/dettach-users`, {
        accountsUsers: items.map(i => i.value)
      })
    },

    async addDevices (items) {
      await tireviewliveApi.post(`/sysadmin/location/${this.locationId}/attach-devices`, {
        accountsDevices: items.map(i => i.value)
      })
    },

    async removeDevices (items) {
      await tireviewliveApi.delete(`/sysadmin/location/${this.locationId}/dettach-devices`, {
        accountsDevices: items.map(i => i.value)
      })
    },

    async init () {
      if (this.isEditing) {
        this.location = await tireviewliveApi.get(`/sysadmin/location/${this.locationId}`, {
          include: 'accountsUsers,accountsDevices'
        })

        this.usersList = await tireviewliveApi.get('/sysadmin/users', {
          accountId: this.location.accountId,
          include: 'accountUser'
        }).then(response => {
          return this.$mapListItems(response, ['fullName', 'accountUser.id'])
        })

        this.devicesList = await tireviewliveApi.get('/sysadmin/devices', {
          accountId: this.location.accountId
        }).then(response => {
          return this.$mapListItems(response, ['name', 'accountDevice.id'])
        })

        this.selectedUsers = this.$mapListItems(this.location.accountsUsers, item => {
          const user = this.usersList.find(i => i.id === item.userId)

          return {
            ...user,
            label: user.fullName,
            value: item.id
          }
        })

        this.selectedDevices = this.$mapListItems(this.location.accountsDevices, item => {
          const device = this.devicesList.find(i => i.id === item.deviceId)

          return {
            ...device,
            label: device.name,
            value: item.id
          }
        })
      }

      this.loading = false
    },

    async confirmDeletion () {
      this.$confirm.require({
        message: 'Are you sure you want to delete this location?',
        header: 'Delete',
        icon: 'fas fa-exclamation-triangle',

        accept: async () => {
          await tireviewliveApi.delete(`/sysadmin/location/${this.locationId}`)

          this.$router.replace('/platform2/locations')
        }
      })
    }
  },

  computed: {
    isEditing () {
      return !!this.$route.params.locationId
    },

    pageTitle () {
      return this.isEditing ? 'Edit Location' : 'Create Location'
    },

    locationId () {
      return this.$route.params.locationId
    },

    accountId () {
      return this.$store.state.workingAccount.value
    }
  },

  data () {
    return {
      location: {},
      usersList: [],
      selectedUsers: [],
      devicesList: [],
      selectedDevices: [],
      loading: true
    }
  },

  async mounted () {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.device-list-item {
  .info {
    opacity: 0.5;
    font-size: 10pt;

    .sep {
      opacity: 0.5;
      margin: 0 5px;
    }
  }
}
</style>
