<template>
  <page title="Ban List" :loading="loading">
    <template #body>
      <data-table :value="banList" @refresh="getBanList">

        <column field="createdAt" header="Date Time" bodyStyle="text-align: center" headerStyle="width: 150px" sortable>
          <template #body="slot">
            <span>{{ $formatDateTime(slot.data.createdAt) }}</span>
          </template>
        </column>
        <column header="IP/User" bodyStyle="text-align: center" headerStyle="width: 150px">
          <template #body="slot">
            <span v-if="slot.data.type === 'ip'">{{ slot.data.ip }}</span>
            <span v-else>{{ parseUser(slot.data.user) }}</span>
          </template>
        </column>
        <column bodyStyle="text-align: center" headerStyle="width: 150px">
          <template #body="slot">
            <button class="btn danger" @click="unbanUser(slot.data.id)">
              <i class="fas fa-trash" />
              Unban
            </button>
          </template>
        </column>
      </data-table>

    </template>
  </page>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import Page from '@/components/Page.vue'
import Column from 'primevue/column'

import { tireviewliveApi } from '@/lib/tireviewlive-api'

export default {
  name: 'BanListView',

  components: {
    DataTable,
    Page,
    Column
  },

  methods: {
    async getBanList () {
      this.loading = true

      this.banList = await tireviewliveApi.get('/sysadmin/banlists', {
        include: 'user'
      })

      this.loading = false
    },

    async unbanUser (id) {
      this.$confirm.require({
        message: 'Are you sure you want to remove this ban entry?',
        header: 'Unban',
        icon: 'far fa-comment-question',

        accept: async () => {
          await tireviewliveApi.delete(`/sysadmin/banlist/${id}`)
          this.getBanList()
        }

      })
    },

    parseUser (user) {
      return user?.fullName ?? 'Unknown'
    }
  },

  data () {
    return {
      loading: true,
      banList: []
    }
  },

  mounted () {
    this.getBanList()
  }
}
</script>

<style lang="less" scoped>
.btn {
  color: @danger-color;

  i {
    margin-right: 6px;
  }
}
</style>
