import * as PusherPushNotifications from '@pusher/push-notifications-web'
import gql from 'graphql-tag'

import store from '../store'

export function getClient () {
  return navigator.serviceWorker.ready.then(registration => {
    return new PusherPushNotifications.Client({
      instanceId: process.env.VUE_APP_PUSHER_INSTANCE_ID,
      serviceWorkerRegistration: registration
    })
  })
}
window.getClient = getClient
export function initPusherClient () {
  const userId = store.state.auth.user._id
  const beamsToken = store.state.auth.beamsToken

  const tokenProvider = {
    fetchToken () {
      return Promise.resolve({ token: beamsToken })
    }
  }

  return getClient().then(client => {
    return client.getRegistrationState().then(state => {
      if (state.endsWith('NOT_REGISTERED_WITH_BEAMS')) {
        return client.start().then(() => {
          return client.setUserId(userId, tokenProvider)
        })
      }
    })
  })
}

export function destroyPusherClient () {
  return getClient().then(client => {
    return client.stop()
  })
}

export default {
  install (Vue) {
    Vue.mixin({
      methods: {
        $enableNotifications () {
          if (this.$options.notificationsChannel !== undefined) {
            return this.$apollo.mutate({
              mutation: gql`
                mutation ($channel: String!) {
                  iam {
                    addUserSubscription(channel: $channel)
                  }
                }
              `,
              variables: {
                channel: this.$options.notificationsChannel
              }
            }).then(() => {
              this.isNotificationEnabled = true
            })
          }
        },

        $disableNotifications () {
          if (this.$options.notificationsChannel !== undefined) {
            return this.$apollo.mutate({
              mutation: gql`
                mutation ($channel: String!) {
                  iam {
                    removeUserSubscription(channel: $channel)
                  }
                }
              `,
              variables: {
                channel: this.$options.notificationsChannel
              }
            }).then(() => {
              this.isNotificationEnabled = false
            })
          }
        },

        $toggleNotifications (val) {
          if (this.$options.notificationsChannel) {
            if (val === true) {
              return this.$enableNotifications()
            } else if (val === false) {
              return this.$disableNotifications()
            }

            return this.$apollo.mutate({
              mutation: gql`
              mutation ($channel: String!) {
                iam {
                  result: toggleUserSubscription(channel: $channel)
                }
              }
            `,
              variables: {
                channel: this.$options.notificationsChannel
              }
            }).then(response => {
              this.isNotificationEnabled = response.data.iam.result

              return response.data.iam.result
            })
          }
        }
      },

      computed: {
        $notificationsLabel () {
          return this.isNotificationEnabled
            ? 'Disable Notifications'
            : 'Enable Notifications'
        },

        $notificationsIcon () {
          return this.isNotificationEnabled
            ? 'fas fa-bell-on'
            : 'fal fa-bell-slash'
        }
      },

      data () {
        return {
          isNotificationEnabled: false
        }
      },

      mounted () {
        if (this.$options.notificationsChannel !== undefined) {
          return this.$apollo.query({
            query: gql`
              query ($channel: String!) {
                iam {
                  result: isUserSubscribed(channel: $channel)
                }
              }
            `,
            variables: {
              channel: this.$options.notificationsChannel
            },
            fetchPolicy: 'no-cache'
          }).then(response => {
            this.isNotificationEnabled = response.data.iam.result
          })
        }
      }
    })
  }
}
