<template>
  <page title="Order Generator">
    <template #controls>
      <form-button @click="reset" icon="fal fa-sync-alt">Reset</form-button>
      <form-button @click="generate" icon="fal fa-print">Generate</form-button>
    </template>

    <template #body>
      <div class="form">
        <div class="input-group size-sm">
          <text-input type="number" label="Order Number" v-model="order.number" ref="number"/>
        </div>
        <div class="input-group size-md">
          <item-picker label="Client" :data="accountPickerOptions.list" :loading="accountPickerOptions.loading" @search="searchAccount" @select="accountSelected" placeholder="Account Name or ID" ref="account"/>
        </div>
        <div class="input-group size-sm">
          <text-input label="System" v-model="order.account.systemName" disabled/>
        </div>

        <h2>ITEMS</h2>
        <div class="input-group">
          <div class="table">
            <div class="header">
              <div class="vehicle-type">Vehicle Type</div>
              <div class="location">Location</div>
              <div class="quantity">Quantity</div>
              <div class="controls"></div>
            </div>
            <div class="row" v-for="item of order.items" :key="item.id">
              <div class="vehicle-type">{{item.batchSettings.name}}</div>
              <div class="location">{{item.locations.map(i => i.name).join(', ')}}</div>
              <div class="quantity">
                {{item.quantity}}
              </div>
              <div class="controls">
                <button tabindex="-1" @click="removeOrderItem(item.id)">
                  <i class="fal fa-trash"></i>
                </button>
              </div>
            </div>
            <div class="row input">
              <div class="vehicle-type">
                <item-picker :data="batchSettingsPickerOptions.list" @search="searchBatchSetting" @select="batchSettingSelected" placeholder="Vehicle type name or ID" ref="batchSetting"/>
              </div>
              <div class="quantity">
                <text-input type="number" placeholder="Quantity" v-model.number="orderItem.quantity" @keyup.enter="addItem" @keyup.esc="resetItemAdding" ref="quantity"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <modal title="Location" ref="locationsModal">
        <div class="locations-list">
          <list-box @confirm="locationsConfirm" :options="locationsPickerOptions.list" optionLabel="name" dataKey="id" v-model="locationsPickerOptions.value" :filter="false">
            <template #option="slot">
              <div class="location-item">
                <div class="icon">
                  <i class="fas fa-map-marker-alt"></i>
                </div>
                <div>
                  <div class="name">{{slot.option.name}}</div>
                </div>
              </div>
            </template>
          </list-box>
        </div>
        <template #footer>
          <button class="btn">Cancel</button>
          <button class="btn primary" @click="locationsConfirm">Confirm</button>
        </template>
      </modal>

      <iframe id="print-helper-frame"></iframe>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import FormButton from '@/components/FormButton.vue'
import TextInput from '@/components/TextInput.vue'
import ItemPicker from '@/components/ItemPicker.vue'
import Modal from '@/components/Modal.vue'
import ListBox from '@/components/ListBox.vue'
import gql from 'graphql-tag'

export default {
  name: 'OrderGeneratorView',

  components: {
    Page,
    FormButton,
    TextInput,
    ItemPicker,
    Modal,
    ListBox
  },

  methods: {
    generate () {
      const frame = document.querySelector('#print-helper-frame')

      this.order.issuedBy = this.$store.state.auth.user.employeeId

      sessionStorage.setItem('order-generator', JSON.stringify(this.order))

      frame.src = this.$route.path + '/print'
    },

    reset () {
      this.order.number = ''
      this.order.account = {}
      this.order.items = []

      this.$refs.account.reset()
      this.$refs.batchSetting.reset()

      this.$nextTick().then(() => {
        this.$refs.number.focus()
      })
    },

    searchAccount (searchText) {
      this.accountPickerOptions.loading = true
      this.accountPickerOptions.list = []

      this.$apollo.query({
        query: gql`
          query ($searchText: String!) {
            platform {
              accounts: searchAccount (searchText: $searchText) {
                id
                name
                system {
                  index
                  id
                  name
                }
              }
            }
          }
        `,
        variables: {
          searchText
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.accountPickerOptions.list = response.data.platform.accounts.map(item => {
          return {
            id: item.system.id + '_' + item.id,
            value: item.id,
            systemId: item.system.id,
            systemName: item.system.name,
            label: item.name,
            altLabel: `${item.name} <span class="system-tag ${item.system.id}">${item.system.name}</span> <span class="id-tag float-right">#${item.id}</span>`
          }
        })
      }).finally(() => {
        this.accountPickerOptions.loading = false
      })
    },

    accountSelected (item) {
      this.order.account = item
      this.order.items = []

      this.$refs.batchSetting.$el.querySelector('input').focus()

      this.$apollo.query({
        query: gql`
          query ($systemId: ID!, $accountId: Int!) {
            platform {
              account: getAccount (systemId: $systemId, accountId: $accountId) {
                id
                name
                batchSettings {
                  id
                  description
                  layout {
                    id
                    sensors {
                      index
                    }
                  }
                }
                locations {
                  id
                  name
                }
              }
            }
          }
        `,
        variables: {
          systemId: item.systemId,
          accountId: item.value
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.accountBatchSettings = response.data.platform.account.batchSettings
        this.locationsPickerOptions.list = response.data.platform.account.locations
      })
    },

    locationsConfirm () {
      this.$refs.locationsModal.close(this.locationsPickerOptions.value)

      this.locationsPickerOptions.value = null
    },

    searchBatchSetting (searchText) {
      this.batchSettingsPickerOptions.list = this.accountBatchSettings.filter(batchSetting => {
        return batchSetting.description.toLowerCase().includes(searchText.toLowerCase()) ||
          batchSetting.id === Number(searchText)
      }).sort((a, b) => {
        if (b.id === Number(searchText)) {
          return 1
        } else {
          return a.description.toLowerCase().indexOf(searchText.toLowerCase()) -
            b.description.toLowerCase().indexOf(searchText.toLowerCase())
        }
      }).map(batchSetting => {
        return {
          value: batchSetting.id,
          label: batchSetting.description,
          altLabel: `${batchSetting.description} <span class="id-tag float-right">#${batchSetting.id}</span>`,
          layout: {
            id: batchSetting.layout.id,
            name: batchSetting.layout.description,
            sensors: batchSetting.layout.sensors.length
          }
        }
      })
    },

    batchSettingSelected (item) {
      this.orderItem.vehicle.id = item.layout.id
      this.orderItem.vehicle.name = item.layout.name
      this.orderItem.vehicle.sensors = item.layout.sensors
      this.orderItem.batchSettings.id = item.value
      this.orderItem.batchSettings.name = item.label

      this.$refs.quantity.focus()
    },

    async addItem () {
      if (this.orderItem.vehicle.id === null) {
        return
      }

      if (this.orderItem.quantity === 0 || this.orderItem.quantity === '') {
        return
      }

      const locations = await this.$refs.locationsModal.open() ?? [{
        id: 0,
        name: '(None)'
      }]

      const existingOrderItem = this.order.items.find(item => {
        return item.batchSettings.id === this.orderItem.batchSettings.id &&
          item.locations.map(i => i.id).join(',') === locations.map(i => i.id).join(',')
      })

      if (existingOrderItem) {
        existingOrderItem.quantity += this.orderItem.quantity
      } else {
        this.order.items.push({
          id: Math.random(),
          batchSettings: {
            id: this.orderItem.batchSettings.id,
            name: this.orderItem.batchSettings.name
          },
          locations,
          vehicle: {
            id: this.orderItem.vehicle.id,
            name: this.orderItem.vehicle.name,
            sensors: this.orderItem.vehicle.sensors * this.orderItem.quantity
          },
          quantity: this.orderItem.quantity
        })
      }

      this.orderItem.vehicle.id = null
      this.orderItem.vehicle.name = ''
      this.orderItem.batchSettings.id = null
      this.orderItem.batchSettings.name = ''
      this.orderItem.quantity = ''

      this.$refs.batchSetting.reset()
    },

    resetItemAdding () {
      this.orderItem.quantity = ''
      this.$refs.batchSetting.reset()
    },

    removeOrderItem (id) {
      this.order.items = this.order.items.filter(i => i.id !== id)

      this.resetItemAdding()
    },

    setFocus () {
      this.$nextTick().then(() => {
        if (!this.$isMobile()) {
          this.$el.querySelector('input:not(:disabled)').focus()
        }
      })
    }
  },

  data () {
    return {
      order: {
        number: '',
        account: {},
        items: [],
        issuedBy: ''
      },

      orderItem: {
        batchSettings: {
          id: null,
          name: ''
        },
        vehicle: {
          id: null,
          name: '',
          sensors: 0
        },
        quantity: ''
      },

      accountPickerOptions: {
        list: [],
        loading: false
      },

      batchSettingsPickerOptions: {
        list: []
      },

      locationsPickerOptions: {
        list: [],
        value: null
      }
    }
  },

  mounted () {
    this.setFocus()
  }
}
</script>

<style lang="less" scoped>
h2 {
  font-family: 'Rajdhani', sans-serif;
  margin: 25px 0 10px 0;
}

.locations-list {
  width: 500px;
}

.location-item {
  display: flex;

  > div {
    flex: 1;
  }

  .icon {
    display: flex;
    flex: 0 0 45px;
    align-items: center;
    justify-content: center;
    font-size: 14pt;
    margin-right: 10px;
    color: tint(@accent-color, 10%);
  }

  .name {
    display: inline-block;
    width: 300px;
  }
}

.table {
  .header,
  .row {
    display: flex;
    align-items: center;

    > div {
      flex: 1;
    }

    .quantity {
      flex: 0 0 180px;
      margin-left: 1px;
      text-align: center;
    }

    .controls {
      flex: 0 0 0;
      margin-left: 5px;
    }
  }

  .header {
    border-bottom: 1px dotted fade(contrast(@main-color), 10%);

    > div {
      margin-bottom: 10px;
      opacity: 0.5;
    }
  }

  .row {
    /deep/ .vehicle-type input {
      border-radius: 8px 0 0 8px;
    }

    /deep/ .quantity .input-components {
      border-radius: 0 8px 8px 0 !important;
    }

    .location {
      margin-left: 45px;
    }

    .controls {
      button {
        width: 40px;
        height: 40px;
        border: none;
        background: transparent;
        color: @danger-color;
        font-size: 15pt;
        cursor: pointer;
        opacity: 0.5;
        transition: all 200ms;

        &:hover {
          opacity: 1;
        }

        &:active {
          opacity: 0.5;
        }
      }
    }

    &.input {
      margin-top: 10px;
    }

    &:not(.input, .controls) {
      padding: 0 10px;
    }

    &:nth-child(odd):not(.input) {
      background: fade(contrast(@main-color), 1%);
    }

    &:nth-child(even):not(.input) {
      background: fade(contrast(@main-color), 3%);
    }
  }
}

iframe {
  display: none;
}
</style>
