<template>
  <div class="editable-list">
    <label>{{label}}</label>
    <div class="list-container">
      <div v-for="(item, index) of items" :key="item.id" class="list-item">
        <div class="input-container">
          <text-input
            v-model="item.value"
            :type="type"
            :placeholder="placeholder"
            @keyup.esc="itemInputEscape(item, index)"
            @keydown.enter="itemInputEnter(item, index)"
            @keydown.up="goToItem(index - 1)"
            @keydown.down="goToItem(index + 1)"
            class="input"
            ref="input"
            autocomplete="off"
          />
        </div>
        <div class="remove-btn-container">
          <button @click="removeItem(index)" :disabled="disableRemoveItem">
            <i class="fal fa-trash"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="add-btn-container">
      <button @click="addItem" :disabled="disableAddItem">
        <i class="fal fa-plus-circle"></i>
      </button>
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue'

export default {
  name: 'EditableList',

  props: {
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'text'
    },
    label: String,
    placeholder: String
  },

  components: {
    TextInput
  },

  methods: {
    addItem () {
      this.items.push({
        id: Math.random(),
        value: ''
      })

      this.$nextTick().then(() => {
        this.$refs.input[this.$refs.input.length - 1].focus()
      })
    },

    removeItem (index) {
      if (this.items.length === 1) {
        this.items[0].value = ''

        this.$refs.input[0].focus()
      } else {
        this.items.splice(index, 1)

        this.$nextTick().then(() => {
          this.$refs.input[index > 0 ? index - 1 : 0].focus()
        })
      }
    },

    itemInputEnter (item, index) {
      if (index === (this.items.length - 1)) {
        if (!this.disableAddItem) {
          this.addItem()
        }
      } else {
        this.$refs.input[index + 1].focus()
      }
    },

    itemInputEscape (item, index) {
      if (item.value === '') {
        this.removeItem(index)
      } else {
        item.value = ''
      }
    },

    goToItem (index) {
      const input = this.$refs.input[index]

      if (input) {
        input.focus()
      }
    }
  },

  computed: {
    disableAddItem () {
      const lastItem = this.items[this.items.length - 1]

      return lastItem?.value === ''
    },

    disableRemoveItem () {
      return (this.items.length === 1) && (this.items[0].value === '')
    }
  },

  watch: {
    items: {
      deep: true,
      handler () {
        this.$emit('input', this.items.filter(i => i.value !== '').map(i => i.value))
      }
    }
  },

  data () {
    return {
      items: this.value.length === 0
        ? [{
          id: Math.random(),
          value: ''
        }]
        : this.value.map(item => {
          return {
            id: Math.random(),
            value: item
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../assets/shared.less';

.editable-list {
  @border-radius: 8px;

  label {
    color: contrast(@main-color);
    font-family: Rajdhani, sans-serif;
  }

  .list-container {
    .list-item {
      display: flex;

      .input-container {
        flex: 1;

        /deep/ .input-components {
          border-radius: 0;
        }
      }

      .remove-btn-container {
        flex: 0 0 50px;

        button {
          .base-btn;
          width: 100%;
          height: 100%;
          border-radius: 0;
          font-size: 15pt;
        }
      }

      &:nth-child(odd) {
        /deep/ .input-components {
          background: fade(contrast(@main-color), 12%);
        }
      }

      &:first-child {
        /deep/ .input-components {
          border-top-left-radius: @border-radius;
        }

        button {
          border-top-right-radius: @border-radius;
        }
      }

      &:last-child {
        /deep/ .input-components {
          border-bottom-left-radius: @border-radius;
        }
      }
    }
  }

  .add-btn-container {
    text-align: right;

    button {
      .base-btn;
      width: 50px;
      height: 45px;
      border-radius: 0 0 8px 8px;
      font-size: 15pt;
    }
  }
}
</style>
