import store from '../store'

const PAGINATION_PROCESSING_LIMIT = 200

export const tireviewliveApi = {
  get: tireviewliveApiRequest.bind(null, 'GET'),
  post: tireviewliveApiRequest.bind(null, 'POST'),
  put: tireviewliveApiRequest.bind(null, 'PUT'),
  delete: tireviewliveApiRequest.bind(null, 'DELETE'),
  stream: tireviewliveApiRequest.bind(null, 'STREAM')
}

export async function tireviewliveApiRequest (type, endpoint, params, processPagination = true, progress = () => {}) {
  const url = new URL(process.env.VUE_APP_TIREVIEWLIVE_API_URL + endpoint)

  let body

  if (type === 'GET') {
    for (const [key, value] of Object.entries(params ?? {})) {
      if (value !== undefined) {
        url.searchParams.set(key, String(value))
      }
    }

    url.searchParams.set('!ignore-pagination', '1')
  }

  if (type === 'POST' || type === 'PUT' || type === 'DELETE') {
    const data = params

    body = JSON.stringify(data)
  }

  if (type === 'STREAM') {
    fetch(url.toString(), {
      method: 'POST',
      headers: {
        Authorization: store.state.auth?.tireviewliveToken ?? '',
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      const reader = response.body.getReader()

      reader.read().then(function pump ({ done, value }) {
        progress(new TextDecoder().decode(value), done)

        if (done) return

        return reader.read().then(pump)
      })
    })

    return
  }

  const response = await fetch(url.toString(), {
    method: type,
    headers: {
      Authorization: store.state.auth?.tireviewliveToken ?? '',
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body
  }).then(async response => {
    if (type !== 'DELETE') {
      return await response.json()
    }
  })

  if (response === undefined) {
    return null
  }

  if (response.success) {
    if (response.pagination !== undefined && type === 'GET' && processPagination) {
      if (response.pagination.meta.pagesTotal > PAGINATION_PROCESSING_LIMIT) {
        throw new Error(`Pages count exceeds the maximum limit for recursive processing: ${PAGINATION_PROCESSING_LIMIT}\n Try increasing the page size`)
      }

      const requests = []

      for (let i = 2; i <= response.pagination.meta.pagesTotal; i++) {
        requests.push(
          tireviewliveApiRequest('GET', url.pathname + url.search, {
            page: i
          }, false).then((data) => {
            progress((i / response.pagination.meta.pagesTotal) * 100)

            return data
          })
        )
      }

      const responses = await Promise.all(requests)

      return (response.data).concat(...responses.flat())
    }

    return response.data
  } else {
    throw new Error(response.error.message)
  }
}
