<template>
  <div class="picklist" :class="{disabled}">
    <div class="panel">
      <h1>{{ originPanelTitle }} <span class="counter">{{ originList.length }}</span></h1>
      <div class="search">
        <input type="text" placeholder="Search" v-model="originSearchText">
        <i class="far fa-search"></i>
      </div>
      <div class="list" :style="{ flexBasis: height + 'px' }">
        <div v-if="originSearchText.length > 0" class="search-result">Showing results for "<strong>{{ originSearchText }}</strong>"</div>

        <div
          v-for="item of originList"
          :key="item.value"
          :class="{ selected: originSelection.includes(item) }"
          @click="originSelect(item)"
          class="list-item"
        >
          <span v-if="$scopedSlots.body === undefined">{{ item.label }}</span>
          <slot v-else name="body" v-bind="item"></slot>
        </div>
        <h1 v-if="originList.length === 0" class="no-data">No items available</h1>
      </div>
    </div>

    <div class="controls">
      <button @click="moveSelectedForward" :disabled="originSelection.length === 0">
        <i class="far fa-chevron-right"></i>
      </button>
      <button @click="moveAllForward" :disabled="originList.length === 0">
        <i class="far fa-chevrons-right"></i>
      </button>
      <button @click="moveSelectedBackward" :disabled="destinationSelection.length === 0">
        <i class="far fa-chevron-left"></i>
      </button>
      <button @click="moveAllBackward" :disabled="selectionList.length === 0">
        <i class="far fa-chevrons-left"></i>
      </button>
    </div>

    <div class="panel">
      <h1>{{ destinationPanelTitle }} <span class="counter">{{ destinationList.length }}</span></h1>
      <div class="search">
        <input type="text" placeholder="Search" v-model="destinationSearchText">
        <i class="far fa-search"></i>
      </div>
      <div class="list" :style="{ flexBasis: height + 'px' }">
        <div v-if="destinationSearchText.length > 0" class="search-result">Showing results for "<strong>{{ destinationSearchText }}</strong>"</div>

        <div
          v-for="item of destinationList"
          :key="item.value"
          :class="{ selected: destinationSelection.includes(item) }"
          @click="destinationSelect(item)"
          class="list-item"
        >
          <span v-if="$scopedSlots.body === undefined">{{ item.label }}</span>
          <slot v-else name="body" v-bind="item"></slot>
        </div>
        <h1 v-if="destinationList.length === 0" class="no-data">No items selected</h1>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'Picklist',

  props: {
    originPanelTitle: {
      type: String,
      default: 'Available'
    },
    destinationPanelTitle: {
      type: String,
      default: 'Selected'
    },
    list: {
      type: Array,
      default: () => []
    },
    height: null,
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    originSelect (item) {
      const index = this.originSelection.indexOf(item)

      if (index !== -1) {
        this.originSelection.splice(index, 1)
      } else {
        this.originSelection.push(item)
      }
    },

    destinationSelect (item) {
      const index = this.destinationSelection.indexOf(item)

      if (index !== -1) {
        this.destinationSelection.splice(index, 1)
      } else {
        this.destinationSelection.push(item)
      }
    },

    moveSelectedForward () {
      this.emitAdd(this.originSelection)

      this.selectionList.push(...this.originSelection)

      this.emitInput()
    },

    moveAllForward () {
      this.$confirm.require({
        message: `Are you sure you want to move these ${this.originList.length} items to the selected panel?`,
        header: 'Select All',
        icon: 'fas fa-comment-question',

        accept: () => {
          this.emitAdd(this.originList)
          this.selectionList.push(...this.originList)
          this.emitInput()
        }
      })
    },

    moveSelectedBackward () {
      this.emitRemove(this.destinationSelection)

      this.selectionList = this.selectionList.filter(item => {
        return !this.destinationSelection.includes(item)
      })

      this.emitInput()
    },

    moveAllBackward () {
      this.$confirm.require({
        message: `Are you sure you want to move these ${this.selectionList.length} items to the unselected panel?`,
        header: 'Diselect All',
        icon: 'fas fa-comment-question',

        accept: () => {
          this.emitRemove(this.selectionList)
          this.selectionList = []
          this.emitInput()
        }
      })
    },

    emitInput () {
      this.$emit('input', this.selectionList)

      this.originSelection = []
      this.destinationSelection = []
    },

    emitAdd (items) {
      this.$emit('add-items', items)
    },

    emitRemove (items) {
      this.$emit('remove-items', items)
    }
  },

  computed: {
    originList () {
      const result = this.list.filter(item => {
        return !this.selectionList.map(i => i.value).includes(item.value)
      })

      if (this.originSearchText.length > 0) {
        return result.filter(item => {
          return Object.values(item).some(i => String(i).toLowerCase().includes(this.originSearchText.toLowerCase()))
        }).sort((a, b) => {
          return a.label.toLowerCase().indexOf(this.originSearchText.toLowerCase()) -
              b.label.toLowerCase().indexOf(this.originSearchText.toLowerCase())
        })
      }

      return _.sortBy(result, 'label')
    },

    destinationList () {
      if (this.destinationSearchText.length > 0) {
        return this.selectionList.filter(item => {
          return Object.values(item).some(i => String(i).toLowerCase().includes(this.destinationSearchText.toLowerCase()))
        }).sort((a, b) => {
          return a.label.toLowerCase().indexOf(this.destinationSearchText.toLowerCase()) -
              b.label.toLowerCase().indexOf(this.destinationSearchText.toLowerCase())
        })
      }

      return _.sortBy(this.selectionList, 'label')
    }
  },

  data () {
    return {
      originSelection: [],
      destinationSelection: [],
      selectionList: this.value,

      originSearchText: '',
      destinationSearchText: ''
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/shared.less';

.picklist {
  display: flex;
  width: 100%;
  overflow: hidden;

  > .panel {
    @border-color: tint(@main-color, 5%);

    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid @border-color;
    border-radius: 10px;
    background-color: fade(contrast(@main-color), 1%);

    > h1 {
      border-bottom: 1px solid @border-color;
      padding: 15px 10px;
      font-size: 15pt;
      opacity: 0.5;

      .counter {
        float: right;
        font-size: 9pt;
        font-weight: 400;
        background-color: fade(@accent-color, 50%);
        padding: 2px 10px;
        border-radius: 10px;
        color: #fff;
      }
    }

    > .search {
      background: @border-color;
      display: flex;
      align-items: center;

      i {
        opacity: 0.6;
        margin-right: 20px;
      }

      input {
        background: transparent;
        border: 1px solid transparent;
        font-size: 12pt;
        padding: 15px 10px;
        outline: none;
        flex: 1;
        color: contrast(@main-color);
      }
    }

    > .list {
      position: relative;
      flex: 1;
      overflow: auto;

      .search-result {
        text-align: center;
        padding: 20px 0;
        opacity: 0.5;
      }

      .list-item {
        padding: 15px 10px;
        cursor: pointer;
        transition: 200ms;

        &:hover {
          background-color: fade(contrast(@main-color), 5%);
        }

        &.selected {
          background-color: fade(@accent-color, 50%);
        }

        &:nth-child(even) {
          background-color: fade(contrast(@main-color), 1%);

          &:hover {
            background-color: fade(contrast(@main-color), 4%);
          }

          &.selected {
            background-color: fade(@accent-color, 30%);
          }
        }
      }

      .no-data {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        white-space: nowrap;
        color: contrast(@main-color);
        opacity: 0.3;
        font-size: 16pt;
      }
    }
  }

  > .controls {
    flex: 0 0 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

    button {
      width: 50px;
      height: 50px;
      background: fade(@accent-color, 70%);
      border: none;
      border-radius: 8px;
      color: contrast(@main-color);
      font-size: 12pt;
      cursor: pointer;
      transition: 200ms;

      &:hover {
        background: @accent-color;
      }

      &:active {
        background: fade(@accent-color, 50%);
      }

      &:disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }

  &.disabled {
    .list-item {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    .controls {
      flex-basis: 20px;

      button {
        display: none;
      }
    }

    h1 {
      opacity: 0.2;
    }
  }

  @media @phone {
    flex-direction: column;

    .controls {
      flex-direction: row;

      button {
        transform: rotate(90deg);
      }
    }

    .no-data {
      text-align: center;
      white-space: nowrap;
    }
  }
}
</style>
