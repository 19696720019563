<template>
  <div class="dropdown-wrapper" :class="{ disabled }">
    <label>{{label}}</label>
    <div class="dropdown">
      <div
        @click.stop="toggleList"
        @keyup.enter.down.space="showList"
        :class="{ disabled, 'force-focus-state': showListFlag }"
        class="dropdown-holder"
        :tabindex="disabled ? -1 : 0"
        ref="holder"
      >{{computedSelectedItem.label}}</div>

      <transition name="dropdown">
        <div v-if="showListFlag" class="dropdown-list" @click.stop tabindex="0" ref="list">
          <div v-if="computedOptions.searchMode !== 'none'" class="dropdown-search-wrapper">
            <input
              v-model="searchText"
              @keyup="search"
              @keyup.down.enter.prevent="setListItemFocus(0)"
              @keyup.esc.prevent="hideListKeepFocus"
              class="dropdown-search"
              type="search"
              placeholder="Search"
              ref="search"
            >
          </div>
          <ul class="dropdown-items" :style="{ maxHeight: `${computedOptions.listMaxHeight}px` }">
            <div v-if="loading" class="loading">
              <i class="fal fa-spinner-third fa-spin"></i>
            </div>
            <div v-else-if="computedList.length === 0" class="no-items">{{computedOptions.noItemsMessage}}</div>
            <li
              v-else
              v-for="(item, index) in computedList"
              :key="item.value"
              :class="{ selected: item.value === computedSelectedItem.value }"
              @click="select(item)"
              @keyup.enter.space="select(item)"
              @keydown.down.prevent="setListItemFocus(index + 1)"
              @keydown.up.prevent="setListItemFocus(index - 1)"
              @keyup.esc="$refs.search.focus()"
              class="dropdown-item"
              tabindex="0"
              ref="item"
            >{{item.label}}</li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',

  props: {
    label: String,

    list: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    options: Object,

    value: Object
  },

  methods: {
    showList (e) {
      e.stopPropagation()

      window.sysadminComponentsEventBus.emit('close')

      this.showListFlag = true

      this.$nextTick().then(() => {
        const { holder, list } = this.$refs
        const y = holder.getBoundingClientRect().y + holder.clientHeight

        if ((y + list.clientHeight) >= window.innerHeight) {
          list.style.top = -(list.clientHeight - 18) + 'px'
          list.style.transformOrigin = 'bottom'
        }

        if (this.computedOptions.searchMode !== 'none') {
          if (!this.$isMobile()) {
            this.$refs.search.focus()
          }
        }

        const selectedItemElement = this.$refs.list.querySelector('.selected')

        if (selectedItemElement !== null) {
          selectedItemElement.scrollIntoView({
            block: 'nearest'
          })
        }
      })

      document.body.addEventListener('click', () => {
        this.hideList()
      }, {
        once: true
      })
    },

    hideList () {
      setTimeout(() => {
        this.searchText = ''
        this.$emit('listClosed')
      }, 300)

      this.showListFlag = false
    },

    hideListKeepFocus () {
      this.hideList()

      this.$refs.holder.focus()
    },

    toggleList (e) {
      if (this.showListFlag) {
        this.hideList(e)
      } else {
        this.showList(e)
      }
    },

    setListItemFocus (index) {
      const itemsCount = this.$refs.item.length

      if (itemsCount === 0) return

      let item = null

      if (index === -1) {
        item = this.$refs.item[itemsCount - 1]
      } else if (index === itemsCount) {
        item = this.$refs.item[0]
      } else {
        item = this.$refs.item[index]
      }

      if (item !== null) {
        item.focus()
        item.scrollIntoView({
          block: 'center',
          behavior: 'instant'
        })
      }
    },

    select (item) {
      this.selectedItem = item

      this.hideListKeepFocus()

      this.$emit('input', item)
      this.$emit('select', item)
    },

    search (e) {
      if (this.computedOptions.searchMode === 'customOnEnter') {
        if (e.key === 'Enter') {
          this.$emit('search', this.searchText)
        }
      } else if (this.computedOptions.searchMode === 'customOnType') {
        this.$emit('search', this.searchText)
      }
    }
  },

  computed: {
    computedSelectedItem () {
      return this.selectedItem ?? {
        label: '',
        value: null
      }
    },

    computedOptions () {
      return {
        listMaxHeight: 211,
        searchMode: 'filter',
        noItemsMessage: 'No items',
        ...this.options ?? {}
      }
    },

    computedList () {
      if (this.computedOptions.searchMode === 'filter') {
        return this.list.filter(i => i.label.toLowerCase().includes(this.searchText.toLowerCase()))
          .sort((a, b) => {
            return a.label.toLowerCase().indexOf(this.searchText.toLowerCase()) -
              b.label.toLowerCase().indexOf(this.searchText.toLowerCase())
          })
      }

      return this.list
    }
  },

  data () {
    return {
      selectedItem: this.value,
      showListFlag: false,
      searchText: ''
    }
  },

  mounted () {
    window.sysadminComponentsEventBus.on('close', () => {
      this.hideList()
    })
  }
}
</script>

<style lang="less" scoped>
@import '../assets/shared.less';

.dropdown-wrapper {
  position: relative;

  &.disabled {
    opacity: 0.5 !important;
    pointer-events: none;
  }

  label {
    color: contrast(@main-color);
    font-family: Rajdhani, sans-serif;
  }

  .dropdown-holder,
  .dropdown-search {
    .base-input;
  }

  .dropdown-holder {
    position: relative;
    cursor: pointer;
    min-height: 46px;
    padding-right: 35px;

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &::after {
      content: '\f078';
      color: #fff;
      font-family: 'Font Awesome 5 Pro';
      font-weight: lighter;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 12pt;
      opacity: .7;
      transition: opacity 200ms;
    }
  }

  .dropdown-search-wrapper {
    position: relative;
    font-size: 12pt;
    margin-bottom: 10px;

    &::before {
      content: '\f002';
      color: #fff;
      font-family: 'Font Awesome 5 Pro';
      font-weight: lighter;
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 12pt;
      opacity: .5;
    }

    .dropdown-search {
      padding-left: 40px;
      font-size: 12pt;
      background: fade(contrast(@main-color), 3%);

      &:focus {
        border-color: transparent;
      }

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }
  }

  .dropdown-list {
    position: absolute;
    z-index: 1;
    background: @main-color;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 3px 1px #0000009e;

    .dropdown-items {
      overflow: auto;

      .loading {
        font-size: 18pt;
        text-align: center;
        padding: 10px 0;

        i {
          animation-duration: 500ms;
        }
      }

      .no-items {
        .locked-element-mixin;
        font-size: 16pt;
        font-weight: 500;
        opacity: 0.2;
        text-align: center;
        padding: 10px 0;
      }

      .dropdown-item {
        .locked-element-mixin;
        padding: 10px 20px;
        margin: 4px 0;
        border-radius: 7px;
        cursor: pointer;
        position: relative;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: '';
          position: absolute;
          width: 5px;
          height: 20px;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 2px;
          background: transparent;
        }

        &:hover {
          background: tint(@main-color, 5%);
        }

        &:active,
        &:focus {
          opacity: 0.7;
          outline: none;

          &:before {
            background: fade(@accent-color, 40%);
          }
        }

        &.selected {
          background: tint(@main-color, 2%);

          &:hover {
            background: tint(@main-color, 5%);
          }

          &:before {
            background: fade(@accent-color, 80%);
          }
        }
      }
    }

  }
}
</style>
