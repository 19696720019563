<template>
  <page :title="pageTitle" backURI=":back" :loading="loading" wide>
    <template #body>
      <data-table :value="orders" @refresh="getOrders">
        <column field="number" header="Order Number" bodyStyle="text-align: center" headerStyle="width: 220px">
          <template #body="slot">
            <router-link v-if="isFindResult" :to="'/third-party-orders/find-orders/order/' + slot.data.id">{{slot.data.number}}</router-link>
            <router-link v-else :to="'/third-party-orders/orders/' + slot.data.id">{{slot.data.number}}</router-link>
          </template>
        </column>
        <column field="processDate" header="Process Date" headerStyle="width: 250px" bodyStyle="text-align: center;" sortable>
          <template #body="slot">
            <span>{{$formatDateTime(slot.data.processDate)}}</span>
          </template>
        </column>
        <column field="qcOperator" header="QC Operator" bodyStyle="text-align: center;" sortable></column>
        <column field="devicesCount" header="Total Devices" bodyStyle="text-align: center;" sortable></column>
        <column field="updatedAt" header="Last Update" headerStyle="width: 250px" sortable>
          <template #body="slot">
            <span>{{$formatDateTime(slot.data.updatedAt)}}</span>
          </template>
        </column>
      </data-table>
    </template>
  </page>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import Page from '@/components/Page.vue'
import gql from 'graphql-tag'
import Column from 'primevue/column'

export default {
  name: 'ThirdPartyOrdersView',

  components: {
    Page,
    DataTable,
    Column
  },

  methods: {
    getLastOrders () {
      this.$apollo.query({
        query: gql`
          query {
            fast {
              orders: getThirdPartyOrders {
                id: _id
                number
                processDate
                qcOperator
                devicesCount
                updatedAt
              }
            }
          }
        `,
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.orders = response.data.fast.orders
        this.loading = false
      })
    },

    getFindOrdersResult () {
      this.$apollo.query({
        query: gql`
          query ($filter: ThirdPartyOrderFilterInput!) {
            fast {
              orders: findThirdPartyOrders(filter: $filter) {
                id: _id
                number
                processDate
                qcOperator
                devicesCount
                updatedAt
              }
            }
          }
        `,
        variables: {
          filter: this.filter
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.orders = response.data.fast.orders
        this.loading = false
      })
    },

    getOrders () {
      if (this.isFindResult) {
        this.getFindOrdersResult()
      } else {
        this.getLastOrders()
      }
    }
  },

  computed: {
    isFindResult () {
      return Object.keys(this.$route.query).length > 0
    },

    pageTitle () {
      return this.isFindResult ? 'Find Third-Party Orders Result' : 'Third-Party Orders'
    },

    filter () {
      return Object.fromEntries(Object.entries(this.$route.query).map(([key, value]) => {
        return [key, value]
      }))
    }
  },

  data () {
    return {
      orders: [],
      loading: true
    }
  },

  mounted () {
    this.getOrders()
  }
}
</script>
