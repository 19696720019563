<template>
  <page title="Users" :loading="loading" wide>
    <template #controls>
      <form-button v-if="$checkPermission('iam.users.delete')" icon="fal fa-trash" :disabled="selection.length === 0" @click="confirmDeletion">Delete Selected Users</form-button>
      <form-button v-if="$checkPermission('iam.users.create')" icon="fal fa-plus" @click="$router.push('/iam/users/create')">Add User</form-button>
    </template>

    <template #body>
      <data-table :value="users" :selection.sync="selection" @refresh="getUsers">
        <column field="employeeId" header="Employee ID" bodyStyle="text-align: center" headerStyle="width: 220px">
          <template #body="slot">
            <router-link :to="'/iam/users/update/' + slot.data.id">{{slot.data.employeeId}}</router-link>
          </template>
        </column>
        <column field="name" header="Name" headerStyle="width: 300px" sortable></column>
        <column field="role" header="Roles" headerStyle="width: 250px" sortable></column>
        <column field="lock.type" header="Status" bodyStyle="text-align: center" headerStyle="width: 150px" sortable>
          <template #body="slot">
            <span class="user-status" :class="userStatus(slot.data.lock)">{{userStatus(slot.data.lock)}}</span>
          </template>
        </column>
      </data-table>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import FormButton from '@/components/FormButton.vue'
import DataTable from '@/components/DataTable.vue'
import Column from 'primevue/column'
import gql from 'graphql-tag'

export default {
  name: 'UsersView',

  components: {
    Page,
    FormButton,
    DataTable,
    Column
  },

  methods: {
    getUsers () {
      this.$apollo.query({
        query: gql`
          query {
            iam {
              users: getUsers(includeSuper: false) {
                id: _id
                employeeId
                roles {
                  name
                }
                name
                email
                lock {
                  type
                }
              }
            }
          }
        `,
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.users = response.data.iam.users
        this.users.forEach(i => (i.role = (i.roles.map(i => i.name).join(', ') ?? '(None)')))

        this.loading = false
      })
    },

    confirmDeletion () {
      this.$confirm.require({
        message: `Are you sure you want to delete ${this.selection.length > 1 ? `these ${this.selection.length} users` : 'this user'}?`,
        header: 'Delete',
        icon: 'fas fa-exclamation-triangle',

        accept: () => {
          this.deleteSelectedUsers()
        }
      })
    },

    deleteSelectedUsers () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($ids: [ID!]!) {
            iam {
              deleteUsers(ids: $ids)
            }
          }
        `,
        variables: {
          ids: this.selection.map(i => i.id)
        }
      }).then(() => {
        this.getUsers()
      })
    },

    userStatus (lock) {
      return lock === null ? 'active' : 'blocked'
    }
  },

  data () {
    return {
      users: [],
      selection: [],
      loading: true
    }
  },

  mounted () {
    this.getUsers()
  }
}
</script>

<style lang="less" scoped>
.user-status {
  background: contrast(@main-color);
  color: contrast(contrast(@main-color));
  padding: 5px 12px;
  border-radius: 3px;
  font-weight: bold;
  text-transform: capitalize;

  &.active {
    background: fade(@success-color, 10%);
    color: @success-color;
  }

  &.blocked {
    background: fade(@danger-color, 10%);
    color: @danger-color;
  }
}
</style>
