<template>
  <page title="Sessions" :loading="loading" wide>
    <template #controls>
      <form-button v-if="$checkPermission('iam.sessions.delete')" icon="fal fa-trash" @click="confirmDeletion" :disabled="selection.length === 0">Delete Selected Sessions</form-button>
    </template >

    <template #body>
      <data-table :value="sessions" :selection.sync="selection">
        <column field="id" header="ID" headerStyle="width: 280px">
          <template #body="slot">
            <div>
              <span>{{slot.data.id}}</span>
              <span v-if="slot.data.id === $store.state.auth.sessionId" class="current-session" title="Current Session"></span>
            </div>
          </template>
        </column>
        <column field="user.employeeId" header="Employee ID" headerStyle="width: 200px" sortable/>
        <column field="appName" header="App" headerStyle="width: 200px" sortable/>
        <column field="ip" header="IP" headerStyle="width: 200px"/>
        <column field="browser" header="Browser" headerStyle="width: 250px" sortable/>
        <column field="location" header="Location" headerStyle="width: 300px"/>
        <column field="expiresAt" header="Expires at" headerStyle="width: 200px">
          <template #body="slot">
            <span>{{$formatDateTime(slot.data.expiresAt)}}</span>
          </template>
        </column>
      </data-table>

      <info-box type="success" :text="infoBoxText"/>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import FormButton from '@/components/FormButton.vue'
import DataTable from '@/components/DataTable.vue'
import InfoBox from '@/components/InfoBox.vue'
import gql from 'graphql-tag'
import Column from 'primevue/column'

export default {
  name: 'SessionsView',

  components: {
    Page,
    FormButton,
    DataTable,
    Column,
    InfoBox
  },

  methods: {
    getSessions () {
      this.$apollo.query({
        query: gql`
          query {
            iam {
              sessions: getSessions {
                id: _id
                user {
                  employeeId
                }
                ip
                os
                browser
                location
                appName
                expiresAt
                createdAt
              }
            }
          }
        `,
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.sessions = response.data.iam.sessions
        this.loading = false
      })
    },

    confirmDeletion () {
      this.$confirm.require({
        message: `Are you sure you want to delete ${this.selection.length > 1 ? `these ${this.selection.length} sessions` : 'this session'}?`,
        header: 'Delete',
        icon: 'fas fa-exclamation-triangle',

        accept: () => {
          this.deleteSelectedSessions()
        }
      })
    },

    deleteSelectedSessions () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($ids: [ID!]!) {
            iam {
              deleteSessions(ids: $ids)
            }
          }
        `,
        variables: {
          ids: this.selection.map(i => i.id)
        }
      }).then(() => {
        this.getSessions()
      }).catch(error => {
        this.infoBoxText = `::error ${error.message}`
      })
    }
  },

  data () {
    return {
      sessions: [],
      selection: [],
      infoBoxText: null,
      loading: true
    }
  },

  mounted () {
    this.getSessions()
  }
}
</script>

<style lang="less" scoped>
.current-session {
  background: @accent-color;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-left: 10px;
}
</style>
