<template>
  <page title="Account Request Form">
    <template #body>
      <div v-if="sent" class="sent">
        <h1>Your form has been successfully submited</h1>
        <p>
          <a href="#" @click.prevent="clear">New Request</a>
        </p>
      </div>

      <div v-else class="form">
        <div class="input-group size-md">
          <text-input v-model="companyName" label="Company Name" autocomplete="disabled"/>
        </div>

        <h1>Contact</h1>
        <div class="input-group size-md">
          <text-input v-model="firstName" label="First Name" autocomplete="disabled"/>
        </div>
        <div class="input-group size-md">
          <text-input v-model="lastName" label="Last Name" autocomplete="disabled"/>
        </div>
        <div class="input-group size-md">
          <text-input v-model="email" label="E-mail" type="email" autocomplete="disabled"/>
        </div>

        <h1>Preferences</h1>
        <div class="input-group size-md">
          <text-input v-model="timeZone" label="Time Zone" autocomplete="disabled"/>
        </div>

        <div class="flags-section">
          <div class="section" v-for="flag in flags" :key="flag.title">
            <h1>{{flag.title}}</h1>

            <div class="input-group" v-for="option in flag.options" :key="option">
              <checkbox :label="option" v-model="flag.selected"/>
            </div>
          </div>
        </div>

        <div class="input-group bottom">
          <form-button @click="send">Send</form-button>
        </div>
      </div>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import TextInput from '@/components/TextInput.vue'
import FormButton from '@/components/FormButton.vue'
import Checkbox from '@/components/Checkbox.vue'

import gql from 'graphql-tag'

export default {
  name: 'FormView',

  components: {
    Page,
    TextInput,
    FormButton,
    Checkbox
  },

  methods: {
    send () {
      const flags = {}

      this.flags.forEach(flag => {
        flags[flag.title] = flag.selected
      })

      this.$apollo.mutate({
        mutation: gql`
          mutation Mutation($data: FormInput!) {
            forms {
              createForm(data: $data) {
                _id
              }
            }
          }
        `,
        variables: {
          data: {
            name: 'Account Request',
            fields: {
              'Company Name': this.companyName,
              'First Name': this.firstName,
              'Last Name': this.lastName,
              'E-mail': this.email,
              'Time Zone': this.timeZone,
              Flags: flags
            }
          }
        }
      }).then(() => {
        this.sent = true
      })
    },

    clear () {
      this.companyName = ''
      this.firstName = ''
      this.lastName = ''
      this.email = ''
      this.timeZone = ''

      this.flags.forEach(i => (i.selected = []))

      this.sent = false
    }
  },

  data () {
    return {
      sent: false,

      companyName: '',
      firstName: '',
      lastName: '',
      email: '',
      timeZone: '',

      flags: [
        {
          title: 'Main',
          selected: [],
          options: [
            'TireView (Demo)',
            'TireView (ATIS)',
            'Dashboard',
            'My Vehicles',
            'TireView',
            'Reports'
          ]
        },
        {
          title: 'Reports',
          selected: [],
          options: [
            'Air Tank History',
            'Hub Temp History',
            'Aux-1 Activation',
            'Consolidated Report',
            'ATIS Activation',
            'Low Voltage',
            'Voltage History',
            'Speed',
            'Tire History',
            'Last Location'
          ]
        },
        {
          title: 'Company Admin',
          selected: [],
          options: [
            'Account Information',
            'Users',
            'Vehicles',
            'Alerts',
            'Billing'
          ]
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
h1 {
  font-size: 16pt;
  margin: 20px 0 10px 0;
  font-weight: normal;
  opacity: 0.7;
  font-family: Rajdhani, sans-serif;
}

.sent {
  text-align: center;
  margin-top: 50px;

  h1 {
    font-size: 18pt;
    margin-bottom: 40px;
  }
}
</style>
