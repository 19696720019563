<template>
  <page :title="form ? form.name : '...'" :loading="loading" backURI=":back">
    <template #body>
      <div class="form">
        <div class="form-row">
          <div class="form-item">
            <div class="label">From</div>
            <div class="value">{{ form.author.name }}</div>
          </div>
          <div class="form-item">
            <dropdown v-model="form.status" @select="setStatus($event.value)" label="Status" :options="{searchMode: 'none'}" :list="statusList"></dropdown>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <div class="label">Date</div>
            <div class="value">{{$formatDateTime(form.createdAt)}}</div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <div class="label">Message</div>
            <pre class="message" v-html="prettyJson"></pre>
          </div>
        </div>
      </div>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import Dropdown from '@/components/Dropdown.vue'
import gql from 'graphql-tag'

import { prettyPrintJson } from 'pretty-print-json'

export default {
  name: 'FormDetailView',

  components: {
    Page,
    Dropdown
  },

  methods: {
    getForm () {
      this.$apollo.query({
        query: gql`
          query ($id: ID!) {
            forms {
              form: getForm(id: $id) {
                name
                author {
                  name
                }
                status
                fields
                createdAt
              }
            }
          }
        `,
        variables: {
          id: this.$route.params.id
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.form = response.data.forms.form
        this.form.status = {
          label: response.data.forms.form.status,
          value: response.data.forms.form.status
        }
        this.loading = false
      })
    },

    setStatus (status) {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($id: ID!, $data: FormInput!) {
            forms {
              updateForm(id: $id, data: $data) {
                _id
              }
            }
          }
        `,
        variables: {
          id: this.$route.params.id,
          data: {
            status
          }
        }
      })
    }
  },

  computed: {
    prettyJson () {
      return prettyPrintJson.toHtml(this.form.fields, {
        indent: 4,
        quoteKeys: true
      })
    }
  },

  data () {
    return {
      form: null,
      loading: true,
      statusList: [
        {
          label: 'UNREAD',
          value: 'UNREAD'
        },
        {
          label: 'READED',
          value: 'READED'
        },
        {
          label: 'IN_PROGRESS',
          value: 'IN_PROGRESS'
        },
        {
          label: 'FINISHED',
          value: 'FINISHED'
        }
      ]
    }
  },

  mounted () {
    this.getForm()
  }
}
</script>

<style lang="less" scoped>
.message {
  border: 2px solid fade(contrast(@main-color), 10%);
  padding: 12px;
  margin-top: 5px;
  border-radius: 10px;
  font-size: 12pt;
  overflow: auto;

  /deep/ .json-key {
    color: @accent-color;
    margin-top: 10px;
    display: inline-block;
  }

  /deep/ .json-mark {
    opacity: 0.5;
  }
}
</style>
