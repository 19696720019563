<template>
  <div style="background-color: #fff">
    <center>
      <h2>TIREVIEW - F.A.S.T</h2>
      <h2 class="no-sep" v-if="order.replacementOrder">REPLACEMENT ORDER #{{order.number}}</h2>
      <h2 class="no-sep" v-else>ORDER #{{order.number}}</h2>
    </center>

    <div>
      <table>
        <tbody>
          <tr>
            <th>Order number</th>
            <th>Items</th>
            <td rowspan="6">
              <img :src="qrcodeImage">
            </td>
          </tr>
          <tr>
            <td>#{{order.number}}</td>
            <td>{{order.items.length}}</td>
          </tr>
          <tr>
            <th>Client</th>
            <th>Devices</th>
          </tr>
          <tr>
            <td>#{{order.account.value}} - {{order.account.label}}</td>
            <td>{{totalDevices}}</td>
          </tr>
          <tr>
            <th width="70%">System</th>
            <th width="15%">Sensors</th>
          </tr>
          <tr>
            <td>{{order.account.systemName}}</td>
            <td>{{totalSensors}}</td>
          </tr>
        </tbody>
      </table>

      <h3>ITEMS</h3>
      <table>
        <thead>
          <tr>
            <th width="50">#</th>
            <th width="80">ID</th>
            <th>Vehicle Type</th>
            <th>Locations</th>
            <th width="15%">Quantity</th>
            <th width="15%">Sensors</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) of order.items" :key="item.id">
            <th>{{index + 1}}</th>
            <td>{{item.batchSettings.id}}</td>
            <td>{{item.batchSettings.name}}</td>
            <td>{{item.locations.length > 0 ? item.locations.map(i => i.name).join(', ') : '(None)'}}</td>
            <td>{{item.quantity}}</td>
            <td>{{item.vehicle.sensors}}</td>
          </tr>
        </tbody>
      </table>

      <div class="issued">Issued at {{currentTime}} by {{this.order.issuedBy}}</div>
    </div>
  </div>
</template>

<script>
import qrcode from 'qrcode'

export default {
  name: 'OrderDetailsPrintView',

  methods: {
    getOrder () {
      this.order = JSON.parse(sessionStorage.getItem('order-generator'))
    }
  },

  computed: {
    totalDevices () {
      return this.order.items.reduce((a, b) => a + b.quantity, 0)
    },

    totalSensors () {
      return this.order.items.reduce((a, b) => a + b.vehicle.sensors, 0)
    },

    currentTime () {
      return new Date().toLocaleString()
    },

    qrcodeText () {
      const items = this.order.items?.map(i => [i.batchSettings.id, i.quantity, i.locations.map(j => j.id)].join(','))
        .join('#')
      const params = [
        this.order.number,
        this.order.account?.systemId,
        this.order.account?.value,
        items
      ]

      return '>>>' + params.join('|')
    }
  },

  watch: {
    async qrcodeText () {
      this.qrcodeImage = await qrcode.toDataURL(this.qrcodeText, {
        errorCorrectionLevel: 'M'
      })
    }
  },

  data () {
    return {
      order: {},
      qrcodeImage: ''
    }
  },

  mounted () {
    this.getOrder()

    setTimeout(() => {
      window.print()
    }, 5000)
  }
}
</script>

<style lang="less" scoped>
h2 {
  padding: 10px 0;
  border-bottom: 1px solid #000;

  &.no-sep {
    border: none;
  }
}

h3 {
  margin-top: 20px;
}

table {
  width: 100%;
  border: 1px solid #000;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #000;
    padding: 5px;
    text-align: center;
  }

  th {
    background-color: #eee;
    -webkit-print-color-adjust: exact !important;

    &.darken-bg {
      background-color: #ccc;
    }
  }

  tbody {
    border: none;
  }
}

.issued {
  padding-top: 30px;
  opacity: 0.7;
  font-style: italic;
  font-size: 10pt;
}
</style>
