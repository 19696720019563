import _ from 'lodash'

export function formatDate (date) {
  return new Date(date).toLocaleDateString()
}

export function formatDateTime (date) {
  return new Date(date).toLocaleString()
}

export function formatUTCDate (date) {
  return new Date(date).toLocaleDateString(undefined, { timeZone: 'UTC' })
}

export function formatUTCDateTime (date) {
  return new Date(date).toLocaleString(undefined, { timeZone: 'UTC' })
}

export function isMobile () {
  return window.matchMedia('only screen and (max-width: 767px)').matches
}

export function moveFocusToFirstElement (container = window.document) {
  if (container) {
    container.querySelector('[tabindex]:not(.disabled), [tabindex]:not(:disabled), input:not([type="checkbox"]):not(:disabled)').focus()
  }
}

export function mapListItems (items, options = {}) {
  const extendResult = (result) => {
    result.fromValue = function (value) {
      if ((typeof value === 'object') && value?.value !== undefined) {
        return this.find(i => i.value === value.value)
      }

      return this.find(i => i.value === value)
    }

    return result
  }

  if (typeof options === 'function') {
    return extendResult(items.map(options))
  }

  let _options = {}

  if (Array.isArray(options)) {
    _options = {
      labelKey: options[0],
      valueKey: options[1],
      capitalize: true
    }
  } else if (typeof options === 'string') {
    _options = {
      labelKey: options,
      valueKey: options,
      capitalize: true
    }
  } else {
    _options = {
      labelKey: 'label',
      valueKey: 'value',
      capitalize: true,
      ...options
    }
  }

  return extendResult(items.map(item => {
    if (item instanceof Object) {
      return {
        ...item,
        label: _.get(item, _options.labelKey),
        value: _.get(item, _options.valueKey)
      }
    }

    return {
      label: _options.capitalize
        ? _.capitalize(item)
        : item,
      value: item
    }
  }))
}
