<template>
  <page :title="pageTitle" backURI="/billing/accounts" :loading="loading">
    <template v-if="isEditing" #controls>
      <form-button v-if="$checkPermission('billing.accounts.delete')" icon="fal fa-trash" @click="confirmDeletion">Delete Billing Account</form-button>
    </template>

    <template #body>
      <div class="form">
        <div class="input-group size-md">
          <dropdown
            v-model="account.account"
            :list="accountList"
            :loading="loadingAccountList"
            :options="{ searchMode: 'customOnType' }"
            label="Account"
            @search="searchAccount"
            @listClosed="accountList = []"
          />
        </div>

        <div class="input-group size-md">
          <text-input label="System" :value="systemName" disabled/>
        </div>

        <div class="input-group size-md">
          <dropdown
            v-model="account.currency"
            :list="currencyList"
            label="Currency"
          />
        </div>

        <div class="input-group">
          <div class="label">Data Plan</div>
          <div class="data-plans-container">
            <div class="data-plan-section">
              <div class="data-plan-section-title first">
                Billable
                <button v-if="account.rates.normal.length > 1" class="sort-btn" :class="{ active: rateSortMode }" @click="rateSortMode = !rateSortMode">
                  <i class="fas fa-sort-alt"></i>
                </button>
              </div>
              <draggable
                v-model="account.rates.normal"
                draggable=".data-plan-item"
                handle=".grip"
                animation="500"
                class="data-plans-list"
                :class="{'sort-mode': rateSortMode}"
              >
                <transition-group name="show-down">
                  <div v-for="rate in account.rates.normal" :key="rate.id" class="data-plan-item">
                    <div class="data-plan-actions">
                      <button v-if="!rateSortMode" class="icon-btn remove-rate-btn" @click="removeBillableRate(rate.id)">
                        <i class="fal fa-minus-circle"></i>
                      </button>

                      <button v-else class="icon-btn grip">
                        <i class="fal fa-grip-lines"></i>
                      </button>
                    </div>
                    <div>
                      <div class="input-group">
                        <text-input v-if="!rate.allTargets" label="Description" v-model="rate.description"/>
                      </div>

                      <div class="input-group size-sm">
                        <text-input label="Rate" :suffix="account.currency.value" type="number" class="rate" v-model.number="rate.rate"/>
                      </div>

                      <div class="billing-cycle-group rate-billing-cycle" :class="{ active: rate.cycle.cycle.value === 'prepaid' }">
                        <div class="input-group size-sm size-sm-full">
                          <dropdown
                            v-model="rate.cycle.cycle"
                            :list="billingCycleList"
                            :options="{ searchMode: 'none' }"
                            label="Billing Cycle"
                          />
                        </div>

                        <template v-if="rate.cycle.cycle.value === 'prepaid'">
                          <div class="input-group size-sm">
                            <text-input label="Period" suffix="months" type="number" v-model.number="rate.cycle.period"/>
                          </div>

                          <div class="input-group size-sm">
                            <text-input label="Effect date" type="date" v-model="rate.cycle.effectDate"/>
                          </div>

                          <div class="input-group">
                            <checkbox label="Auto Renew" v-model="rate.cycle.autoRenew"/>
                          </div>

                          <div class="input-group" v-if="!rate.cycle.autoRenew">
                            <dropdown
                              v-model="rate.cycle.onExpires"
                              :list="expirationActionsList"
                              :options="{ searchMode: 'none' }"
                              label="On Expiration"
                            />
                          </div>
                        </template>
                      </div>

                      <div v-if="!rate.allTargets" class="data-plan-targets-container">
                        <div class="data-plan-targets-title">Targets</div>
                        <div class="data-plan-target-item">
                          <input-list label="Vehicle Batch Settings" :list="rate.cycle.cycle.value === 'prepaid' ? vehicleBatchSettings : computedVehicleBatchSettings" v-model="rate.targets.vehicleTypes"/>
                          <input-list label="Vehicle Definitions" :list="rate.cycle.cycle.value === 'prepaid' ? vehicleGraphicList : computedVehicleGraphicList" v-model="rate.targets.vehicleDefinitions"/>
                          <input-list label="Devices" :list="computedDeviceList" v-model="rate.targets.devices"/>
                        </div>
                      </div>
                      <transition name="show-down">
                        <checkbox v-if="account.rates.normal.length === 1" label="All devices" class="all-devices" v-model="rate.allTargets"/>
                      </transition>
                    </div>
                  </div>
                </transition-group>
              </draggable>

              <div v-if="account.rates.normal.length === 0" class="no-items">No Billable items added yet</div>

              <div v-if="!firstBillable.allTargets && !rateSortMode" class="data-plan-footer">
                <button class="icon-btn" @click="addBillableRate" :disabled="!account.account">
                  <i class="fal fa-plus-circle"></i>
                </button>
              </div>
            </div>

            <div class="data-plan-section">
              <div class="data-plan-section-title">Customer Satisfaction</div>
              <div class="data-plans-list">
                <div class="data-plan-item">
                  <div>
                    <div class="data-plan-targets-container">
                      <div class="data-plan-target-item">
                        <input-list label="Devices" :list="computedDeviceList" v-model="account.rates.customerSatisfaction[0].targets.devices"/>
                      </div>
                    </div>
                    <div class="input-group expires-container">
                      <checkbox label="Expires" v-model="account.rates.customerSatisfaction[0].cycle.expires"/>
                    </div>
                    <div v-if="account.rates.customerSatisfaction[0].cycle.expires" class="expiration-options">
                      <div class="input-group size-sm-md">
                        <text-input label="Expires on" type="month" v-model="account.rates.customerSatisfaction[0].cycle.dueDate"/>
                      </div>
                      <div class="input-group">
                        <dropdown
                          v-model="account.rates.customerSatisfaction[0].cycle.onExpires"
                          :list="expirationActionsList"
                          :options="{ searchMode: 'none' }"
                          label="On Expiration"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-plan-section">
              <div class="data-plan-section-title">Research & Development</div>
              <div class="data-plans-list">
                <div class="data-plan-item last">
                  <div>
                    <div class="data-plan-targets-container">
                      <div class="data-plan-target-item">
                        <input-list label="Devices" :list="computedDeviceList" v-model="account.rates.researchAndDevelopment[0].targets.devices"/>
                      </div>
                    </div>
                    <div class="input-group expires-container">
                      <checkbox label="Expires" v-model="account.rates.researchAndDevelopment[0].cycle.expires"/>
                    </div>
                    <div v-if="account.rates.researchAndDevelopment[0].cycle.expires" class="expiration-options">
                      <div class="input-group size-sm-md">
                        <text-input label="Expires on" type="month" v-model="account.rates.researchAndDevelopment[0].cycle.dueDate"/>
                      </div>
                      <div class="input-group">
                        <dropdown
                          v-model="account.rates.researchAndDevelopment[0].cycle.onExpires"
                          :list="expirationActionsList"
                          :options="{ searchMode: 'none' }"
                          label="On Expiration"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-plan-section">
              <div class="data-plan-section-title">In Pause</div>
              <div class="data-plans-list">
                <div class="data-plan-item last">
                  <div>
                    <div class="data-plan-targets-container">
                      <div class="data-plan-target-item">
                        <input-list label="Devices" :list="deviceList" v-model="account.rates.pause[0].targets.devices"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <info-box type="success" :text="infoBoxText"/>

        <div class="input-group bottom">
          <form-button v-if="showSaveBtn" @click="save">Save</form-button>
        </div>
      </div>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import FormButton from '@/components/FormButton.vue'
import TextInput from '@/components/TextInput.vue'
import InfoBox from '@/components/InfoBox.vue'
import Dropdown from '@/components/Dropdown.vue'
import InputList from '@/components/InputList.vue'
import Checkbox from '@/components/Checkbox.vue'

import draggable from 'vuedraggable'

import gql from 'graphql-tag'

export default {
  name: 'BillingAccountsEditorView',

  components: {
    Page,
    FormButton,
    TextInput,
    InfoBox,
    Dropdown,
    InputList,
    Checkbox,
    draggable
  },

  methods: {
    getAccount () {
      this.$apollo.query({
        query: gql`
          query ($id: ID!) {
            billing {
              account: getAccount(id: $id) {
                id: _id
                account {
                  id
                  name
                  value: id
                  label: name
                  system {
                    id
                    name
                  }
                }
                currency
                rates {
                  customerSatisfaction {
                    id: _id
                    cycle {
                      dueDate
                      onExpires
                    }
                    targets {
                      vehicleTypes {
                        value: id
                        label: name
                      }
                      vehicleDefinitions {
                        value: id
                        label: name
                      }
                      devices {
                        value: id
                        label: name
                      }
                    }
                  }
                  researchAndDevelopment {
                    id: _id
                    cycle {
                      dueDate
                      onExpires
                    }
                    targets {
                      vehicleTypes {
                        value: id
                        label: name
                      }
                      vehicleDefinitions {
                        value: id
                        label: name
                      }
                      devices {
                        value: id
                        label: name
                      }
                    }
                  }
                  pause {
                    id: _id
                    targets {
                      vehicleTypes {
                        value: id
                        label: name
                      }
                      vehicleDefinitions {
                        value: id
                        label: name
                      }
                      devices {
                        value: id
                        label: name
                      }
                    }
                  }
                  normal {
                    id: _id
                    allTargets
                    cycle {
                      cycle
                      period
                      effectDate
                      autoRenew
                      onExpires
                    }
                    description
                    rate
                    targets {
                      vehicleTypes {
                        value: id
                        label: name
                      }
                      vehicleDefinitions {
                        value: id
                        label: name
                      }
                      devices {
                        value: id
                        label: name
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          id: this.$route.params.id
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const {
          id,
          account,
          currency,
          rates
        } = response.data.billing.account

        this.account = {
          id,
          account,
          currency: this.currencyList.find(i => i.value === currency),
          rates
        }

        this.account.rates.normal = this.account.rates.normal.map(item => {
          return {
            ...item,
            cycle: {
              ...item.cycle,
              cycle: this.billingCycleList.find(i => i.value === item.cycle?.cycle),
              effectDate: item.cycle?.effectDate?.substring(0, 10) ?? null,
              onExpires: this.expirationActionsList.find(i => i.value === item.cycle?.onExpires)
            }
          }
        })

        this.account.rates.customerSatisfaction = this.account.rates.customerSatisfaction.map(item => {
          return {
            ...item,
            cycle: {
              ...item.cycle,
              expires: item.cycle?.dueDate !== null,
              dueDate: item.cycle?.dueDate?.substring(0, 7) ?? null,
              onExpires: this.expirationActionsList.find(i => i.value === item.cycle?.onExpires)
            }
          }
        })

        this.account.rates.researchAndDevelopment = this.account.rates.researchAndDevelopment.map(item => {
          return {
            ...item,
            cycle: {
              ...item.cycle,
              expires: item.cycle?.dueDate !== null,
              dueDate: item.cycle?.dueDate?.substring(0, 7) ?? null,
              onExpires: this.expirationActionsList.find(i => i.value === item.cycle?.onExpires)
            }
          }
        })

        this.loading = false

        this.$moveFocusToFirstElement()
      })
    },

    createAccount () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($data: BillingAccountInput!) {
            billing {
              createAccount(data: $data) {
                _id
              }
            }
          }
        `,
        variables: {
          data: this.normalizedAccount
        }
      }).then(() => {
        this.$router.push('/billing/accounts')
      }).catch(error => {
        this.infoBoxText = `::error ${error.message}`
      })
    },

    updateAccount (e) {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($id: ID!, $data: BillingAccountInput!) {
            billing {
              updateAccount(id: $id, data: $data) {
                _id
              }
            }
          }
        `,
        variables: {
          id: this.account.id,
          data: this.normalizedAccount
        }
      }).then(() => {
        if (e.shiftKey) {
          this.infoBoxText = '::success Sucessfully saved!'
        } else {
          this.$router.push('/billing/accounts')
        }
      }).catch(error => {
        this.infoBoxText = `::error ${error.message}`
      })
    },

    confirmDeletion () {
      this.$confirm.require({
        message: 'Are you sure you want to delete this billing account?',
        header: 'Delete',
        icon: 'fas fa-exclamation-triangle',

        accept: () => {
          this.deleteAccount()
        }
      })
    },

    deleteAccount () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($ids: [ID!]!) {
            billing {
              deleteAccounts(ids: $ids)
            }
          }
        `,
        variables: {
          ids: [this.account.id]
        }
      }).then(() => {
        this.$router.push('/billing/accounts')
      }).catch(error => {
        this.infoBoxText = `::error ${error.message}`
      })
    },

    save (e) {
      if (this.isEditing) {
        this.updateAccount(e)
      } else {
        this.createAccount(e)
      }
    },

    searchAccount (searchText) {
      if (searchText.length < 3) {
        this.loadingAccountList = false
        this.accountList = []

        return
      }

      this.loadingAccountList = true

      this.$apollo.query({
        query: gql`
          query ($searchText: String!) {
            platform {
              accounts: searchAccount (searchText: $searchText) {
                id,
                name,
                system {
                  id
                  name
                }
                value: id
                label: name
              }
            }
          }
        `,
        variables: {
          searchText
        }
      }).then(response => {
        this.accountList = response.data.platform.accounts
      }).finally(() => {
        this.loadingAccountList = false
      })
    },

    addBillableRate () {
      this.account.rates.normal.push({
        id: Math.random(),
        allTargets: this.account.rates.normal.length === 0,
        cycle: {
          cycle: {
            value: 'monthly',
            label: 'Monthly'
          },
          period: 12,
          effectDate: '2020-01-01',
          onExpires: {
            value: 'remove',
            label: 'Remove from this data plan'
          }
        },
        description: '',
        rate: 10,
        targets: {
          vehicleTypes: [],
          vehicleDefinitions: [],
          devices: []
        }
      })
    },

    removeBillableRate (id) {
      this.account.rates.normal = this.account.rates.normal.filter(i => i.id !== id)
    }
  },

  computed: {
    isEditing () {
      return !!this.$route.params.id
    },

    pageTitle () {
      return this.isEditing ? 'Edit Billing Account' : 'Create Billing Account'
    },

    showSaveBtn () {
      if (this.isEditing) {
        return this.$checkPermission('billing.accounts.update')
      } else {
        return this.$checkPermission('billing.accounts.create')
      }
    },

    computedVehicleBatchSettings () {
      const selected = []

      Object.values(this.account.rates ?? []).forEach(rateType => {
        if (Array.isArray(rateType)) {
          rateType.forEach(rate => {
            (rate.targets?.vehicleTypes ?? []).forEach(target => {
              selected.push(target.value)
            })
          })
        }
      })

      return this.vehicleBatchSettings.filter(i => !selected.includes(i.value))
    },

    computedVehicleGraphicList () {
      const selected = []

      Object.values(this.account.rates ?? []).forEach(rateType => {
        if (Array.isArray(rateType)) {
          rateType.forEach(rate => {
            (rate.targets?.vehicleDefinitions ?? []).forEach(target => {
              selected.push(target.value)
            })
          })
        }
      })

      return this.vehicleGraphicList.filter(i => !selected.includes(i.value))
    },

    computedDeviceList () {
      const selected = []

      Object.values(this.account.rates ?? []).forEach(rateType => {
        if (Array.isArray(rateType)) {
          rateType.forEach(rate => {
            (rate.targets?.devices ?? []).forEach(target => {
              selected.push(target.value)
            })
          })
        }
      })

      return this.deviceList.filter(i => !selected.includes(i.value))
    },

    normalizedAccount () {
      const customerSatisfaction = this.account.rates.customerSatisfaction[0]
      const researchAndDevelopment = this.account.rates.researchAndDevelopment[0]

      return {
        account: {
          id: this.account.account.id,
          name: this.account.account.name,
          system: {
            id: this.account.account.system.id,
            name: this.account.account.system.name
          }
        },
        currency: this.account.currency.value,
        rates: {
          customerSatisfaction: [
            {
              cycle: {
                dueDate: customerSatisfaction.cycle?.expires
                  ? customerSatisfaction.cycle?.dueDate
                  : undefined,
                onExpires: customerSatisfaction.cycle?.expires
                  ? customerSatisfaction.cycle?.onExpires?.value
                  : undefined
              },
              targets: {
                devices: customerSatisfaction.targets.devices
                  .map(({ value: id, label: name }) => ({ id, name }))
              }
            }
          ],
          researchAndDevelopment: [
            {
              cycle: {
                dueDate: researchAndDevelopment.cycle?.expires
                  ? researchAndDevelopment.cycle?.dueDate
                  : undefined,
                onExpires: researchAndDevelopment.cycle?.expires
                  ? researchAndDevelopment.cycle?.onExpires?.value
                  : undefined
              },
              targets: {
                devices: researchAndDevelopment.targets.devices
                  .map(({ value: id, label: name }) => ({ id, name }))
              }
            }
          ],
          pause: [
            {
              targets: {
                devices: this.account.rates.pause[0].targets.devices
                  .map(({ value: id, label: name }) => ({ id, name }))
              }
            }
          ],
          normal: this.account.rates.normal.map(item => {
            const targets = {}

            Object.entries(item.targets ?? {}).forEach(([key, value]) => {
              if (Array.isArray(value) && value.length > 0) {
                targets[key] = value.map(({ value: id, label: name }) => ({ id, name }))
              }
            })

            const common = {
              rate: item.rate,
              cycle: {
                cycle: item.cycle.cycle.value,
                period: item.cycle.period,
                effectDate: item.cycle.effectDate,
                autoRenew: item.cycle.autoRenew ?? false,
                onExpires: !item.cycle.autoRenew
                  ? item.cycle.onExpires?.value
                  : undefined
              }
            }

            if (item.cycle.cycle.value === 'monthly') {
              delete common.cycle.period
              delete common.cycle.effectDate
              delete common.cycle.autoRenew
              delete common.cycle.onExpires
            }

            if (item.allTargets) {
              return {
                ...common,
                allTargets: item.allTargets
              }
            } else {
              return {
                ...common,
                description: item.description,
                targets
              }
            }
          })
        }
      }
    },

    firstBillable () {
      return this.account.rates.normal[0] ?? {}
    },

    systemName () {
      return this.account?.account?.system.name ?? ''
    }
  },

  watch: {
    'account.account' () {
      this.$apollo.query({
        query: gql`
          query ($accountId: Int!, $systemId: ID!) {
            platform {
              account: getAccount(accountId: $accountId, systemId: $systemId) {
                batchSettings {
                  value: id
                  label: description
                }
                devices {
                  value: id
                  label: name
                }
              }

              system: getSystem(systemId: $systemId) {
                vehicleLayouts {
                  value: id
                  label: description
                }
              }
            }
          }
        `,
        variables: {
          accountId: this.account.account.id,
          systemId: this.account.account.system.id
        }
      }).then(response => {
        this.vehicleBatchSettings = response.data.platform.account.batchSettings
        this.vehicleGraphicList = response.data.platform.system.vehicleLayouts
        this.deviceList = response.data.platform.account.devices
      })
    }
  },

  data () {
    return {
      account: {
        account: null,
        currency: {
          value: 'USD',
          label: 'USD — US Dollar'
        },
        rates: {
          normal: [],
          customerSatisfaction: [
            {
              id: Math.random(),
              cycle: {
                expires: false,
                dueDate: null,
                onExpires: {
                  value: 'remove',
                  label: 'Remove from this data plan'
                }
              },
              targets: {
                devices: []
              }
            }
          ],
          researchAndDevelopment: [
            {
              id: Math.random(),
              cycle: {
                expires: false,
                dueDate: null,
                onExpires: {
                  value: 'remove',
                  label: 'Remove from this data plan'
                }
              },
              targets: {
                devices: []
              }
            }
          ],
          pause: [
            {
              id: Math.random(),
              targets: {
                devices: []
              }
            }
          ]
        }
      },

      loadingAccountList: false,
      accountList: [],
      currencyList: [
        {
          value: 'USD',
          label: 'USD — US Dollar'
        },
        {
          value: 'EUR',
          label: 'EUR — Euro'
        },
        {
          value: 'AUD',
          label: 'AUD — Autralian Dollar'
        },
        {
          value: 'CAD',
          label: 'CAD — Canadian Dollar'
        },
        {
          value: 'ILS',
          label: 'ILS — Israeli New Shekel'
        }
      ],
      billingCycleList: [
        {
          value: 'monthly',
          label: 'Monthly'
        },
        {
          value: 'prepaid',
          label: 'Prepaid'
        }
      ],
      expirationActionsList: [
        {
          value: 'remove',
          label: 'Remove from this data plan'
        },
        {
          value: 'disable',
          label: 'Disable devices'
        }
      ],
      vehicleBatchSettings: [],
      vehicleGraphicList: [],
      deviceList: [],

      rateSortMode: false,

      infoBoxText: null,
      loading: true
    }
  },

  mounted () {
    if (this.isEditing) {
      this.getAccount()
    } else {
      this.loading = false
      this.$moveFocusToFirstElement()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/shared.less';

.label {
  font-family: Rajdhani, sans-serif;
}

.billing-cycle-group {
  border: 1px solid transparent;
  border-radius: 10px;
  transition: all 500ms;

  @media @phone {
    .never-expires-checkbox {
      margin-top: 10px;
    }
  }

  &.active {
    padding: 15px;
    border-color: fade(contrast(@main-color), 5%);
    margin-bottom: 10px;

    .input-group:last-child {
      margin-bottom: 0 !important;
    }

    &.rate-billing-cycle {
      margin-top: 20px;
    }
  }
}

.data-plans-container {
  background: fade(contrast(@main-color), 1%);
  padding: 20px;
  border-radius: 10px;

  .no-items {
    text-align: center;
    margin-bottom: 20px;
    font-size: 15pt;
    opacity: 0.2;
  }

  .all-devices {
    display: block;
    margin-top: 10px;
  }

  .rate {
    max-width: 200px;
  }

  .data-plan-section-title {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 14pt;
    font-weight: 100;

    .sort-btn {
      background: fade(contrast(@main-color), 5%);
      float: right;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      border: none;
      color: @accent-color;
      cursor: pointer;

      &.active {
        background: @accent-color;
        color: contrast(@accent-color);
      }

      &:hover {
        opacity: 0.8;
      }

      &:active {
        opacity: 0.7;
      }
    }

    &.first {
      margin-top: 0;
    }
  }

  .data-plan-targets-title {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .data-plans-list {
    .data-plan-item {
      display: flex;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px dotted fade(contrast(@main-color), 10%);
      height: 100%;

      &.last {
        border-bottom: none;
      }

      &:focus-within {
        .data-plan-actions:before {
          background-color: shade(@accent-color, 50%);
        }
      }

      > div {
        flex: 1;
      }

      .data-plan-actions {
        flex: 0;
        margin-right: 10px;
        display: flex;
        align-items: center;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          background: fade(contrast(@main-color), 10%);
          width: 10px;
          height: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 10px;
        }
      }

      .expires-container {
        margin-top: 10px;
      }

      .expiration-options {
        padding-left: 35px;
      }
    }

    &.sort-mode {
      .data-plan-item {
        height: 109px;
        overflow: hidden;
        mask-image: -webkit-gradient(linear, left top, left bottom, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
        user-select: none;

        .data-plan-actions {
          &:before {
            visibility: hidden;
          }
        }
      }
    }
  }

  .icon-btn {
    color: @accent-color;
    font-size: 18pt;
    cursor: pointer;
    border: none;
    background: transparent;
    width: 30px;
    height: 30px;
    background-color: @main-color;
    z-index: 1;
    border-radius: 100%;
    padding: 2px;

    &.grip {
      background: transparent;
      color: fade(contrast(@main-color), 50%);
      cursor: ns-resize;

      &:active {
        opacity: 0.7 !important;
        color: inherit
      }
    }

    &:active {
      color: darken(@accent-color, 20%);
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
      cursor: default;
    }
  }

  .data-plan-footer {
    text-align: center;
  }
}
</style>
