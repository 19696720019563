<template>
  <page title="Find Orders">

    <template #body>
      <div class="tabs">
        <button v-if="$checkPermission('fast.ui.find-orders.basic')" :class="{ selected: tab === 'basic' }" @click="selectTab('basic')">Basic</button>
        <button v-if="$checkPermission('fast.ui.find-orders.advanced')" :class="{ selected: tab === 'advanced' }" @click="selectTab('advanced')">Advanced</button>
      </div>

      <div class="basic-search" v-if="tab === 'basic'">
        <div class="form">
          <div class="input-group size-sm">
            <text-input label="Order Number" v-model="filter.number" @keyup.enter="findOrders" @input="inputChanged('number')"/>
          </div>
        </div>
      </div>

      <div class="advanced-search" v-if="tab === 'advanced'">
        <div class="form">
          <div class="input-group size-md">
            <text-input label="Order ID" v-model="filter.orderId" @keyup.enter="findOrders" @input="inputChanged('orderId')"/>
          </div>
        </div>

        <div class="preposition">or...</div>

        <div class="form">
          <div class="input-group size-sm">
            <text-input label="Order Number" v-model="filter.number" @keyup.enter="findOrders" @input="inputChanged('number')"/>
          </div>
        </div>

        <div class="preposition">or...</div>

        <div class="form">
          <div class="input-group size-md">
            <text-input type="number" label="Device ID" v-model="filter.deviceId" @keyup.enter="findOrders" @input="inputChanged('deviceId')"/>
          </div>
        </div>

        <div class="preposition">or...</div>

        <div class="form">
          <div class="input-group size-sm">
            <text-input label="Sensor ID" v-model="filter.sensorId" @keyup.enter="findOrders" @input="inputChanged('sensorId')"/>
          </div>
        </div>

        <div class="preposition">or...</div>

        <div class="group">
          <div class="form">
            <div class="input-group size-sm">
              <text-input type="number" label="Employee ID" v-model="filter.employeeId" @keyup.enter="findOrders" @input="inputChanged('employeeId')"/>
            </div>

            <div class="preposition">and/or...</div>

            <div class="input-group size-sm">
              <text-input type="number" label="Account ID" v-model="filter.clientId" @keyup.enter="findOrders" @input="inputChanged('clientId')"/>
            </div>

            <div class="preposition">and/or...</div>

            <div class="input-group date-between">
              <text-input type="date" label="Date between" v-model="filter.dateFrom" @keyup.enter="findOrders" @input="inputChanged('dateFrom')"/>
              <span>and</span>
              <text-input type="date" v-model="filter.dateTo" @keyup.enter="findOrders" @input="inputChanged('dateTo')"/>
            </div>
          </div>
        </div>
      </div>

      <div class="controls">
        <form-button @click="findOrders">Find</form-button>
      </div>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import TextInput from '@/components/TextInput.vue'
import FormButton from '@/components/FormButton.vue'

export default {
  name: 'FindOrdersView',

  components: {
    Page,
    TextInput,
    FormButton
  },

  methods: {
    inputChanged (origin) {
      const filterClearExcept = fieldName => {
        Object.keys(this.filter).forEach(field => {
          if (Array.isArray(fieldName)) {
            if (!fieldName.includes(field)) {
              this.filter[field] = null
            }
          } else {
            if (field !== fieldName) {
              this.filter[field] = null
            }
          }
        })
      }

      if (['employeeId', 'clientId', 'dateFrom', 'dateTo'].includes(origin)) {
        filterClearExcept(['employeeId', 'clientId', 'dateFrom', 'dateTo'])
      } else {
        filterClearExcept(origin)
      }
    },

    findOrders () {
      this.$store.dispatch('SET_FIND_ORDERS_FILTER', this.filter)

      this.$router.push({
        path: '/fast/find-orders/result',
        query: this.queryParams
      })
    },

    selectTab (name) {
      this.tab = name

      this.setFocus()
    },

    setFocus () {
      this.$nextTick().then(() => {
        const els = this.$el.querySelectorAll('input:not(:disabled)')
        const el = Array.from(els).find(i => i.value.length > 0)

        if (!this.$isMobile()) {
          els[0].focus()

          if (el !== undefined) {
            el.focus()
          }
        }
      })
    }
  },

  computed: {
    queryParams () {
      return Object.fromEntries(Object.entries(this.filter).filter(i => (i[1] !== '' && i[1] !== null)))
    }
  },

  data () {
    return {
      tab: this.$checkPermission('fast.ui.find-orders.basic') ? 'basic' : 'advanced',
      filter: {
        orderId: null,
        number: null,
        deviceId: null,
        sensorId: null,
        employeeId: null,
        clientId: null,
        dateFrom: null,
        dateTo: null
      }
    }
  },

  mounted () {
    this.setFocus()

    this.filter = this.$store.state.findOrdersFilter
  }
}
</script>

<style lang="less" scoped>
.tabs {
  margin-bottom: 25px;

  button {
    border: 1px solid @accent-color;
    margin-right: 20px;
    padding: 5px 20px;
    border-radius: 15px;
    background: transparent;
    color: @accent-color;
    cursor: pointer;

    &:active {
      opacity: 0.8;
    }

    &.selected {
      background: @accent-color;
      color: contrast(contrast(@accent-color));
    }
  }
}

.group {
  border: 1px solid fade(contrast(@main-color), 10%);
  border-radius: 10px;
  padding: 20px;

  .input-group:last-child {
    margin-bottom: 0 !important;
  }

  .date-between {
    .text-input {
      display: inline-block;
      width: 200px;
    }

    span {
      margin: 0 10px;
    }
  }
}

.preposition {
  font-size: 15pt;
  margin: 10px 5px;
  opacity: 0.7;
}

.controls {
  margin-top: 40px;
  text-align: right;

  .form-button {
    width: 150px;
  }
}
</style>
