import mitt from 'mitt'

import App from './App.vue'
import PortalVue from 'portal-vue'
import PrimeVue from 'primevue/config'
import Vue from 'vue'
import apolloProvider from './apollo'
import router from './router'
import store from './store'
import ConfirmationService from 'primevue/confirmationservice'
import plugins from './plugins'
import pusher from './pusher'

Vue.config.productionTip = false

Vue.use(PortalVue)
Vue.use(PrimeVue)
Vue.use(ConfirmationService)
Vue.use(plugins)
Vue.use(pusher)

window.sysadminComponentsEventBus = mitt()

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
