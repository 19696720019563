<template>
  <div class="administration">
  </div>
</template>

<script>
export default {
  name: 'HomeView',

  mounted () {
    const redirPages = [
      {
        page: '/fast/orders',
        permission: 'fast.ui.last-orders'
      },
      {
        page: '/fast/find-orders',
        permission: 'fast.ui.find-orders.*'
      },
      {
        page: '/fast/order-generator',
        permission: 'fast.ui.order-generator'
      },
      {
        page: '/orders/third-party-orders',
        permission: 'orders.third-party-orders.list'
      },
      {
        page: '/iam/users',
        permission: [
          'iam.*',
          'iam.users.*'
        ]
      },
      {
        page: '/iam/roles',
        permission: 'iam.roles.*'
      },
      {
        page: '/iam/sessions',
        permission: 'iam.sessions.*'
      },
      {
        page: '/billing/data-plans',
        permission: 'billing.dataPlans.*'
      },
      {
        page: '/forms',
        permission: 'forms.ui.manager'
      },
      {
        page: '/form/account-request',
        permission: 'forms.ui.form.account-request'
      }
    ]

    let redirectTo = null

    redirPages.forEach(redir => {
      if (Array.isArray(redir.permission)) {
        if (redir.permission.some(i => this.$checkPermission(i))) {
          if (!redirectTo) {
            redirectTo = redir.page
          }
        }
      } else {
        if (this.$checkPermission(redir.permission)) {
          if (!redirectTo) {
            redirectTo = redir.page
          }
        }
      }
    })

    this.$router.replace(redirectTo)
  }
}
</script>
