<template>
  <page title="Billing Settings" :loading="loading" backURI=":back">
    <template #body>
      <div class="section">Automatic Monthly Billing Report</div>
      <div class="form">
        <div class="input-group size-md">
          <div class="input-group">
            <p class="hint">Set a list of e-mails to receive an automatic monthly report with all billing accounts</p>

            <editable-list
              v-model="monthlyReportRecipients"
              type="email"
              label="Recipients"
              placeholder="Type an e-mail"
            />
          </div>

          <info-box :text="infoBoxText"/>

          <div class="input-group bottom">
            <form-button @click="save">Save</form-button>
          </div>
        </div>
      </div>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import EditableList from '@/components/EditableList.vue'
import FormButton from '@/components/FormButton.vue'
import InfoBox from '@/components/InfoBox.vue'

import gql from 'graphql-tag'

export default {
  name: 'BillingConfigsView',

  components: {
    Page,
    EditableList,
    FormButton,
    InfoBox
  },

  methods: {
    getConfigs () {
      this.loading = true

      this.$apollo.query({
        query: gql`
          query {
            billing {
              configs: getConfigs {
                monthlyReportRecipients
              }
            }
          }
        `,
        fetchPolicy: 'no-cache'
      }).then(data => {
        this.monthlyReportRecipients = data.data.billing.configs.monthlyReportRecipients
      }).finally(() => {
        this.loading = false
      })
    },

    save () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($data: BillingConfigInput!) {
            billing {
              setConfigs (data: $data)
            }
          }
        `,
        variables: {
          data: {
            monthlyReportRecipients: this.monthlyReportRecipients
          }
        }
      }).then(() => {
        this.infoBoxText = '::success Preferences successfully saved!'
      })
    }
  },

  data () {
    return {
      infoBoxText: null,
      loading: true,
      monthlyReportRecipients: []
    }
  },

  mounted () {
    this.getConfigs()
  }
}
</script>

<style lang="less" scoped>
.section {
  margin-bottom: 15px;
  color: contrast(@main-color);
  font-family: Rajdhani, sans-serif;
  font-size: 18pt;
}

.hint {
  font-style: italic;
  margin: 10px 0;
  opacity: 0.5;
  font-size: 10pt;
  color: @accent-color;
}
</style>
