<template>
  <div class="full-screen-locked-app">
    <div class="container">
      <div class="icon">
        <i class="fal fa-ban"></i>
      </div>

      <h1>You don't have access to this application</h1>
      <h2>Contact your supervisor in order to get access to this service.</h2>

      <div class="sign-out">
        <a href="#" @click.prevent="logout"><i class="fal fa-sign-out"></i> Sign out</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullScreenLockedApp',

  methods: {
    logout () {
      this.$store.dispatch('UNSET_AUTH').then(() => {
        const url = new URL(process.env.VUE_APP_AUTH_URL)

        url.searchParams.append('app', 'sysadmin')

        location.replace(url)
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../assets/shared.less';

.full-screen-locked-app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, @main-color 0%, darken(@main-color, 90%) 100%);
  user-select: none;

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: contrast(@main-color);

    @media @phone {
      font-size: 8pt;
      width: 90%;

      .icon {
        font-size: 60pt !important;
      }
    }

    .icon {
      font-size: 100pt;
      margin-bottom: 50px;
      opacity: 0.3;
    }

    h2 {
      font-weight: normal;
      opacity: 0.7;
      margin-top: 10px;
      font-size: 12pt;
    }

    .sign-out {
      margin-top: 50px;
      font-size: 14pt;
    }
  }
}
</style>
