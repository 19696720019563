<template>
  <page :title="pageTitle" :backURI="isFindResult ? '/fast/find-orders' : null" :loading="loading" wide>
    <template #body>
      <data-table :value="orders" @refresh="getOrders">
        <column field="number" header="Order Number" bodyStyle="text-align: center" headerStyle="width: 220px">
          <template #body="slot">
            <router-link v-if="isFindResult" :to="'/fast/find-orders/order/' + slot.data.id">{{slot.data.number}}</router-link>
            <router-link v-else :to="'/fast/orders/' + slot.data.id">{{slot.data.number}}</router-link>
          </template>
        </column>
        <column field="client.name" header="Client" headerStyle="width: 200px" sortable></column>
        <column field="status.code" header="Status" bodyStyle="text-align: center" headerStyle="width: 250px" sortable>
          <template #body="slot">
            <span class="status-badge" :class="slot.data.status.code.toLowerCase()">{{slot.data.status.code}}</span>
          </template>
        </column>
        <column field="totalDevices" header="Total Devices" bodyStyle="text-align: center" headerStyle="width: 190px" sortable></column>
        <column field="status.user.name" header="Last Status by" headerStyle="width: 270px" sortable></column>
        <column field="createdAt" header="Processed Date" headerStyle="width: 200px" sortable>
          <template #body="slot">
            <span>{{$formatDateTime(slot.data.createdAt)}}</span>
          </template>
        </column>
      </data-table>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import DataTable from '@/components/DataTable.vue'
import Column from 'primevue/column'
import gql from 'graphql-tag'

export default {
  name: 'UsersView',

  components: {
    Page,
    DataTable,
    Column
  },

  methods: {
    getLastOrders () {
      this.$apollo.query({
        query: gql`
          query {
            fast {
              orders: getOrders {
                id: _id
                number
                status {
                  code
                  user {
                    name
                  }
                }
                system {
                  name
                }
                client {
                  name
                }
                totalDevices
                createdAt
              }
            }
          }
        `,
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.orders = response.data.fast.orders
        this.loading = false
      })
    },

    getFindOrdersResult () {
      this.$apollo.query({
        query: gql`
          query ($filter: FastOrderFilterInput!) {
            fast {
              orders: findOrders(filter: $filter) {
                id: _id
                number
                status {
                  code
                  user {
                    name
                  }
                }
                system {
                  name
                }
                client {
                  name
                }
                totalDevices
                createdAt
              }
            }
          }
        `,
        variables: {
          filter: this.filter
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.orders = response.data.fast.orders
        this.loading = false
      })
    },

    getOrders () {
      if (this.isFindResult) {
        this.getFindOrdersResult()
      } else {
        this.getLastOrders()
      }
    }
  },

  computed: {
    isFindResult () {
      return Object.keys(this.$route.query).length > 0
    },

    pageTitle () {
      return this.isFindResult ? 'Find Orders Result' : 'Orders'
    },

    filter () {
      return Object.fromEntries(Object.entries(this.$route.query).map(([key, value]) => {
        if (key === 'clientId') {
          return [key, Number(value)]
        }

        return [key, value]
      }))
    }
  },

  data () {
    return {
      orders: [],
      loading: true
    }
  },

  mounted () {
    this.getOrders()
  }
}
</script>

<style lang="less" scoped>
.status-badge {
  background: fade(@success-color, 10%);
  color: @success-color;
  padding: 5px 12px;
  border-radius: 3px;
  font-weight: bold;

  &.processed {
    @color: lighten(@success-color, 20%);

    background: fade(@color, 10%);
    color: @color;
  }

  &.replacement_requested {
    @color: lighten(@warning-color, 20%);

    background: fade(@color, 10%);
    color: @color;
  }

  &.failed {
    @color: lighten(@danger-color, 20%);

    background: fade(@color, 10%);
    color: @color;
  }
}
</style>
