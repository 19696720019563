<template>
  <page :title="'Third-Party Order Details' + (loading ? '' : ' #' + order.number)" :loading="loading" ref="page" backURI=":back">
    <template #body>
      <div class="form">
        <div class="form-row">
          <div class="form-item">
            <div class="label">ID</div>
            <div class="value"><copiable-text>{{ order.id }}</copiable-text></div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <div class="label">Order Number</div>
            <div class="value">#<copiable-text>{{ order.number }}</copiable-text></div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-item">
            <div class="label">Total devices</div>
            <div class="value">{{ order.devicesCount }}</div>
          </div>
          <div class="form-item">
            <div class="label">QC Operator</div>
            <div class="value">{{ order.qcOperator }}</div>
          </div>
        </div>
      </div>

      <div class="device-list">
        <div class="device-list-title">Devices</div>

        <div v-for="(devices, type) in devicesGroupedByType" :key="type" class="device-group">
          <div class="device-group-title">
            {{ type }} <span class="bullet">•</span> {{ devices.length }} devices
          </div>

          <div v-for="(device, index) in devices" :key="device._id" class="device-item">
            <div class="head">
              <div class="device-id">#{{ index + 1 }} {{ $formatDateTime(device.processDate) }} <span style="opacity: 0.2"><copiable-text>{{ device._id.substr(-4) }}</copiable-text></span></div>
            </div>

            <div v-if="device.tractor.length > 0" class="vehicle">
              <div class="vehicle-name">Tractor</div>

              <div class="sensors">
                <div class="sensor-list">
                  <div v-for="sensor in device.tractor" :key="sensor.index" class="sensor-item">
                    <div>
                      {{ sensor.index }}  <copiable-text>{{ sensor.id }}</copiable-text>
                    </div>
                  </div>
                </div>
                <div class="device-settings">
                  <div class="settings-title">Settings</div>

                  <div class="input-group">
                    <div class="label">Max Temperature</div>
                    <div class="value">{{ device.settings.maxTemperature }} °F</div>
                  </div>

                  <div v-for="axle in device.settings.axles" :key="axle.index" class="input-row">
                    <div class="input-group">
                      <div class="label">Min Pressure</div>
                      <div class="value">{{ axle.minPressure }} PSI</div>
                    </div>

                    <div class="axle-title">Axle {{ axle.index + 1 }}</div>

                    <div class="input-group">
                      <div class="label">Max Pressure</div>
                      <div class="value">{{ axle.maxPressure }} PSI</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-for="trailer in device.trailers" :key="trailer.index" class="vehicle">
              <div class="vehicle-name">Trailer {{ trailer.index + 1 }}</div>

              <div class="sensors">
                <div class="sensor-list">
                  <div v-for="sensor in trailer.sensors" :key="sensor.index" class="sensor-item">
                    <div>
                      {{ sensor.index }}  <copiable-text>{{ sensor.id }}</copiable-text>
                    </div>
                  </div>
                </div>
                <div class="device-settings">
                  <div class="settings-title">Settings</div>

                  <div class="input-group">
                    <div class="label">Max Temperature</div>
                    <div class="value">{{ device.settings.maxTemperature }} °F</div>
                  </div>
                  <div class="input-group">
                    <div class="label">Min Pressure</div>
                    <div class="value">{{ device.settings.trailers.minPressure }} PSI</div>
                  </div>
                  <div class="input-group">
                    <div class="label">Max Pressure</div>
                    <div class="value">{{ device.settings.trailers.maxPressure }} PSI</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </template>
  </page>
</template>

<script>
import gql from 'graphql-tag'
import _ from 'lodash'

import CopiableText from '@/components/CopiableText.vue'
import Page from '@/components/Page.vue'

export default {
  name: 'OrderDetailsView',

  components: {
    Page,
    CopiableText
  },

  methods: {
    getOrder () {
      this.$apollo.query({
        query: gql`
          query ($id: ID!) {
            fast {
              order: getThirdPartyOrder(id: $id) {
                id: _id
                number
                processDate
                qcOperator
                devicesCount
                devices {
                  _id
                  lot
                  type
                  settings {
                    maxTemperature
                    trailers {
                      minPressure
                      maxPressure
                    }
                    axles {
                      index
                      minPressure
                      maxPressure
                    }
                  }
                  tractor {
                    index
                    id
                  }
                  trailers {
                    index
                    sensors {
                      index
                      id
                    }
                  }
                  processDate
                }
                createdAt
              }
            }
          }
        `,
        variables: {
          id: this.$route.params.id
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.order = response.data.fast.order
        this.loading = false
      })
    }
  },

  computed: {
    devicesGroupedByType () {
      return _(this.order?.devices ?? [])
        .sortBy('type')
        .groupBy('type')
        .value()
    }
  },

  data () {
    return {
      order: {},
      loading: true
    }
  },

  mounted () {
    setTimeout(() => {
      this.getOrder()
    }, 1000)
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../../assets/shared.less';

.id {
  opacity: 0.5;
  margin-left: 10px;
  font-size: 12pt;
}

.device-list {
  position: relative;

  .device-list-title {
    font-size: 15pt;
    opacity: 0.5;
    font-weight: 100;
  }

  .device-group {
    margin-bottom: 40px;

    .device-group-title {
      font-size: 16pt;
      font-weight: 800;
      opacity: 0.8;
      color: @accent-color;
      margin-bottom: 10px;

      @media @phone {
        font-size: 14pt;
      }

      .bullet {
        margin: 0 10px;

        @media @phone {
          height: 0;
          display: block;
        }
      }
    }

    .device-item {
      padding-left: 15px;
      padding-bottom: 15px;
      margin-left: 10px;
      margin-top: -7px;
      border-left: 2px solid shade(contrast(@main-color), 80%);
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        top: 7px;
        left: -9px;
        background: tint(contrast(contrast(@main-color)), 6%);
        border: 3px solid shade(@accent-color, 50%);
        border-radius: 50%;
      }

      &:last-child {
        padding-bottom: 0;
        border-left-color: transparent
      }

      .head {
        display: flex;

        .device-id {
          font-family: Consolas, monospace;
          font-size: 18pt;
          flex: 1;
        }
      }

      .vehicle-name {
        font-size: 15pt;
        margin: 30px 0;
        font-weight: bold;
        opacity: 0.6;
        text-align: center;
        text-transform: uppercase;
      }

      .sensors {
        display: flex;
        gap: 15px;

        > div {
          flex: 1;
        }

        @media @phone {
          width: 100%;
        }

        .sensor-item {
          display: flex;
          border-radius: 7px;
          cursor: default;
          border: 2px solid transparent;
          text-align: center;
          flex-wrap: wrap;
          background: fade(contrast(@main-color), 1%);
          margin-bottom: 5px;

          &:nth-child(even) {
            background: fade(contrast(@main-color), 2%);
          }

          &:hover {
            background: fade(contrast(@main-color), 4%);
            border-color: fade(contrast(@main-color), 10%);
          }

          > div {
            flex: 1;
            font-family: Consolas, monospace;
            font-size: 16pt;
            padding: 5px;
          }
        }
      }

      .settings-title {
        text-align: center;
        opacity: 0.5;
        font-size: 15pt;
        font-weight: 300;
        margin-bottom: 15px;
      }

      .input-group {
        margin-bottom: 10px;
        text-align: center;

        .label {
          opacity: 0.5;
        }
      }

      .input-row {
        display: flex;
        align-items: center;

        .axle-title {
          text-align: center;
          font-weight: bold;
          opacity: 0.3;
          font-size: 11pt;
        }

        > div {
          flex: 1;
        }
      }
    }
  }
}
</style>
