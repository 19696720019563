<template>
  <div class="change-password">
    <h1>CHANGE PASSWORD</h1>

    <info-box class="info" type="info2" text="Your password should have at least 6 digits and must not include sequential numbers"/>

    <div class="form">
      <div class="form-item">
        <text-input label="Current password" type="password" v-model="currentPassword" ref="password" :invalid="error"/>
      </div>
      <div class="form-item">
        <text-input label="New password" type="password" v-model="newPassword"/>
        <i class="fal fa-check-circle" :class="{show: validNewPassoword}"></i>
      </div>
      <div class="form-item">
        <text-input label="Confirm password" type="password" v-model="confirmPassword" @keyup.enter="changePassword"/>
        <i class="fal fa-check-circle" :class="{show: validConfirmPassword}"></i>
      </div>

      <info-box class="success" type="success" :text="success"/>

      <form-button class="change-password-btn" :disabled="!validNewPassoword || !validConfirmPassword" @click="changePassword">OK</form-button>
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue'
import FormButton from '@/components/FormButton.vue'
import InfoBox from '@/components/InfoBox.vue'
import gql from 'graphql-tag'

export default {
  name: 'ChangePassword',

  components: {
    TextInput,
    FormButton,
    InfoBox
  },

  methods: {
    changePassword () {
      if (!this.validNewPassoword || !this.validConfirmPassword) return

      this.$apollo.mutate({
        mutation: gql`
          mutation ($password: String!, $newPassword: String!) {
            iam {
              changePassword (password: $password, newPassword: $newPassword)
            }
          }
        `,
        variables: {
          password: this.currentPassword,
          newPassword: this.newPassword
        }
      }).then(() => {
        this.success = 'Password successfully changed'
        this.error = null
      }).catch(error => {
        this.success = null
        this.error = error.message
      }).finally(() => {
        this.currentPassword = ''
        this.newPassword = ''
        this.confirmPassword = ''

        this.passwordFocus()
      })
    },

    passwordFocus () {
      if (!this.$isMobile()) {
        this.$refs.password.focus()
      }
    }
  },

  computed: {
    validNewPassoword () {
      return this.newPassword.length >= 6
    },

    validConfirmPassword () {
      return this.newPassword.length > 0 && this.newPassword === this.confirmPassword
    }
  },

  data () {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      error: null,
      success: null
    }
  },

  mounted () {
    this.passwordFocus()
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../assets/shared.less';

.change-password {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;

  @media @phone {
    top: 70px;
    left: 0;
    transform: none;
    width: 100%;
    padding: 0 20px;
  }

  h1 {
    font-family: Rajdhani, sans-serif;
    color: contrast(@main-color);
    margin-bottom: 30px;
  }

  .info {
    margin-bottom: 30px;
  }

  .success {
    margin: 20px 0;
  }

  .form-item {
    /deep/ .text-input {
      margin-bottom: 8px;
    }

    i {
      float: right;
      font-size: 20pt;
      clear: both;
      margin: -43px -40px 0 0;
      color: @success-color;
      opacity: 0;
      transition: opacity 1s;

      @media @phone {
        margin: -40px 50px 0 0;
        font-size: 15pt;
      }

      &.show {
        opacity: 1;
      }
    }
  }

  .change-password-btn {
    margin-top: 30px;
  }
}
</style>
