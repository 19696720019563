<template>
  <page title="Inbox" :loading="loading" wide>
    <template #controls>
      <switch-button :icon="$notificationsIcon" v-model="isNotificationEnabled" @change="$toggleNotifications"/>
      <form-button v-if="$checkPermission('forms.delete')" icon="fal fa-trash" @click="confirmDeletion" :disabled="selected.length === 0">Delete Selected Forms</form-button>
    </template>

    <template #body>
      <data-table :value="forms" @refresh="getForms" :selection.sync="selected">
        <column field="id" header="ID" bodyStyle="text-align: center" headerStyle="width: 120px">
          <template #body="slot">
            <router-link :to="'/forms/' + slot.data.id">{{getDecimalId(slot.data.id)}}</router-link>
          </template>
        </column>
        <column field="name" header="Form" bodyStyle="text-align: center" headerStyle="width: 220px" sortable></column>
        <column field="author.name" header="From" headerStyle="width: 300px" sortable></column>
        <column field="createdAt" header="Date" headerStyle="width: 250px" sortable>
          <template #body="slot">
            {{$formatDateTime(slot.data.createdAt)}}
          </template>
        </column>
        <column field="status" header="Status" bodyStyle="text-align: center" headerStyle="width: 150px" sortable></column>
      </data-table>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import FormButton from '@/components/FormButton.vue'
import SwitchButton from '@/components/SwitchButton.vue'
import DataTable from '@/components/DataTable.vue'
import Column from 'primevue/column'

import gql from 'graphql-tag'

export default {
  name: 'InboxView',

  notificationsChannel: 'forms',

  components: {
    DataTable,
    Page,
    Column,
    FormButton,
    SwitchButton
  },

  methods: {
    getForms () {
      this.$apollo.query({
        query: gql`
          query {
            forms {
              forms: getForms {
                id: _id
                name
                author {
                  name
                }
                status
                createdAt
              }
            }
          }
        `,
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.loading = false
        this.forms = response.data.forms.forms
      })
    },

    getDecimalId (id) {
      return parseInt(id.substr(-4), 16)
    },

    confirmDeletion () {
      this.$confirm.require({
        message: `Are you sure you want to delete ${this.selected.length > 1 ? `these ${this.selected.length} forms` : 'this form'}?`,
        header: 'Delete',
        icon: 'fas fa-exclamation-triangle',

        accept: () => {
          this.deleteSelectedForms()
        }
      })
    },

    deleteSelectedForms () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($ids: [ID!]!) {
            forms {
              deleteForms(ids: $ids)
            }
          }
        `,
        variables: {
          ids: this.selected.map(i => i.id)
        }
      }).then(() => {
        this.getForms()
      })
    }
  },

  data () {
    return {
      loading: true,
      forms: [],
      selected: []
    }
  },

  mounted () {
    this.getForms()
  }
}
</script>
