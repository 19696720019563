<template>
  <page title="Locations" backURI=":back" :loading="loading">
    <template #body>
      <h1 class="title">Locations of <strong>{{ accountDevice.device.name }}</strong> in <strong>{{ accountDevice.account.name }}</strong></h1>
      <picklist
        :list="locationsList"
        @add-items="addLocations"
        @remove-items="removeLocations"
        :height="500"
        v-model="selectedLocations"
      >
      </picklist>
    </template>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import Picklist from '@/components/Picklist.vue'

import { tireviewliveApi } from '@/lib/tireviewlive-api'

export default {
  name: 'LocationDeviceView',

  components: {
    Page,
    Picklist
  },

  methods: {
    async addLocations (items) {
      await tireviewliveApi.post(`/sysadmin/account-device/${this.accountDeviceId}/attach-locations`, {
        locations: items.map(i => i.value)
      })
    },

    async removeLocations (items) {
      await tireviewliveApi.delete(`/sysadmin/account-device/${this.accountDeviceId}/dettach-locations`, {
        locations: items.map(i => i.value)
      })
    }
  },

  watch: {
    '$store.state.workingAccount': function () {
      this.$router.replace('/platform2/devices')
    }
  },

  computed: {
    accountDeviceId () {
      return this.$route.params.accountDeviceId
    }
  },

  data () {
    return {
      accountDevice: {},
      locationsList: [],
      selectedLocations: [],
      loading: true

    }
  },

  async mounted () {
    this.accountDevice = await tireviewliveApi.get(`/sysadmin/account-device/${this.accountDeviceId}`, {
      include: 'account,device,locations'
    })

    this.locationsList = await tireviewliveApi.get('/sysadmin/locations', {
      accountId: this.accountDevice.accountId
    }).then(response => {
      this.selectedLocations = this.$mapListItems(this.accountDevice.locations, item => {
        const location = response.find(i => i.id === item.locationAccountDevice.locationId)

        return {
          ...location,
          label: location.name,
          value: item.id
        }
      })

      return this.$mapListItems(response, ['name', 'id'])
    })

    this.loading = false
  }
}
</script>

<style lang="less">
.title {
  opacity: 0.6;
  margin-bottom: 20px;
  font-family: Rajdhani, sans-serif;
  font-weight: 500;
}
</style>
