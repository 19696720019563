import Vue from 'vue'
import VueRouter from 'vue-router'
import ChangePasswordView from '../views/ChangePasswordView.vue'
import HomeView from '../views/HomeView.vue'
import AccountEditorView from '../views/platform2/AccountEditorView.vue'
import AccountUserEditorView from '../views/platform2/AccountUserEditorView.vue'
import AccountsView from '../views/platform2/AccountsView.vue'
import DeviceEditorView from '../views/platform2/DeviceEditorView.vue'
import DevicesView from '../views/platform2/DevicesView.vue'
import LocationDeviceView from '../views/platform2/LocationDeviceView.vue'
import LocationEditorView from '../views/platform2/LocationEditorView.vue'
import LocationsView from '../views/platform2/LocationsView.vue'
import AccountManagementRolesEditorView from '../views/platform2/RoleEditorView.vue'
import AccountManagementRolesView from '../views/platform2/RolesView.vue'
import AccountManagementSessionsView from '../views/platform2/SessionsView.vue'
import SyncView from '../views/platform2/SyncView.vue'
import BanListView from '../views/platform2/BanListView.vue'
import AccountManagementUserEditorView from '../views/platform2/UserEditorView.vue'
import AccountManagementUsersView from '../views/platform2/UsersView.vue'
import BillingAccountsEditorView from '../views/billing/BillingAccountsEditorView.vue'
import BillingAccountsView from '../views/billing/BillingAccountsView.vue'
import BillingConfigsView from '../views/billing/BillingConfigsView.vue'
import BillingReportDetailView from '../views/billing/BillingReportDetailView.vue'
import BillingReportView from '../views/billing/BillingReportView.vue'
import FindOrdersView from '../views/fast/FindOrdersView.vue'
import OrderDetailsPrintView from '../views/fast/OrderDetailsPrintView.vue'
import OrderDetailsView from '../views/fast/OrderDetailsView.vue'
import OrderGeneratorPrintView from '../views/fast/OrderGeneratorPrintView.vue'
import OrderGeneratorView from '../views/fast/OrderGeneratorView.vue'
import OrdersView from '../views/fast/OrdersView.vue'
import AccountRequestFormView from '../views/forms/AccountRequestFormView.vue'
import FormDetailView from '../views/forms/FormDetailView.vue'
import InboxView from '../views/forms/InboxView.vue'
import RoleEditorView from '../views/iam/RoleEditorView.vue'
import RolesView from '../views/iam/RolesView.vue'
import SessionsView from '../views/iam/SessionsView.vue'
import UserEditorView from '../views/iam/UserEditorView.vue'
import UsersView from '../views/iam/UsersView.vue'
import FindThirdPartyOrdersView from '../views/third-party-orders/FindThirdPartyOrdersView'
import ThirdPartyOrderDetailsView from '../views/third-party-orders/ThirdPartyOrderDetailsView'
import ThirdPartyOrdersView from '../views/third-party-orders/ThirdPartyOrdersView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePasswordView
  },
  {
    path: '/fast/orders',
    name: 'Orders',
    component: OrdersView,
    alias: '/fast/find-orders/result'
  },
  {
    path: '/fast/orders/:id',
    name: 'OrderDetails',
    component: OrderDetailsView,
    alias: '/fast/find-orders/order/:id'
  },
  {
    path: '/fast/orders/:id/print',
    name: 'OrderDetailsPrint',
    component: OrderDetailsPrintView,
    meta: {
      frame: false
    }
  },
  {
    path: '/fast/find-orders',
    name: 'FindOrders',
    component: FindOrdersView
  },
  {
    path: '/fast/order-generator',
    name: 'OrderGenerator',
    component: OrderGeneratorView
  },
  {
    path: '/fast/order-generator/print',
    name: 'OrderGeneratorPrint',
    component: OrderGeneratorPrintView,
    meta: {
      frame: false
    }
  },
  {
    path: '/third-party-orders/orders',
    name: 'ThirdPartyOrders',
    component: ThirdPartyOrdersView,
    alias: '/third-party-orders/find-orders/result'
  },
  {
    path: '/third-party-orders/orders/:id',
    name: 'ThirdPartyOrderDetails',
    component: ThirdPartyOrderDetailsView,
    alias: '/third-party-orders/find-orders/order/:id'
  },
  {
    path: '/third-party-orders/find-orders',
    name: 'FindThirdPartyOrders',
    component: FindThirdPartyOrdersView
  },
  {
    path: '/iam/users',
    name: 'Users',
    component: UsersView
  },
  {
    path: '/iam/users/create',
    name: 'CreateUser',
    component: UserEditorView
  },
  {
    path: '/iam/users/update/:id',
    name: 'UpdateUser',
    component: UserEditorView
  },
  {
    path: '/iam/roles',
    name: 'Roles',
    component: RolesView
  },
  {
    path: '/iam/roles/create',
    name: 'CreateRole',
    component: RoleEditorView
  },
  {
    path: '/iam/roles/update/:id',
    name: 'UpdateRole',
    component: RoleEditorView
  },
  {
    path: '/iam/sessions',
    name: 'Sessions',
    component: SessionsView
  },
  {
    path: '/platform2/devices',
    name: 'DevicesView',
    component: DevicesView
  },
  {
    path: '/platform2/devices/update/:deviceId',
    name: 'DeviceEditorView',
    component: DeviceEditorView
  },
  {
    path: '/platform2/devices/create',
    name: 'DeviceEditorCreateView',
    component: DeviceEditorView
  },
  {
    path: '/platform2/devices/locations/:accountDeviceId',
    name: 'LocationDeviceView',
    component: LocationDeviceView
  },
  {
    path: '/platform2/accounts',
    name: 'AccountsView',
    component: AccountsView
  },
  {
    path: '/platform2/accounts/update/:accountId',
    name: 'AccountUpdateView',
    component: AccountEditorView
  },
  {
    path: '/platform2/accounts/create',
    name: 'CreateAccount',
    component: AccountEditorView
  },
  {
    path: '/platform2/users',
    name: 'UsersView',
    component: AccountManagementUsersView
  },
  {
    path: '/platform2/users/update/:userId',
    name: 'UpdatePlatformUser',
    component: AccountManagementUserEditorView
  },
  {
    path: '/platform2/users/create',
    name: 'CreatePlatformUser',
    component: AccountManagementUserEditorView
  },
  {
    path: '/platform2/users/account-user/:accountUserId',
    name: 'AccountUserView',
    component: AccountUserEditorView
  },
  {
    path: '/platform2/locations',
    name: 'LocationsView',
    component: LocationsView
  },
  {
    path: '/platform2/locations/update/:locationId',
    name: 'UpdateLocation',
    component: LocationEditorView
  },
  {
    path: '/platform2/locations/create',
    name: 'CreateLocation',
    component: LocationEditorView
  },
  {
    path: '/platform2/roles',
    name: 'RolesView',
    component: AccountManagementRolesView
  },
  {
    path: '/platform2/roles/update/:roleId',
    name: 'UpdatePlatformRoles',
    component: AccountManagementRolesEditorView
  },
  {
    path: '/platform2/roles/create',
    name: 'CreatePlatformRoles',
    component: AccountManagementRolesEditorView
  },
  {
    path: '/platform2/sessions',
    name: 'SessionsView',
    component: AccountManagementSessionsView
  },
  {
    path: '/platform2/sync',
    name: 'SyncView',
    component: SyncView
  },
  {
    path: '/platform2/ban-list',
    name: 'BanListView',
    component: BanListView
  },
  {
    path: '/forms',
    name: 'InboxView',
    component: InboxView
  },
  {
    path: '/form/account-request',
    name: 'AccountRequestFormView',
    component: AccountRequestFormView
  },
  {
    path: '/forms/:id',
    name: 'FormDetailView',
    component: FormDetailView
  },
  {
    path: '/billing/accounts',
    name: 'BillingAccounts',
    component: BillingAccountsView
  },
  {
    path: '/billing/accounts/create',
    name: 'CreateBillingAccount',
    component: BillingAccountsEditorView
  },
  {
    path: '/billing/accounts/update/:id',
    name: 'UpdateBillingAccount',
    component: BillingAccountsEditorView
  },
  {
    path: '/billing/report',
    name: 'BillingReport',
    component: BillingReportView
  },
  {
    path: '/billing/report/detail/current/:systemId/:accountId/:dueDate',
    name: 'BillingReportDetail',
    component: BillingReportDetailView
  },
  {
    path: '/billing/report/settings',
    name: 'BillingReportSettings',
    component: BillingConfigsView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
