<template>
  <div class="list-box-wrapper" :class="{disabled}">
    <div class="label">{{label}}</div>
    <listbox :disabled="disabled" class="list-box" :class="{'multiple-selection': tableProps.multiple}" v-bind="tableProps" v-on="$listeners" @confirm="$emit('confirm')">
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData"/>
      </template>
    </listbox>
  </div>
</template>

<script>
import Listbox from 'primevue/listbox'

export default {
  components: {
    Listbox
  },

  props: {
    label: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    tableProps () {
      return {
        filter: true,
        filterPlaceholder: 'Search',
        multiple: true,
        ...this.$attrs
      }
    }
  },

  data () {
    return {
      filters: {}
    }
  }
}
</script>

<style lang="less" scoped>
.list-box-wrapper {
  .label {
    color: contrast(@main-color);
    font-family: Rajdhani, sans-serif;
    margin-bottom: 5px;
  }

  /deep/ .list-box {
    @header-color: tint(@main-color, 5%);

    border: 1px solid @header-color;
    border-radius: 8px;
    overflow: hidden;
    background: fade(contrast(@main-color), 1%);

    .p-listbox-header {
      padding: 10px;
      background-color: @header-color;
      border-radius: 8px 8px 0 0;
      border: 1px solid transparent;

      .pi-search {
        font-family: 'Font Awesome 5 Pro';
        right: 10px;
        opacity: 0.6;

        &::before {
          content: '\f002'
        }
      }

      input {
        border: none;
        background: transparent;
        font-size: 12pt;
        padding: 6px;
        color: contrast(@main-color);
        outline: none;
        font-family: inherit;
        width: 100%;
      }
    }

    .p-listbox-list {
      .p-listbox-item {
        position: relative;
        padding: 15px 20px 15px 50px;
        border-bottom: 1px solid fade(@header-color, 50%);
        user-select: none;

        &::before {
          content: '\f111';
          position: absolute;
          font-family: 'Font Awesome 5 Pro';
          left: 22px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 17pt;
          opacity: 0.03;
          transition: all 200ms;
        }

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background: fade(contrast(@main-color), 1%);
        }

        &:focus-visible {
          background: fade(contrast(@main-color), 1%);
          outline: none;
        }

        &.p-highlight::before {
          content: '\f192';
          color: @accent-color;
          opacity: 1;
          font-weight: normal;
        }
      }

      .p-listbox-empty-message {
        text-align: center;
        font-weight: bold;
        font-size: 14pt;
        padding: 20px 0;
        opacity: 0.2;
      }
    }

    &.multiple-selection .p-listbox-item {
      &::before {
        content: '\f0c8';
      }

      &.p-highlight::before {
        content: '\f14a';
        font-weight: bold;
      }
    }
  }

  &.disabled {
    /deep/ .p-listbox-item {
      pointer-events: none;
      opacity: 0.3;
    }
  }
}
</style>
