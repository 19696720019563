<template>
  <div id="app">
    <div v-if="noFrame" class="no-frame">
      <router-view/>
    </div>

    <div v-else-if="status === 'ok'">
      <header-bar/>

      <div class="content">
        <div class="content-wrapper">
          <div class="sidebar" :class="{hide: !visibleMenu}">
            <div class="title">Administration</div>
            <div class="menu">
              <div v-if="$checkPermission('fast.*')" class="menu-section">
                <div class="menu-section-title">F.A.S.T</div>
                <div class="menu-section-content">
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('fast.ui.last-orders')" to="/fast/orders">Last Orders</router-link>
                  </div>
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('fast.ui.find-orders.*')" to="/fast/find-orders">Find Orders</router-link>
                  </div>
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('fast.ui.order-generator')" to="/fast/order-generator">Order Generator</router-link>
                  </div>
                </div>
              </div>

              <div v-if="$checkPermission('third-party-orders.*')" class="menu-section">
                <div class="menu-section-title">Third-Party Orders</div>
                <div class="menu-section-content">
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('third-party-orders.ui.last-orders')" to="/third-party-orders/orders">Last Orders</router-link>
                  </div>
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('third-party-orders.ui.find-orders')" to="/third-party-orders/find-orders">Find Orders</router-link>
                  </div>
                </div>
              </div>

              <div v-if="$checkPermission('platform.*')" class="menu-section">
                <div class="menu-section-title">Platform2 Management</div>
                <div class="menu-section-content">
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('platform.accounts.view')" to="/platform2/accounts">Accounts</router-link>
                  </div>
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('platform.users.view')" to="/platform2/users">Users</router-link>
                  </div>
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('platform.devices.view')" to="/platform2/devices">Devices</router-link>
                  </div>
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('platform.locations.view')" to="/platform2/locations">Locations</router-link>
                  </div>
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('platform.roles.view')" to="/platform2/roles">Roles</router-link>
                  </div>
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('platform.sessions.view')" to="/platform2/sessions">Sessions</router-link>
                  </div>
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('platform.sync.view')" to="/platform2/sync">Sync</router-link>
                  </div>
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('platform.ban-list.view')" to="/platform2/ban-list">Ban List</router-link>
                  </div>
                </div>
              </div>

              <div v-if="$checkPermission('utilities.ui.view')" class="menu-section">
                <div class="menu-section-title">Utilities</div>
                <div class="menu-section-content">
                  <div class="menu-item">
                    <a
                      v-if="$checkPermission('drawing-tool.use')"
                      href="https://drawing-tool.tireviewapps.net"
                      target="_blank">
                      TireGraphic Drawing Tool
                      <i class="far fa-arrow-up-right-from-square"></i>
                    </a>
                  </div>
                  <div class="menu-item">
                    <a
                      v-if="$checkPermission('roi-calculator.use')"
                      href="https://roi-calculator.tireviewapps.net"
                      target="_blank">
                      ROI Calculator
                      <i class="far fa-arrow-up-right-from-square"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div v-if="$checkPermission('forms.*')" class="menu-section">
                <div class="menu-section-title">Forms</div>
                <div class="menu-section-content">
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('forms.ui.manager')" to="/forms">Inbox</router-link>
                  </div>
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('forms.ui.form.account-request')" to="/form/account-request">Account Request Form</router-link>
                  </div>
                </div>
              </div>

              <div v-if="$checkPermission('billing.*')" class="menu-section">
                <div class="menu-section-title" title="Identity and Access Management">Billing</div>
                <div class="menu-section-content">
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('billing.accounts.list')" to="/billing/accounts">Billing Accounts</router-link>
                  </div>
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('billing.ui.report')" to="/billing/report">Report</router-link>
                  </div>
                </div>
              </div>

              <div v-if="$checkPermission('iam.*')" class="menu-section">
                <div class="menu-section-title" title="Identity and Access Management">IAM</div>
                <div class="menu-section-content">
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('iam.users.list')" to="/iam/users">Users</router-link>
                  </div>
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('iam.roles.list')" to="/iam/roles">Roles</router-link>
                  </div>
                  <div class="menu-item">
                    <router-link v-if="$checkPermission('iam.sessions.list')" to="/iam/sessions">Sessions</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page-content-wrapper">
            <transition name="page-transition">
              <router-view/>
            </transition>
          </div>
        </div>
      </div>
    </div>

    <full-screen-loading v-if="status === 'loading'"/>
    <full-screen-locked-app v-if="status === 'access-denied'"/>

    <confirm-dialog></confirm-dialog>

    <message-box></message-box>
  </div>
</template>

<script>
import '@/assets/animations.css'

import FullScreenLoading from '@/components/FullScreenLoading.vue'
import FullScreenLockedApp from '@/components/FullScreenLockedApp.vue'
import HeaderBar from '@/components/HeaderBar.vue'
import MessageBox from '@/components/MessageBox.vue'

import ConfirmDialog from 'primevue/confirmdialog'

import gql from 'graphql-tag'

import { initPusherClient } from './pusher'

export default {
  name: 'App',

  components: {
    HeaderBar,
    FullScreenLoading,
    FullScreenLockedApp,
    ConfirmDialog,
    MessageBox
  },

  methods: {
    setReady (status = 'ok') {
      setTimeout(() => {
        this.status = status
      }, 3000)
    },

    redirectToLogin () {
      const url = new URL(process.env.VUE_APP_AUTH_URL)

      url.searchParams.append('app', 'sysadmin')
      url.searchParams.append('return_uri', this.$route.path)

      location.replace(url)
    }
  },

  computed: {
    visibleMenu () {
      return this.$store.state.menuVisibility
    },

    noFrame () {
      return this.$route.meta.frame === false
    }
  },

  data () {
    return {
      status: 'loading'
    }
  },

  mounted () {
    const authToken = this.$route.query.token

    let mustReload = false

    if (authToken) {
      localStorage.setItem('token', authToken)

      this.$router.replace({
        query: {
          token: undefined
        }
      })

      mustReload = true
    }

    const token = localStorage.getItem('token')

    if (token) {
      this.$apollo.mutate({
        mutation: gql`
          mutation {
            auth {
              token: renewToken {
                hash
              }
            }
          }
        `,
        fetchPolicy: 'no-cache'
      }).then(response => {
        this.$store.dispatch('SET_AUTH', response.data.auth.token.hash)
      }).catch(() => {
        this.$store.dispatch('UNSET_AUTH')

        this.$nextTick().then(() => {
          this.redirectToLogin()
        })
      }).finally(() => {
        const allowedPermissions = [
          'fast.*',
          'iam.*',
          'billing.*',
          'forms.*',
          'platform.*'
        ]

        if (allowedPermissions.some(i => this.$checkPermission(i))) {
          this.setReady()
        } else {
          this.setReady('access-denied')
        }

        initPusherClient()

        if (mustReload) {
          location.reload()
        }
      })
    } else {
      this.redirectToLogin()
    }

    document.documentElement.addEventListener('click', () => {
      this.$store.dispatch('SET_MENU_VISIBILITY', false)
    })
  }
}
</script>

<style lang="less">
@import './assets/shared.less';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  font-family: Roboto, sans-serif;
  width: 100%;
  height: 100%;
  background: @main-color;
  color: contrast(@main-color);
  overscroll-behavior: none;
  -webkit-tap-highlight-color: transparent;
  touch-action: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 0;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: none;
}
::-webkit-scrollbar-thumb {
  background: fade(contrast(@main-color), 5%);
}
::-webkit-scrollbar-thumb:hover {
  background: fade(contrast(@main-color), 20%);
}

@media @phone {
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    display: none;
  }
}

a {
  text-decoration: none;
  color: @accent-color;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
}

a.editor-link {
  display: inline-block;
  background-color: fade(#fff, 8%);
  font-weight: 500;
  padding: 2px 5px;
  min-width: 120px;
  border-radius: 5px;
  transition: 200ms;
  position: relative;

  &:hover {
    text-decoration: none;
    background-color: @accent-color;
    color: #fff;

    &::after {
      transform: translateX(10px);
      opacity: 1;
    }
  }

  &:active {
    opacity: 0.5;
  }

  &::after {
    content: '\f303';
    position: absolute;
    font-family: 'Font Awesome 6 Pro';
    font-weight: 900;
    margin-left: 15px;
    opacity: 0;
    transition: 200ms;
    color: #fff;
  }
}

.no-frame {
  color: #000;
  background: #fff;
}

.btn {
  border: 2px solid transparent;
  padding: 8px 20px;
  font-family: Roboto, sans-serif;
  font-size: 12pt;
  background-color: lighten(@main-color, 10%);
  color: contrast(@main-color);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 200ms;
  user-select: none;

  &:focus {
    border-color: fade(@accent-color, 50%);
  }

  &:hover {
    background-color: lighten(@main-color, 15%);
  }

  &:active {
    background-color: darken(@main-color, 3%);
  }

  &:disabled {
    opacity: 0.3;
    background-color: tint(@main-color, 5%);
    cursor: default;
  }

  &.primary {
    background-color: @accent-color;
    color: contrast(@accent-color);

    &:hover {
      background-color: lighten(@accent-color, 15%);
    }

    &:active {
      background-color: darken(@accent-color, 3%);
    }

    &:disabled {
      opacity: 0.3;
      background-color: tint(@accent-color, 5%);
      cursor: default;
    }
  }
}

.id-tag {
  font-size: 0.8em;
  background: fade(contrast(@main-color), 20%);
  color: contrast(@main-color);
  padding: 2px 5px;
  border-radius: 5px;
  margin-right: 10px;
  vertical-align: middle;

  &.float-right {
    float: right;
  }

  &.order {
    display: inline-block;
    min-width: 45px;
    text-align: center;
  }
}

.system-tag {
  float: right;
  font-size: 0.8em;
  background: cadetblue;
  color: contrast(cadetblue);
  padding: 2px 5px;
  border-radius: 5px;

  &.default {
    background: #940000;
  }

  &.goodyear {
    background: #0000b7;
  }
}

.p-confirm-dialog.p-dialog {
  background: lighten(@main-color, 5%);
  border-radius: 15px;
  border: 3px solid shade(@accent-color, 40%);
  overflow: hidden;
  color: contrast(@main-color);
  box-shadow: 1px 1px 20px 0px #0000008a;
  cursor: default;
  user-select: none;
  max-width: 80%;

  .p-dialog-header {
    padding: 15px 20px;
    color: @accent-color;
    font-size: 18pt;
    border-bottom: 1px solid fade(contrast(@main-color), 5%);
    font-family: Rajdhani, sans-serif;
    font-weight: bold;

    .p-dialog-header-close {
      display: none;
    }
  }

  .p-dialog-content {
    padding: 15px 20px;

    > i {
      font-size: 25pt;
      margin-right: 20px;
      opacity: 0.3;
    }
  }

  .p-dialog-footer {
    padding: 15px 20px;
    border-top: 1px solid fade(contrast(@main-color), 5%);
    text-align: right;

    button {
      .btn;
      margin-left: 10px;
    }
  }
}

.resource-status-badge {
  background: contrast(@main-color);
  color: contrast(contrast(@main-color));
  padding: 5px 12px;
  border-radius: 3px;
  font-weight: bold;
  text-transform: capitalize;

  &.active {
    background: fade(@success-color, 10%);
    color: @success-color;
  }

  &.blocked {
    background: fade(@danger-color, 10%);
    color: @danger-color;
  }
}

.section-title {
  font-size: 16pt;
  margin: 20px 0 10px 0;
  font-weight: normal;
  opacity: 0.7;
  font-family: Rajdhani, sans-serif;
}
</style>

<style lang="less">
@import (reference) './assets/shared.less';

.content {
  background-color: tint(contrast(contrast(@main-color)), 6%);
  overflow: hidden;
  position: fixed;
  top: 55px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border-radius: 15px;
  color: contrast(@main-color);

  @media @phone {
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.content-wrapper {
  display: flex;
  height: 100%;

  > div {
    flex: 1;
  }

  .sidebar {
    flex: 0 0 @sidebar-width;
    background: lighten(@main-color, 0.5%);
    display: flex;
    flex-direction: column;

    @media @phone {
      z-index: 100;
      transition: all 300ms;

      &.hide {
        transform: translateX(-100%);
      }
    }

    > div {
      flex: 1;
      padding: 21px;
    }

    .title {
      .locked-element-mixin;
      font-size: 23.6pt;
      font-family: Rajdhani, sans-serif;
      font-weight: 300;
      color: fade(contrast(@main-color), 60%);
      flex-grow: 0;
      border-bottom: 1px solid fade(contrast(@main-color), 5%);
      background: fade(contrast(@main-color), 5%);

      @media @phone {
        display: none;
      }
    }

    .menu {
      padding: 30px;
      overflow: scroll;

      .menu-section {
        &:first-child .menu-section-title {
          margin-top: 0;
        }

        .menu-section-title {
          .locked-element-mixin;
          font-size: 15pt;
          font-family: Rajdhani, sans-serif;
          padding: 10px 0;
          margin: 20px 0 10px 0;
          color: fade(contrast(@main-color), 70%);
          border-bottom: 1px dotted fade(contrast(@main-color), 10%);
        }

        .menu-section-content {
          margin-left: 5px;

          .menu-item {
            font-size: 12pt;
            position: relative;

            a {
              opacity: 0.5;
              display: inline-block;
              padding: 5px;
              border-radius: 50px;
              transition: 500ms;

              &::before {
                content: '';
                position: absolute;
                width: 0;
                top: 6px;
                bottom: 6px;
                left: 0;
                background: @accent-color;
                border-radius: 10px;
                display: block;
                transition: width 500ms;
                opacity: 0.8;
              }

              &:hover {
                opacity: 0.8;
              }

              &.router-link-active {
                opacity: 1;
                padding-left: 10px;

                &:before {
                  width: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  .page-content-wrapper {
    overflow: auto;
  }
}
</style>
