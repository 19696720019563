<template>
  <div class="user-item">
    <div class="icon">
      <i v-if="user.type === 'super'" class="far fa-user-bounty-hunter" />
      <i v-else-if="user.type === 'service'" class="far fa-robot-astromech" />
      <i v-else class="far fa-user" />
    </div>
    <div>
      <div class="name">
        {{ user.fullName }}
        <span class="sep">•</span>
        <span class="username">@{{ user.username }}</span>
      </div>
      <div class="email">{{ user.email }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserPicklistItem',

  props: {
    user: Object
  }
}
</script>

<style lang="less" scoped>
.user-item {
  display: flex;

  > div {
    flex: 1;
  }

  .icon {
    flex: 0 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16pt;
    opacity: 0.5;
    margin-right: 10px;
  }

  .name {
    font-weight: 900;

    .username {
      opacity: 0.5;
      font-weight: 400;
      font-size: 10pt;
      vertical-align: middle;
    }

    .sep {
      margin: 0 7px;
      opacity: 0.5;
    }
  }

  .email {
    opacity: 0.6;
    font-size: 11pt;
  }
}
</style>
