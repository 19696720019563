<template>
  <div style="background-color: #fff">
    <center>
      <h2>TIREVIEW - F.A.S.T</h2>
      <h2 class="no-sep">ORDER DETAILS #{{order.number}}</h2>
    </center>

    <div>
      <table>
        <tbody>
          <tr>
            <th>ID</th>
            <th>Status</th>
            <th>Processed by</th>
          </tr>
          <tr>
            <td>{{order.id}}</td>
            <td>{{order.status.code}}</td>
            <td>#{{order.createdBy.employeeId}} - {{order.createdBy.name}}</td>
          </tr>
          <tr>
            <th>Client</th>
            <th>System</th>
            <th>Processed at</th>
          </tr>
          <tr>
            <td>#{{order.client.id}} - {{order.client.name}}</td>
            <td>{{order.system.name}}</td>
            <td>{{$formatDateTime(order.createdAt)}}</td>
          </tr>
          <tr>
            <th width="33%">Total devices</th>
            <th width="33%">Total types</th>
            <th width="33%">Total sensors</th>
          </tr>
          <tr>
            <td>{{totalDevices}}</td>
            <td>{{totalVehicleTypes}}</td>
            <td>{{totalSensors}}</td>
          </tr>
        </tbody>
      </table>

      <h3>DEVICES</h3>

      <table>
        <tbody v-for="orderItem in order.items" :key="orderItem._id">
          <tr>
            <th colspan="2" class="darken-bg" width="33%">Vehicle Type</th>
            <th colspan="2" class="darken-bg" width="33%">Layout Type</th>
            <th colspan="2" class="darken-bg" width="33%">Total devices</th>
          </tr>
          <tr>
            <td colspan="2">#{{orderItem.batchSettings.id}} - {{orderItem.batchSettings.name}}</td>
            <td colspan="2">#{{orderItem.vehicle.id}} - {{orderItem.vehicle.name}}</td>
            <td colspan="2">{{orderItem.devices.length}}</td>
          </tr>
          <tr>
            <th class="darken-bg" colspan="6">Locations</th>
          </tr>
          <tr>
            <td colspan="6">{{orderItem.locations.length > 0 ? orderItem.locations.map(i => i.name).join(', ') : '(None)'}}</td>
          </tr>
          <template v-for="orderItemDevice in orderItem.devices">
            <tr :key="orderItemDevice._id">
              <th>IMEI</th>
              <th colspan="5">Sensors</th>
            </tr>
            <tr :key="orderItemDevice._id">
              <td :rowspan="orderItemDevice.sensors.length + 1">{{orderItemDevice.deviceId}}</td>
              <th>ID</th>
              <th>Name</th>
              <th>Date Code</th>
              <th>Part Number</th>
              <th>Supplier QC Code</th>
            </tr>
            <tr v-for="orderItemDeviceSensor in orderItemDevice.sensors" :key="orderItemDeviceSensor._id">
              <td>{{orderItemDeviceSensor.id}}</td>
              <td>{{orderItemDeviceSensor.name}}</td>
              <td>{{orderItemDeviceSensor.meta.manufacturingDateCode}}</td>
              <td>{{orderItemDeviceSensor.meta.manufacturingPartNumber}}</td>
              <td>{{orderItemDeviceSensor.meta.manufacturingQualityControlCode}}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'OrderDetailsPrintView',

  methods: {
    getOrder () {
      this.$apollo.query({
        query: gql`
          query ($id: ID!) {
            fast {
              order: getOrder(id: $id) {
                id: _id
                number
                status {
                  code
                  reason
                }
                system {
                  id
                  name
                }
                client {
                  id
                  name
                }
                items {
                  _id
                  batchSettings {
                    id
                    name
                  }
                  locations {
                    id
                    name
                  }
                  vehicle {
                    id
                    name
                  }
                  devices {
                    _id
                    deviceId
                    sensors {
                      _id
                      id
                      name
                      type
                      cmdId
                      meta {
                        manufacturingDateCode
                        manufacturingPartNumber
                        manufacturingQualityControlCode
                      }
                    }
                  }
                }
                createdBy {
                  employeeId
                  name
                }
                createdAt
              }
            }
          }
        `,
        variables: {
          id: this.$route.params.id
        }
      }).then(response => {
        this.order = response.data.fast.order
        this.loading = false

        setTimeout(() => {
          window.print()
        }, 5000)
      })
    }
  },

  computed: {
    totalVehicleTypes () {
      return this.order.items.length
    },

    totalDevices () {
      return this.order.items.reduce((a, b) => a + b.devices.length, 0)
    },

    totalSensors () {
      return this.order.items.reduce((a, b) => a + b.devices.reduce((c, d) => c + d.sensors.length, 0), 0)
    }
  },

  data () {
    return {
      order: {},
      loading: true
    }
  },

  mounted () {
    this.getOrder()
  }
}
</script>

<style lang="less" scoped>
h2 {
  padding: 10px 0;
  border-bottom: 1px solid #000;

  &.no-sep {
    border: none;
  }
}

h3 {
  margin-top: 20px;
}

table {
  width: 100%;
  border: 1px solid #000;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #000;
    padding: 5px;
    text-align: center;
  }

  th {
    background-color: #eee;
    -webkit-print-color-adjust: exact !important;

    &.darken-bg {
      background-color: #ccc;
    }
  }

  tbody {
    border: none;
  }
}
</style>
