<template>
  <page title="Roles" :loading="loading" wide>
    <template #controls>
      <form-button v-if="$checkPermission('platform.roles.create')" icon="fal fa-plus" @click="$router.push('/platform2/roles/create')">Create a Role</form-button>
    </template>

    <template #body>
      <data-table :value="roles" @refresh="getRoles">
        <column field="id" header="Role ID" bodyStyle="text-align: center" headerStyle="width: 180px" sortable>
          <template #body="slot">
            <router-link class="editor-link" :to="'/platform2/roles/update/' + slot.data.id">{{String(slot.data.id).padStart(6, '0')}}</router-link>
          </template>
        </column>
        <column field="context" header="Context" headerStyle="width: 150px" bodyStyle="text-align: center; text-transform: capitalize" sortable/>
        <column field="name" header="Name" headerStyle="width: 300px" bodyStyle="text-align: center" sortable/>
        <column field="description" header="Description" bodyStyle="text-align: center" sortable/>
      </data-table>
    </template>
  </page>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import FormButton from '@/components/FormButton.vue'
import Page from '@/components/Page.vue'
import Column from 'primevue/column'

import { tireviewliveApi } from '@/lib/tireviewlive-api'

export default {
  name: 'RolesView',

  components: {
    Page,
    DataTable,
    Column,
    FormButton
  },

  methods: {
    async getRoles () {
      this.loading = true

      this.roles = await tireviewliveApi.get('/sysadmin/roles', { accountId: this.accountId })

      this.loading = false
    }
  },

  computed: {
    accountId () {
      return this.$store.state.workingAccount.value
    }
  },

  watch: {
    '$store.state.workingAccount': function () {
      this.getRoles()
    }
  },

  data () {
    return {
      loading: true,
      roles: []
    }
  },

  mounted () {
    this.getRoles()
  }
}
</script>
