import { render, staticRenderFns } from "./SwitchButton.vue?vue&type=template&id=1b28f211&scoped=true&"
import script from "./SwitchButton.vue?vue&type=script&lang=js&"
export * from "./SwitchButton.vue?vue&type=script&lang=js&"
import style0 from "./SwitchButton.vue?vue&type=style&index=0&id=1b28f211&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b28f211",
  null
  
)

export default component.exports