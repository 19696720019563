import { messageBox } from '../lib/message-box'
import { checkPermission } from '../lib/permissions'
import {
  formatDate,
  formatDateTime,
  formatUTCDate,
  formatUTCDateTime,
  isMobile,
  moveFocusToFirstElement,
  mapListItems
} from '../lib/utils'

export default {
  install (Vue) {
    Vue.prototype.$checkPermission = checkPermission
    Vue.prototype.$formatDate = formatDate
    Vue.prototype.$formatDateTime = formatDateTime
    Vue.prototype.$formatUTCDate = formatUTCDate
    Vue.prototype.$formatUTCDateTime = formatUTCDateTime
    Vue.prototype.$isMobile = isMobile
    Vue.prototype.$mapListItems = mapListItems
    Vue.prototype.$messageBox = messageBox

    Vue.mixin({
      methods: {
        $moveFocusToFirstElement () {
          if (!isMobile()) {
            this.$nextTick().then(() => {
              moveFocusToFirstElement(this.$el)
            })
          }
        }
      }
    })
  }
}
