<template>
  <div class="device-item">
    <div v-if="icon !== 'unknown'" class="icon">
      <i v-if="icon === 'tractor'" class="far fa-truck" />
      <i v-else class="far fa-trailer" />
    </div>
    <div>
      <div class="name">{{ device.name }}</div>
      <div class="details">
        {{ device.fleet }}
        <div v-show="device.fleet !== '' && device.type !== ''" class="sep">•</div>
        {{ device.type }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DevicePicklistItem',

  props: {
    device: Object
  },

  computed: {
    icon () {
      return this.device.settings?.tireGraphic?.type ?? 'unknown'
    }
  }
}
</script>

<style lang="less" scoped>
.device-item {
  display: flex;

  > div {
    flex: 1;
  }

  .icon {
    flex: 0 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16pt;
    opacity: 0.5;
    margin-right: 10px;
  }

  .name {
    font-weight: 900;
  }

  .details {
    opacity: 0.6;
    font-size: 11pt;

    .sep {
      display: inline;
      margin: 0 5px;
      opacity: 0.5;
    }
  }
}
</style>
