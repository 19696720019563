<template>
  <page :title="pageTitle" backURI=":back" :loading="loading">
    <template #body>
      <h1 class="title"><span v-if="!isEditing">New</span> Account <strong>{{ account.name }}</strong></h1>

      <tabs>
        <tab title="Account">
          <div class="form">
            <div class="input-group size-sm">
              <text-input v-if="isEditing" type="number" label="ID" :value="String(account.id).padStart(6, 0)" disabled/>
            </div>

            <div class="input-group size-md">
              <dropdown :disabled="!$checkPermission('platform.accounts.update')" :list="usersList" label="Owner User" v-model="account.ownerUserId"/>
            </div>
            <div class="input-group size-md">
              <dropdown :disabled="!$checkPermission('platform.accounts.update')" :list="usersList" label="Administrator Contact User" v-model="account.adminContactUserId"/>
            </div>
            <div class="input-group size-md">
              <dropdown :disabled="!$checkPermission('platform.accounts.update')" :list="usersList" label="Billing Contact User" v-model="account.billingContactUserId"/>
            </div>
            <div class="input-group size-md">
              <text-input :disabled="!$checkPermission('platform.accounts.update')" label="Account Name" autocapitalize="words" v-model="account.name"/>
            </div>
            <div class="input-group size-md">
              <dropdown :disabled="!$checkPermission('platform.accounts.update')" :list="languageList" label="Language" :options="{searchMode: 'none'}" v-model="account.language"/>
            </div>
            <div class="input-group size-md">
              <dropdown :disabled="!$checkPermission('platform.accounts.update')" :list="timeZoneList" label="Time Zone" v-model="account.timeZone"/>
            </div>
            <div class="input-group size-sm">
              <dropdown :disabled="!$checkPermission('platform.accounts.update')" :list="unitSystemList" label="Unit System" :options="{searchMode: 'none'}" v-model="account.unitSystem"/>
            </div>
            <div class="input-group size-sm">
              <dropdown :disabled="!$checkPermission('platform.accounts.update')" :list="temperatureUnitList" label="Temperature Unit" :options="{searchMode: 'none'}" v-model="account.temperatureUnit"/>
            </div>
            <div class="input-group size-sm">
              <dropdown :disabled="!$checkPermission('platform.accounts.update')" :list="pressureUnitList" label="Pressure Unit" :options="{searchMode: 'none'}" v-model="account.pressureUnit"/>
            </div>
            <div class="input-group size-sm">
              <dropdown :disabled="!$checkPermission('platform.accounts.update')" :list="themeList" label="Theme" :options="{searchMode: 'none'}" v-model="account.theme"/>
            </div>
            <div class="input-group size-sm">
              <dropdown :disabled="!$checkPermission('platform.accounts.update')" :list="statusList" label="Status" :options="{searchMode: 'none'}" v-model="account.status"/>
            </div>
            <div class="input-group size-sm">
              <text-input :disabled="!$checkPermission('platform.accounts.update')" label="Legacy Account ID" type="number" v-model="account.legacyAccountId"/>
            </div>
            <div class="input-group bottom">
              <form-button v-if="$checkPermission('platform.accounts.update')" icon="fal fa-check" class="default" @click="save">Save</form-button>
            </div>
          </div>
        </tab>

        <tab title="Users" :disabled="!isEditing">
          <picklist
            :list="usersList"
            @add-items="addUsers"
            @remove-items="removeUsers"
            :height="500"
            v-model="selectedUsers"
            :disabled="!$checkPermission('platform.accounts.update')"
          >
            <template #body="item">
              <user-picklist-item :user="item"/>
            </template>
          </picklist>
        </tab>

        <tab title="Devices" :disabled="!isEditing">
          <picklist
            :list="devicesList"
            @add-items="addDevices"
            @remove-items="removeDevices"
            :height="500"
            v-model="selectedDevices"
            :disabled="!$checkPermission('platform.accounts.update')"
          >
            <template #body="item">
              <device-picklist-item :device="item"/>
            </template>
          </picklist>
        </tab>
      </tabs>
    </template>
  </page>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue'
import FormButton from '@/components/FormButton.vue'
import Page from '@/components/Page.vue'
import Picklist from '@/components/Picklist.vue'
import Tabs from '@/components/Tabs'
import Tab from '@/components/Tabs/Tab'
import TextInput from '@/components/TextInput.vue'
import DevicePicklistItem from './components/DevicePicklistItem.vue'
import UserPicklistItem from './components/UserPicklistItem.vue'

import { tireviewliveApi } from '@/lib/tireviewlive-api'

export default {
  name: 'AccountEditorView',

  components: {
    Dropdown,
    Page,
    TextInput,
    FormButton,
    Tabs,
    Tab,
    Picklist,
    DevicePicklistItem,
    UserPicklistItem
  },

  methods: {
    async save () {
      const data = {
        ...this.account,
        ownerUserId: this.account.ownerUserId.value,
        adminContactUserId: this.account.adminContactUserId?.value,
        billingContactUserId: this.account.billingContactUserId?.value,
        language: this.account.language.value,
        timeZone: this.account.timeZone.value,
        unitSystem: this.account.unitSystem.value,
        temperatureUnit: this.account.temperatureUnit.value,
        pressureUnit: this.account.pressureUnit.value,
        theme: this.account.theme.value,
        status: this.account.status.value
      }

      if (this.isEditing) {
        await tireviewliveApi.put(`/sysadmin/account/${this.accountId}`, data).then(() => {
          this.$messageBox('Account successfully saved', 'success')
        }).catch(error => {
          this.$messageBox(error.message, 'danger')
        })
      } else {
        await tireviewliveApi.post('/sysadmin/account', data).then((newAccount) => {
          this.$messageBox('Account successfully created', 'success')

          this.$router.replace(`/platform2/accounts/update/${newAccount.id}`)

          this.init()
        }).catch(error => {
          this.$messageBox(error.message, 'danger')
        })
      }
    },

    async addDevices (items) {
      await tireviewliveApi.post(`/sysadmin/account/${this.accountId}/attach-devices`, {
        devices: items.map(i => i.value)
      })
    },

    async removeDevices (items) {
      await tireviewliveApi.delete(`/sysadmin/account/${this.accountId}/dettach-devices`, {
        devices: items.map(i => i.value)
      })
    },

    async addUsers (items) {
      await tireviewliveApi.post(`/sysadmin/account/${this.accountId}/attach-users`, {
        users: items.map(i => i.value)
      })
    },

    async removeUsers (items) {
      await tireviewliveApi.delete(`/sysadmin/account/${this.accountId}/dettach-users`, {
        users: items.map(i => i.value)
      })
    },

    async init () {
      this.usersList = await tireviewliveApi.get('/sysadmin/users', {
        itemsPerPage: 100,
        includeSpecial: 'super,agent'
      }).then(response => {
        return this.$mapListItems(response, ['fullName', 'id'])
      })

      if (this.isEditing) {
        this.devicesList = await tireviewliveApi.get('/sysadmin/devices', { itemsPerPage: 100 }).then(response => {
          return this.$mapListItems(response, ['name', 'id'])
        })

        this.account = await tireviewliveApi.get(`/sysadmin/account/${this.accountId}`, {
          include: 'devices,users'
        })

        this.selectedDevices = this.$mapListItems(this.account.devices, ['name', 'id'])
        this.selectedUsers = this.$mapListItems(this.account.users, ['fullName', 'id'])

        this.account.ownerUserId = this.usersList.fromValue(this.account.ownerUserId)
        this.account.adminContactUserId = this.usersList.fromValue(this.account.adminContactUserId)
        this.account.billingContactUserId = this.usersList.fromValue(this.account.billingContactUserId)
        this.account.language = this.languageList.fromValue(this.account.language)
        this.account.timeZone = this.timeZoneList.fromValue(this.account.timeZone)
        this.account.unitSystem = this.unitSystemList.fromValue(this.account.unitSystem)
        this.account.temperatureUnit = this.temperatureUnitList.fromValue(this.account.temperatureUnit)
        this.account.pressureUnit = this.pressureUnitList.fromValue(this.account.pressureUnit)
        this.account.theme = this.themeList.fromValue(this.account.theme)
        this.account.status = this.statusList.fromValue(this.account.status)
      } else {
        this.account.ownerUserId = this.usersList.fromValue(1)
        this.account.language = this.languageList.fromValue('en-US')
        this.account.timeZone = this.timeZoneList.fromValue('auto')
        this.account.unitSystem = this.unitSystemList.fromValue('imperial')
        this.account.temperatureUnit = this.temperatureUnitList.fromValue('f')
        this.account.pressureUnit = this.pressureUnitList.fromValue('psi')
        this.account.theme = this.themeList.fromValue('auto')
        this.account.status = this.statusList.fromValue('active')
      }

      this.loading = false
    }
  },

  computed: {
    isEditing () {
      return !!this.$route.params.accountId
    },

    pageTitle () {
      return this.isEditing ? 'Edit Account' : 'Create Account'
    },

    accountId () {
      return this.$route.params.accountId
    }
  },

  data () {
    return {
      loading: true,
      account: {},
      usersList: [],
      devicesList: [],
      selectedDevices: [],
      selectedUsers: [],
      languageList: this.$mapListItems([{ value: 'en-US', label: 'English (United States)' }]),
      timeZoneList: this.$mapListItems([
        { label: 'Automatic', value: 'auto' },
        'UTC',
        'Pacific/Midway',
        'Pacific/Niue',
        'Pacific/Pago_Pago',
        'America/Adak',
        'Pacific/Honolulu',
        'Pacific/Johnston',
        'Pacific/Rarotonga',
        'Pacific/Tahiti',
        'Pacific/Marquesas',
        'America/Anchorage',
        'Pacific/Gambier',
        'America/Juneau',
        'America/Nome',
        'America/Sitka',
        'America/Yakutat',
        'America/Dawson',
        'America/Los_Angeles',
        'America/Metlakatla',
        'Pacific/Pitcairn',
        'America/Santa_Isabel',
        'America/Tijuana',
        'America/Vancouver',
        'America/Whitehorse',
        'America/Boise',
        'America/Cambridge_Bay',
        'America/Chihuahua',
        'America/Creston',
        'America/Dawson_Creek',
        'America/Denver',
        'America/Edmonton',
        'America/Hermosillo',
        'America/Inuvik',
        'America/Mazatlan',
        'America/Ojinaga',
        'America/Phoenix',
        'America/Shiprock',
        'America/Yellowknife',
        'America/Bahia_Banderas',
        'America/Belize',
        'America/North_Dakota/Beulah',
        'America/Cancun',
        'America/North_Dakota/Center',
        'America/Chicago',
        'America/Costa_Rica',
        'Pacific/Easter',
        'America/El_Salvador',
        'Pacific/Galapagos',
        'America/Guatemala',
        'America/Indiana/Knox',
        'America/Managua',
        'America/Matamoros',
        'America/Menominee',
        'America/Merida',
        'America/Mexico_City',
        'America/Monterrey',
        'America/North_Dakota/New_Salem',
        'America/Rainy_River',
        'America/Rankin_Inlet',
        'America/Regina',
        'America/Resolute',
        'America/Swift_Current',
        'America/Tegucigalpa',
        'America/Indiana/Tell_City',
        'America/Winnipeg',
        'America/Atikokan',
        'America/Bogota',
        'America/Cayman',
        'America/Detroit',
        'America/Grand_Turk',
        'America/Guayaquil',
        'America/Havana',
        'America/Indiana/Indianapolis',
        'America/Iqaluit',
        'America/Jamaica',
        'America/Lima',
        'America/Kentucky/Louisville',
        'America/Indiana/Marengo',
        'America/Kentucky/Monticello',
        'America/Montreal',
        'America/Nassau',
        'America/New_York',
        'America/Nipigon',
        'America/Panama',
        'America/Pangnirtung',
        'America/Indiana/Petersburg',
        'America/Port-au-Prince',
        'America/Thunder_Bay',
        'America/Toronto',
        'America/Indiana/Vevay',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Caracas',
        'America/Anguilla',
        'America/Antigua',
        'America/Aruba',
        'America/Asuncion',
        'America/Barbados',
        'Atlantic/Bermuda',
        'America/Blanc-Sablon',
        'America/Boa_Vista',
        'America/Campo_Grande',
        'America/Cuiaba',
        'America/Curacao',
        'America/Dominica',
        'America/Eirunepe',
        'America/Glace_Bay',
        'America/Goose_Bay',
        'America/Grenada',
        'America/Guadeloupe',
        'America/Guyana',
        'America/Halifax',
        'America/Kralendijk',
        'America/La_Paz',
        'America/Lower_Princes',
        'America/Manaus',
        'America/Marigot',
        'America/Martinique',
        'America/Moncton',
        'America/Montserrat',
        'Antarctica/Palmer',
        'America/Port_of_Spain',
        'America/Porto_Velho',
        'America/Puerto_Rico',
        'America/Rio_Branco',
        'America/Santiago',
        'America/Santo_Domingo',
        'America/St_Barthelemy',
        'America/St_Kitts',
        'America/St_Lucia',
        'America/St_Thomas',
        'America/St_Vincent',
        'America/Thule',
        'America/Tortola',
        'America/St_Johns',
        'America/Araguaina',
        'America/Bahia',
        'America/Belem',
        'America/Argentina/Buenos_Aires',
        'America/Argentina/Catamarca',
        'America/Cayenne',
        'America/Argentina/Cordoba',
        'America/Fortaleza',
        'America/Godthab',
        'America/Argentina/Jujuy',
        'America/Argentina/La_Rioja',
        'America/Maceio',
        'America/Argentina/Mendoza',
        'America/Miquelon',
        'America/Montevideo',
        'America/Paramaribo',
        'America/Recife',
        'America/Argentina/Rio_Gallegos',
        'Antarctica/Rothera',
        'America/Argentina/Salta',
        'America/Argentina/San_Juan',
        'America/Argentina/San_Luis',
        'America/Santarem',
        'America/Sao_Paulo',
        'Atlantic/Stanley',
        'America/Argentina/Tucuman',
        'America/Argentina/Ushuaia',
        'America/Noronha',
        'Atlantic/South_Georgia',
        'Atlantic/Azores',
        'Atlantic/Cape_Verde',
        'America/Scoresbysund',
        'Africa/Abidjan',
        'Africa/Accra',
        'Africa/Bamako',
        'Africa/Banjul',
        'Africa/Bissau',
        'Atlantic/Canary',
        'Africa/Casablanca',
        'Africa/Conakry',
        'Africa/Dakar',
        'America/Danmarkshavn',
        'Europe/Dublin',
        'Africa/El_Aaiun',
        'Atlantic/Faroe',
        'Africa/Freetown',
        'Europe/Guernsey',
        'Europe/Isle_of_Man',
        'Europe/Jersey',
        'Europe/Lisbon',
        'Africa/Lome',
        'Europe/London',
        'Atlantic/Madeira',
        'Africa/Monrovia',
        'Africa/Nouakchott',
        'Africa/Ouagadougou',
        'Atlantic/Reykjavik',
        'Africa/Sao_Tome',
        'Atlantic/St_Helena',
        'Africa/Algiers',
        'Europe/Amsterdam',
        'Europe/Andorra',
        'Africa/Bangui',
        'Europe/Belgrade',
        'Europe/Berlin',
        'Europe/Bratislava',
        'Africa/Brazzaville',
        'Europe/Brussels',
        'Europe/Budapest',
        'Europe/Busingen',
        'Africa/Ceuta',
        'Europe/Copenhagen',
        'Africa/Douala',
        'Europe/Gibraltar',
        'Africa/Kinshasa',
        'Africa/Lagos',
        'Africa/Libreville',
        'Europe/Ljubljana',
        'Arctic/Longyearbyen',
        'Africa/Luanda',
        'Europe/Luxembourg',
        'Europe/Madrid',
        'Africa/Malabo',
        'Europe/Malta',
        'Europe/Monaco',
        'Africa/Ndjamena',
        'Africa/Niamey',
        'Europe/Oslo',
        'Europe/Paris',
        'Europe/Podgorica',
        'Africa/Porto-Novo',
        'Europe/Prague',
        'Europe/Rome',
        'Europe/San_Marino',
        'Europe/Sarajevo',
        'Europe/Skopje',
        'Europe/Stockholm',
        'Europe/Tirane',
        'Africa/Tripoli',
        'Africa/Tunis',
        'Europe/Vaduz',
        'Europe/Vatican',
        'Europe/Vienna',
        'Europe/Warsaw',
        'Africa/Windhoek',
        'Europe/Zagreb',
        'Europe/Zurich',
        'Europe/Athens',
        'Asia/Beirut',
        'Africa/Blantyre',
        'Europe/Bucharest',
        'Africa/Bujumbura',
        'Africa/Cairo',
        'Europe/Chisinau',
        'Asia/Damascus',
        'Africa/Gaborone',
        'Asia/Gaza',
        'Africa/Harare',
        'Asia/Hebron',
        'Europe/Helsinki',
        'Europe/Istanbul',
        'Asia/Jerusalem',
        'Africa/Johannesburg',
        'Europe/Kiev',
        'Africa/Kigali',
        'Africa/Lubumbashi',
        'Africa/Lusaka',
        'Africa/Maputo',
        'Europe/Mariehamn',
        'Africa/Maseru',
        'Africa/Mbabane',
        'Asia/Nicosia',
        'Europe/Riga',
        'Europe/Simferopol',
        'Europe/Sofia',
        'Europe/Tallinn',
        'Europe/Uzhgorod',
        'Europe/Vilnius',
        'Europe/Zaporozhye',
        'Africa/Addis_Ababa',
        'Asia/Aden',
        'Asia/Amman',
        'Indian/Antananarivo',
        'Africa/Asmara',
        'Asia/Baghdad',
        'Asia/Bahrain',
        'Indian/Comoro',
        'Africa/Dar_es_Salaam',
        'Africa/Djibouti',
        'Africa/Juba',
        'Europe/Kaliningrad',
        'Africa/Kampala',
        'Africa/Khartoum',
        'Asia/Kuwait',
        'Indian/Mayotte',
        'Europe/Minsk',
        'Africa/Mogadishu',
        'Europe/Moscow',
        'Africa/Nairobi',
        'Asia/Qatar',
        'Asia/Riyadh',
        'Antarctica/Syowa',
        'Asia/Tehran',
        'Asia/Baku',
        'Asia/Dubai',
        'Indian/Mahe',
        'Indian/Mauritius',
        'Asia/Muscat',
        'Indian/Reunion',
        'Europe/Samara',
        'Asia/Tbilisi',
        'Europe/Volgograd',
        'Asia/Yerevan',
        'Asia/Kabul',
        'Asia/Aqtau',
        'Asia/Aqtobe',
        'Asia/Ashgabat',
        'Asia/Dushanbe',
        'Asia/Karachi',
        'Indian/Kerguelen',
        'Indian/Maldives',
        'Antarctica/Mawson',
        'Asia/Oral',
        'Asia/Samarkand',
        'Asia/Tashkent',
        'Asia/Colombo',
        'Asia/Kolkata',
        'Asia/Kathmandu',
        'Asia/Almaty',
        'Asia/Bishkek',
        'Indian/Chagos',
        'Asia/Dhaka',
        'Asia/Qyzylorda',
        'Asia/Thimphu',
        'Antarctica/Vostok',
        'Asia/Yekaterinburg',
        'Indian/Cocos',
        'Asia/Rangoon',
        'Asia/Bangkok',
        'Indian/Christmas',
        'Antarctica/Davis',
        'Asia/Ho_Chi_Minh',
        'Asia/Hovd',
        'Asia/Jakarta',
        'Asia/Novokuznetsk',
        'Asia/Novosibirsk',
        'Asia/Omsk',
        'Asia/Phnom_Penh',
        'Asia/Pontianak',
        'Asia/Vientiane',
        'Asia/Brunei',
        'Antarctica/Casey',
        'Asia/Choibalsan',
        'Asia/Chongqing',
        'Asia/Harbin',
        'Asia/Hong_Kong',
        'Asia/Kashgar',
        'Asia/Krasnoyarsk',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Asia/Macau',
        'Asia/Makassar',
        'Asia/Manila',
        'Australia/Perth',
        'Asia/Shanghai',
        'Asia/Singapore',
        'Asia/Taipei',
        'Asia/Ulaanbaatar',
        'Asia/Urumqi',
        'Australia/Eucla',
        'Asia/Dili',
        'Asia/Irkutsk',
        'Asia/Jayapura',
        'Pacific/Palau',
        'Asia/Pyongyang',
        'Asia/Seoul',
        'Asia/Tokyo',
        'Australia/Adelaide',
        'Australia/Broken_Hill',
        'Australia/Darwin',
        'Australia/Brisbane',
        'Pacific/Chuuk',
        'Australia/Currie',
        'Antarctica/DumontDUrville',
        'Pacific/Guam',
        'Australia/Hobart',
        'Asia/Khandyga',
        'Australia/Lindeman',
        'Australia/Melbourne',
        'Pacific/Port_Moresby',
        'Pacific/Saipan',
        'Australia/Sydney',
        'Asia/Yakutsk',
        'Australia/Lord_Howe',
        'Pacific/Efate',
        'Pacific/Guadalcanal',
        'Pacific/Kosrae',
        'Antarctica/Macquarie',
        'Pacific/Noumea',
        'Pacific/Pohnpei',
        'Asia/Sakhalin',
        'Asia/Ust-Nera',
        'Asia/Vladivostok',
        'Pacific/Norfolk',
        'Asia/Anadyr',
        'Pacific/Auckland',
        'Pacific/Fiji',
        'Pacific/Funafuti',
        'Asia/Kamchatka',
        'Pacific/Kwajalein',
        'Asia/Magadan',
        'Pacific/Majuro',
        'Antarctica/McMurdo',
        'Pacific/Nauru',
        'Antarctica/South_Pole',
        'Pacific/Tarawa',
        'Pacific/Wake',
        'Pacific/Wallis',
        'Pacific/Chatham',
        'Pacific/Apia',
        'Pacific/Enderbury',
        'Pacific/Fakaofo',
        'Pacific/Tongatapu',
        'Pacific/Kiritimati'
      ], { capitalize: false }),
      unitSystemList: this.$mapListItems([
        'metric',
        'imperial'
      ]),
      temperatureUnitList: this.$mapListItems([
        { value: 'c', label: 'Celsius' },
        { value: 'f', label: 'Fahrenheit' },
        { value: 'k', label: 'Kelvin' }
      ]),
      pressureUnitList: this.$mapListItems([
        { value: 'kpa', label: 'kPa' },
        { value: 'psi', label: 'PSI' },
        'bar'
      ], { capitalize: false }),
      themeList: this.$mapListItems([{ value: 'auto', label: 'Automatic' }, 'light', 'dark']),
      statusList: this.$mapListItems(['active', 'blocked'])
    }
  },

  async mounted () {
    this.init()
  }
}
</script>
