<template>
  <div class="header">
    <transition name="header-bar-slide">
      <div class="back" @click="$router.back()">
        <i class="fal fa-arrow-left"></i>
      </div>
    </transition>
    <div>
      <div class="hamburger-menu" @click="toggleMenu">
        <i class="fal fa-bars"></i>
      </div>
      <div class="logo">
        <img src="@/assets/tireview-logo-bw.png" alt="TireView LIVE">
      </div>
    </div>
    <div class="right">
      <transition name="fade">
        <span v-if="showAccountSelector" class="accounts-info" @click="showAccountDropdown">
          <i class="fas fa-building"></i> <span>{{ workingAccount.label }} <strong v-if="workingAccount.value">(#{{ workingAccount.value }})</strong></span>
        </span>
      </transition>

      <span class="user-info" @click="showUserMenu">
        <i class="fas fa-user-circle"></i> <span>{{user.name}} (<strong>#{{user.employeeId}}</strong>)</span>
      </span>

      <div class="btn-container" :class="{show: userMenuVisible}">
        <div class="btn-group">
          <button v-if="$checkPermission('fast.orders.*') || $checkPermission('iam.*')" title="Administration" @click="$router.push('/')" :disabled="!$route.path.includes('/change-password')">
            <i class="fal fa-user-shield"></i> <span>Home</span>
          </button>

          <button title="Change Password" @click="$router.push('/change-password')" :disabled="$route.name === 'ChangePassword'">
            <i class="fal fa-key"></i> <span>Change Password</span>
          </button>
        </div>

        <button class="close" title="Sign Out" @click="logout">
          <i class="fal fa-sign-out"></i> <span>Sign out</span>
        </button>
      </div>
    </div>

    <div class="account-selector" :class="{ show: accountDropdownVisible }" @click="hideAccountDropdown">
      <div class="account-selector-content" @click.stop>
        <h1>Select an account</h1>

        <dropdown
          v-model="workingAccount"
          :list="accountList"
          @select="accountSelected"
          :options="{ searchMode: 'filter' }"
          ref="accountDropdown"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from './Dropdown.vue'

import { tireviewliveApi } from '../lib/tireviewlive-api'

export default {
  name: 'HeaderBar',

  components: {
    Dropdown
  },

  methods: {
    logout () {
      this.$store.dispatch('UNSET_AUTH').then(() => {
        const url = new URL(process.env.VUE_APP_AUTH_URL)

        url.searchParams.append('app', 'sysadmin')

        location.replace(url)
      })
    },

    toggleMenu (e) {
      e.stopPropagation()

      this.$store.dispatch('TOGGLE_MENU_VISIBILITY')
    },

    showAccountDropdown (e) {
      this.accountDropdownVisible = true

      this.$refs.accountDropdown.showList(e)
    },

    hideAccountDropdown (e) {
      e.stopPropagation()

      this.accountDropdownVisible = false
    },

    closeDropdown () {
      this.accountDropdownVisible = false
    },

    showUserMenu (e) {
      e.stopPropagation()

      this.userMenuVisible = true
    },

    accountSelected () {
      this.accountDropdownVisible = false
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    },

    workingAccount: {
      get () {
        return this.$store.state.workingAccount
      },
      set (val) {
        this.$store.commit('setWorkingAccount', val)
      }
    },

    showAccountSelector () {
      const { path } = this.$route

      return path.startsWith('/platform2') &&
        !(path.startsWith('/platform2/accounts') ||
        path.startsWith('/platform2/sync') ||
        path.startsWith('/platform2/ban-list'))
    }
  },

  watch: {
    $route () {
      const count = window.history.length

      if (count > this.windowHistoryCount) {
        this.historyCount++
      } else {
        this.historyCount--
      }

      this.windowHistoryCount = count
    }
  },

  data () {
    return {
      accountDropdownVisible: false,
      userMenuVisible: false,
      accountList: []
    }
  },

  async mounted () {
    document.addEventListener('click', () => {
      this.userMenuVisible = false
    })

    document.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.accountDropdownVisible = false
      }
    })

    tireviewliveApi.get('/sysadmin/accounts').then((response) => {
      this.accountList = [
        { value: undefined, label: 'All' },
        ...response.map(i => ({ value: i.id, label: i.name }))
      ]
    })
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../assets/shared.less';

.header {
  display: flex;

  > div {
    flex: 1;
    padding: 10px;

    @media @phone {
      margin-top: 2px;
    }

    &.back {
      flex: 0 0 60px;
      cursor: pointer;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15pt;
      color: contrast(@main-color);
      transform: translateX(12px);
      transition: all 200ms;
      opacity: 0.4;
      overflow: hidden;

      @media @phone {
        display: none;
      }

      &:hover {
        opacity: 1;
        transform: translateX(5px);
      }

      &:active {
        opacity: 0.6;
        transform: scale(0.8);
      }
    }

    .logo img {
      height: 30px;
      opacity: 0.6;

      @media @phone {
        position: fixed;
        top: 18px;
        left: 70px;
        height: 16px;
      }
    }

    .hamburger-menu {
      font-size: 20pt;
      margin-left: 10px;
      opacity: 0.7;
      display: none;

      @media @phone {
        display: initial;
      }
    }

    &.right {
      text-align: right;

      .user-info, .accounts-info {
        color: fade(contrast(@main-color), 40%);
        margin-right: 20px;
        user-select: none;
        cursor: default;

        i {
          font-size: 16pt;
          vertical-align: middle;
          margin-right: 10px;
        }

        @media @phone {
          margin-right: 10px;

          i {
            margin-right: 0;
            font-size: 22pt;
          }

          span {
            display: none;
          }
        }
      }

      .accounts-info {
        border: 1px solid fade(contrast(@main-color), 5%);
        border-radius: 5px;
        padding: 10px;
        cursor: pointer;

        &:hover {
          border-color: fade(contrast(@main-color), 10%);
        }
      }

      .btn-container {
        display: inline-block;

        @media @phone {
          display: block;
          position: fixed;
          background: @main-color;
          border: 1px solid darken(@main-color, 2%);
          box-shadow: -2px 8px 12px -5px #000;
          border-radius: 10px;
          padding: 10px;
          top: 45px;
          right: 10px;
          z-index: 100;
          transition: all 200ms;
          transition-timing-function: cubic-bezier(0, -0.32, 1, 1.55);
          opacity: 0;
          transform: scale(0);
          transform-origin: top right;

          &.show {
            transform: scale(1);
            opacity: 1;
          }
        }

        .btn-group {
          display: inline;
          margin-right: 15px;

          button {
            border-radius: 0;
            margin: 0 !important;
            width: 50px;

            &:first-child {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }

            &:last-child {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }

        button {
          width: 30px;
          height: 30px;
          border: none;
          border-radius: 5px;
          background: fade(contrast(@main-color), 10%);
          color: contrast(contrast(@main-color));
          font-size: 15pt;
          cursor: pointer;
          transition: all 200ms;

          @media @phone {
            display: block;
            border-radius: 0 !important;
            width: 100% !important;
            height: auto !important;
            text-align: left;
            background: transparent !important;
            margin: 0 !important;
            padding: 10px 15px !important;
            font-size: 13pt;
            color: fade(contrast(@main-color), 60%);

            span {
              display: initial !important;
              padding-left: 10px;
            }

            &:disabled {
              color: contrast(@main-color) !important;
              pointer-events: none;
            }
          }

          span {
            display: none;
          }

          &:not(.close) {
            margin-right: 5px;
          }

          &:hover {
            background: fade(contrast(@main-color), 20%);
            color: contrast(@main-color);
          }

          &:active {
            opacity: 0.6;
          }

          &:disabled {
            background: fade(contrast(@main-color), 25%);
            pointer-events: none;
          }

          &.close {
            margin-left: 15px;

            @media @phone {
              color: @danger-color;
            }

            &:hover {
              background-color: @danger-color;
            }
          }
        }
      }
    }
  }
}

.account-selector {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  animation: select-account-animation 500ms forwards;

  &.show {
    display: block;
  }

  .account-selector-content {
    width: 450px;
    margin: 150px auto 0 auto;
    max-width: 90%;
    animation: select-account-content-animation 500ms forwards;

    h1 {
      font-weight: 100;
      margin-bottom: 10px;
    }
  }
}

@keyframes select-account-animation {
  0% {
    backdrop-filter: blur(0);
  }
  100% {
    backdrop-filter: blur(5px);
  }
}

@keyframes select-account-content-animation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
