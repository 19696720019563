import store from '../store'

export function checkPermission (permission, permissions = null) {
  const queryPermission = permission

  if (permissions === null) {
    permissions = store.state.auth?.permissions ?? []
  }

  return permissions.some(permission => {
    if (permission === '*') {
      return true
    } else {
      const permissionPrefix = permission.replace(/\*$/g, '')
      const queryPermissionPrefix = queryPermission.replace(/\*$/g, '')

      if (permission.endsWith('*') && !queryPermission.endsWith('*')) {
        return queryPermission.startsWith(permissionPrefix)
      }

      if (queryPermission.endsWith('*') && !permission.endsWith('*')) {
        return permission.startsWith(queryPermissionPrefix)
      }

      if (permission.endsWith('*') && queryPermission.endsWith('*')) {
        return permission.startsWith(queryPermissionPrefix)
      }

      return permission === queryPermission
    }
  })
}
