<template>
  <div class="tabs">
    <div class="btn-container">
      <template
        v-for="tab in tabs"
      >
        <button
          v-if="!tab.component.hidden"
          :key="tab.id"
          :disabled="tab.component.disabled"
          :class="{ selected: tab.id === selectedTab.id }"
          @click="selectedTab = tab"
        >{{tab.title}}</button>
      </template>
    </div>

    <div class="tabs-container">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',

  watch: {
    selectedTab () {
      this.tabs.forEach(item => {
        item.component.visible = false
      })

      this.selectedTab.component.visible = true
      this.selectedTab.component.$moveFocusToFirstElement()
    }
  },

  data () {
    return {
      tabs: [],
      selectedTab: null
    }
  },

  mounted () {
    this.$slots.default.forEach(item => {
      if (item.componentOptions.tag === 'tab') {
        this.tabs.push({
          component: item.componentInstance,
          id: item.componentInstance._uid,
          title: item.componentOptions.propsData.title
        })
      }
    })

    this.selectedTab = this.tabs.filter(i => !(i.component.disabled || i.component.hidden))[0]
  }
}
</script>

<style lang="less" scoped>
@tab-bg: fade(contrast(@main-color), 5%);

.tabs {
  .btn-container {
    display: flex;
    overflow: auto;

    button {
      flex: 1;
      border: none;
      padding: 15px 30px;
      color: contrast(@main-color);
      font-size: 9pt;
      background: fade(contrast(@main-color), 3%);
      cursor: pointer;
      transition: all 200ms;
      position: relative;
      text-transform: uppercase;

      &::before {
        content: '';
        background: fade(@accent-color, 80%);
        position: absolute;
        top: 0;
        left: 5px;
        right: 5px;
        height: 0;
        border-radius: 3px;
        transition: height 300ms;
      }

      &:hover {
        background: fade(contrast(@main-color), 7%);
      }

      &:disabled {
        opacity: 0.4;
        cursor: default;

        &:hover {
          border-color: transparent;
        }
      }

      &.selected {
        background: @tab-bg;
        font-weight: bold;

        &::before {
          height: 5px;
        }
      }
    }
  }

  .tabs-container {
    padding: 10px;
    background: @tab-bg;
  }
}
</style>
